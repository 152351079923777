import {
    ExactValueFilter, RangeFilter,
} from "src/interfaces/models/SendBulkNotificationsModels";
import StringUtils from "src/utils/StringUtils";
import DateTimeUtils from "src/utils/DateTimeUtils";
import {SearchNotificationsPaneState} from "src/interfaces/states/SendBulkNotificationsStates";
import {SearchNotificationsQueryFieldName} from "src/interfaces/enums/SendBulkNotificationsEnums";

const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

export function buildRangeFilters(state: SearchNotificationsPaneState): RangeFilter[] {
    const filters = new Array<RangeFilter>();
    if(StringUtils.isNotEmpty(state.startDateValue) && StringUtils.isNotEmpty(state.endDateValue)) {
        filters.push({
            fieldName: SearchNotificationsQueryFieldName.CREATION_TIME,
            startValue: DateTimeUtils.getUTCTimeFromDateString(state.startDateValue).toString(),
            endValue: (ONE_DAY_IN_MILLIS + DateTimeUtils.getUTCTimeFromDateString(state.endDateValue)).toString(),
        });
    }
    return filters;
}


export function buildExactValueFilters(state: SearchNotificationsPaneState): ExactValueFilter[] {
    const filters = new Array<ExactValueFilter>();
    if(StringUtils.isNotEmpty(state.searchFieldUserAlias)) {
        filters.push({
            fieldName: SearchNotificationsQueryFieldName.USER_ALIAS,
            value: state.searchFieldUserAlias,
        });
    }
    return filters;
}
