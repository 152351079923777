import React from "react"
import {TextAreaProps} from "src/interfaces/props/CommonProps";
import Textarea from "@amzn/meridian/textarea";

export class MediumTextArea extends React.Component<TextAreaProps> {

    constructor(props: TextAreaProps) {
        super(props);
    }

    render() {
        return(
            <Textarea value={this.props.value} onChange={this.props.onChange} width={600}
                      rows={this.props.rows} resize={this.props.resize} disabled={this.props.isDisabled}/>
        );
    }
}

export default MediumTextArea;