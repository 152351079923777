import React from "react";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import DatePicker from "@amzn/meridian/date-picker";
import XLInput from "src/components/common/input/XLInput";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import ErrorAlert from "src/components/common/alert/ErrorAlert";
import {SearchNotificationsPaneProps,} from "src/interfaces/props/SendBulkNotificationsProps";
import {searchNotifications} from "src/helpers/send-bulk-notifications/SendBulkNotificationsAPIs";
import {navigateToResendNotification} from "src/helpers/send-bulk-notifications/SendBulkNotificationsCommonHelper";
import Modal from "@amzn/meridian/modal";
import BulkNotificationDetailsScreen from "src/components/screens/send-bulk-notifications/BulkNotificationDetailsScreen";
import {SearchNotificationsPaneState} from "src/interfaces/states/SendBulkNotificationsStates";
import {SearchNotificationsAPIOutput} from "src/interfaces/models/SendBulkNotificationsModels";
import {buildSearchNotificationsAPIPayload} from "src/helpers/send-bulk-notifications/SendBulkNotificationsAPIPayloadBuilders";
import SearchNotificationsTable from "src/components/screens/send-bulk-notifications/SearchNotificationsTable";
import {SendBulkNotificationsPages} from "src/interfaces/enums/SendBulkNotificationsEnums";
import StringUtils from "src/utils/StringUtils";
import Column from "@amzn/meridian/column";
import SmallDropDown from "src/components/common/dropdown/SmallDropDown";
import {PAGE_SIZE_LABELS, PAGE_SIZE_VALUES} from "src/constants/SendBulkNotificationsConstants";

export default class SearchNotificationsPane extends React.Component<SearchNotificationsPaneProps, SearchNotificationsPaneState> {

    constructor(props: SearchNotificationsPaneProps) {
        super(props);
        this.state = getDefaultState();
        this.selectStartDateValue = this.selectStartDateValue.bind(this);
        this.selectEndDateValue = this.selectEndDateValue.bind(this);
        this.onUserAliasValueChange = this.onUserAliasValueChange.bind(this);
        this.onNotificationDetailsClose = this.onNotificationDetailsClose.bind(this);
        this.searchNotificationsForToken = this.searchNotificationsForToken.bind(this);
        this.onResetSearchFiltersButtonClick = this.onResetSearchFiltersButtonClick.bind(this);
        this.onViewNotificationButtonClick = this.onViewNotificationButtonClick.bind(this);
        this.onSearchNotificationsButtonClicked = this.onSearchNotificationsButtonClicked.bind(this);
        this.searchNotificationsForToken = this.searchNotificationsForToken.bind(this);
        this.onClickNextPageButton = this.onClickNextPageButton.bind(this);
        this.onChangePageSize = this.onChangePageSize.bind(this);
    }

    selectStartDateValue(startDate: string) {
        this.setState({startDateValue: startDate});
    }

    selectEndDateValue(endDate: string) {
        this.setState({endDateValue: endDate});
    }

    onUserAliasValueChange(userAlias: string) {
        this.setState({searchFieldUserAlias: userAlias});
    }

    onNotificationDetailsClose() {
        this.setState({showNotificationDetails: false});
    }

    onResetSearchFiltersButtonClick() {
        this.setState({
            searchFieldUserAlias: EMPTY_STRING,
            endDateValue: EMPTY_STRING,
            startDateValue: EMPTY_STRING,
        })
    }

    onViewNotificationButtonClick(referenceId: string) {
        this.setState({
            showNotificationDetails: true,
            selectedReferenceId: referenceId,
        });
    }

    onEditAndResendNotificationButtonClick(referenceId: string) {
        navigateToResendNotification(referenceId);
    }

    async onSearchNotificationsButtonClicked() {
        await this.searchNotificationsForToken(EMPTY_STRING);
    }

    async searchNotificationsForToken(token: string) {
        await this.prepareStateForMakingSearchRequest(token);
        const searchNotificationsInput = buildSearchNotificationsAPIPayload(this.state);
        try {
            const response = await searchNotifications(searchNotificationsInput);
            this.handleSearchNotificationsResponse(response);
        } catch (e) {
            this.toggleErrorAlert("Error while fetching the search results. Please try again.", false);
        }
        this.setState({showFetchDataTag: false});
    }

    handleSearchNotificationsResponse(response: SearchNotificationsAPIOutput) {
        if (response.notifications.length > 0) {
            this.setState({
                notificationsList: response.notifications,
                paginationToken: response.lastToken,
            });
        } else {
            this.toggleErrorAlert("No notifications found.", false);
        }
    }

    async prepareStateForMakingSearchRequest(token: string) {
        return new Promise((resolve, _) => {
            this.toggleErrorAlert(EMPTY_STRING, true);
            this.setState({
                showFetchDataTag: true,
                paginationIndex: StringUtils.isEmpty(token) ? 0 : this.state.paginationIndex + this.state.notificationsList.length,
                paginationToken: token,
                notificationsList: [],
            }, resolve);
        });
    }

    toggleErrorAlert(message: string, isHidden: boolean) {
        this.setState({
            errorAlertMessage: message,
            hideErrorAlert: isHidden,
        });
    }

    async onClickNextPageButton(token: string) {
        await this.searchNotificationsForToken(token);
    }

    onChangePageSize(pageSize: string) {
        this.setState({pageSize: parseInt(pageSize)});
    }

    render() {
        return (
            <div id={"searchNotificationsPaneMainDiv"}>
                <div id={"notificationDetailsDiv"} hidden={this.state.showNotificationDetails}>
                    <Modal title="Notification Details" open={this.state.showNotificationDetails}
                           onClose={this.onNotificationDetailsClose}>
                        <BulkNotificationDetailsScreen screenInput={this.props.screenInput}
                                                       referenceId={this.state.selectedReferenceId}
                                                       page={SendBulkNotificationsPages.VIEW_NOTIFICATION_PAGE}/>
                    </Modal>
                </div>
                <div id={"searchFieldsDiv"}>
                    <Row alignmentHorizontal="left" spacingInset="none large large" spacing="small" widths="fill">
                        <DatePicker value={this.state.startDateValue} onChange={this.selectStartDateValue}
                                    label={"Start Date"}/>
                        <DatePicker value={this.state.endDateValue} onChange={this.selectEndDateValue}
                                    label={"End Date"}/>
                        <XLInput value={this.state.searchFieldUserAlias} onChange={this.onUserAliasValueChange}
                                 placeholder={"Sender"} type={"text"} id={"userAliasSearchField"}/>
                        <div role={"spacing"}>{}</div> {/* This div is only to occupy space for alignment. */}
                        <Button size={"large"} onClick={this.onSearchNotificationsButtonClicked}>
                            Search Notifications
                        </Button>
                        <Button size={"large"} onClick={this.onResetSearchFiltersButtonClick}>
                            Reset
                        </Button>
                    </Row>
                </div>

                <div id="alertsAndSpinnersDiv">
                    <div hidden={!this.state.showFetchDataTag}>
                        <FetchingDataMessage/>
                    </div>
                    <div hidden={this.state.hideErrorAlert}>
                        <ErrorAlert text={this.state.errorAlertMessage}/>
                    </div>
                </div>

                <div id={"notificationsTableDiv"} hidden={this.state.showFetchDataTag}>
                    <Column alignmentHorizontal={"center"} spacingInset={"large"} spacing={"small"}>
                        <SearchNotificationsTable notifications={this.state.notificationsList}
                                                  serialNumberOffset={this.state.paginationIndex}
                                                  onViewClick={this.onViewNotificationButtonClick}
                                                  onResendClick={this.onEditAndResendNotificationButtonClick}/>
                    </Column>
                    <Row spacingInset={"none large"} widths={"fill"} spacing={"large"}>
                        <Column alignmentHorizontal={"left"}>
                            <SmallDropDown defaultValue={this.state.pageSize.toString()} onSelect={this.onChangePageSize}
                                           valueList={PAGE_SIZE_VALUES} labelList={PAGE_SIZE_LABELS} prefix={"Page Size"}/>
                        </Column>
                        <Column alignmentHorizontal={"right"}>
                            <div id={"nextPageDiv"} hidden={StringUtils.isEmpty(this.state.paginationToken)}>
                                <Button size={"large"} type={"secondary"}
                                        href={this.state.paginationToken} onClick={this.onClickNextPageButton}>
                                    Next
                                </Button>
                            </div>
                        </Column>
                    </Row>
                </div>
            </div>
        );
    }
}

function getDefaultState(): SearchNotificationsPaneState {
    return {
        searchFieldUserAlias: EMPTY_STRING,
        endDateValue: EMPTY_STRING,
        startDateValue: EMPTY_STRING,
        notificationsList: [],
        showFetchDataTag: false,
        hideErrorAlert: true,
        errorAlertMessage: EMPTY_STRING,
        showNotificationDetails: false,
        selectedReferenceId: EMPTY_STRING,
        pageSize: 10,
        paginationToken: EMPTY_STRING,
        paginationIndex: 0,
    } as SearchNotificationsPaneState;
}
