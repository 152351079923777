export const ApplicationScreenConstants = {
    HOME: {
        SCREEN_NAME: "Home",
        URL: "/"
    },
    AUDIT_EXECUTION_ARTIFACTS: {
        SCREEN_NAME: "Audit Execution Artifacts",
        URL: "/audit-execution-artifacts",
        CARD_INTRO: "Start here if you want to audit execution artifacts data.",
        SCREEN_INTRO: "To use this dashboard, submit the details below. After loading records, " +
            "click on action buttons ( Match or No-Match ) button and that's it, we will take it from there."
    },
    SERVICE_PROVIDERS_AUDIT: {
        SCREEN_NAME: "Audit Service Providers",
        URL: "/service-providers-audit",
        CARD_INTRO: "Start here if you want to audit service providers.",
        SCREEN_INTRO: "To use this dashboard, submit the details in the section below. After loading records, " +
            "click on action buttons ( Match or No-Match ) button and thats it, we will take it from there."
    },
    CONTACT_SUPPORT_CASES: {
        SCREEN_NAME: "Contact Support Cases",
        URL: "/contact-support-cases",
        CARD_INTRO: "Start here if you want solve the Contact cases.",
        SCREEN_INTRO: "Use this dashboard.",
        CREATE_OR_UPDATE_CASE_URL: "/contact-support-cases/createOrUpdateContactSupportCase"
    },

    TECHNICIAN_ASSIGNMENT_TRAIL: {
        SCREEN_NAME: "Technician Assignment Trail",
        URL: "/technician-assignment-trail",
        CARD_INTRO: "Start here if you want to view technician assignment trail.",
        SCREEN_INTRO: "To use this dashboard, submit the detail(s) in the section below."
    },
    TECHNICIAN_PROFILE_EDIT: {
        SCREEN_NAME: "Edit Technician Profile",
        URL: "/technician-profile-edit",
        CARD_INTRO: "Start here if you want to search and edit technician.",
        SCREEN_INTRO: "To use this dashboard, submit the details in the section below."
    },
    SEND_BULK_NOTIFICATIONS: {
        SCREEN_NAME: "Send Bulk Notifications",
        URL: "/send-bulk-notifications",
        CARD_INTRO: "Start here if you want to send bulk notifications.",
        SCREEN_INTRO: "To use this dashboard, submit the details in the section below.",
        NOTIFICATION_DETAILS: "/send-bulk-notifications/notificationDetails",
    },
    STAFF_RATING_FILE_UPLOAD: {
        SCREEN_NAME: "Upload Staff's Rating File",
        URL: "/rating-file-upload",
        CARD_INTRO: "Start here if you want to upload staffs rating file.",
        SCREEN_INTRO: "To use this dashboard, choose the staff's rating file below " +
             "and click on the upload button."
    },
    JOB_EXECUTION_IMAGES: {
        SCREEN_NAME: "Query Job Photos",
        URL: "/query-job-photos",
        CARD_INTRO: "Start here if you wantr to query job photos.",
        SCREEN_INTRO: "To use this dashboard, submit the detail(s) in the section below."
    }
};
