import React from "react";
import {
    ASC,
    ASCENDING,
    DESC,
    EMPTY_STRING,
    TABLE_ALIGNMENT
} from "src/constants/ApplicationCommonConstants";
import {
    deserializeLoadData,
    searchQuery
} from "src/helpers/technician-profile-edit/TechnicianProfileEditHelper";
import {apiCallParameters, FAILED, TECHNICIAN_ID} from "src/constants/TechnicianProfileEditApiConstants";
import Row from "@amzn/meridian/row";
import Level4Heading from "src/components/common/heading/Level4Heading";
import SearchField from "@amzn/meridian/search-field";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Column from "@amzn/meridian/column";
import orderBy from "lodash/orderBy"
import {SortState} from "src/interfaces/states/ServiceProviderAuditStates";
import {TechnicianProfileEditTableStates} from "src/interfaces/states/TechnicianProfileEditStates";
import {
    TechnicianProfileEditRowObject,
    TechnicianProfileEditTableProps
} from "src/interfaces/props/TechnicianProfileEditProps";
import StringUtils from "src/utils/StringUtils";
import SmallText from "src/components/common/text/SmallText";
import TechnicianProfileEditDetailsPane
    from "src/components/screens/technician-profile-edit/TechnicianProfileEditDetailsPane";
import {technicianProfileEditApiCall} from "src/helpers/technician-profile-edit/TechnicianProfileEditAPIs";
import Button from "@amzn/meridian/button";

function createPayloadObject(marketplaceId: string, merchantId: string, technicianId: string) {
    let payloadObject = {
        marketPlaceId: marketplaceId,
        merchantId: merchantId,
        technicianId: technicianId
    }
    return payloadObject;
}

export class TechnicianProfileEditTable extends React.Component<TechnicianProfileEditTableProps, TechnicianProfileEditTableStates> {
    constructor(props: TechnicianProfileEditTableProps) {
        super(props);
        this.state = {
            responsePayload: [],
            searchValue: EMPTY_STRING,
            result: this.props.resultData,
            currentPage: 1,
            sortColumn: TECHNICIAN_ID,
            sortDirection: ASCENDING,
            technicianID: EMPTY_STRING,
            isEdit: false,
            inputValidationStatus: false,
        }
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.submitSearchQuery = this.submitSearchQuery.bind(this);
        this.clearSearchQuery = this.clearSearchQuery.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.onEditButtonClick = this.onEditButtonClick.bind(this);
        this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
    }

    changeSearchValue(value: string) {
        this.setState({searchValue: value});
    }

    sortTable(sortState: SortState) {
        this.setState(sortState);
    }

    setCurrentPage(value: number) {
        this.setState({currentPage: value});
    }

    submitSearchQuery(event: boolean) {
        if (StringUtils.isEmpty(this.state.searchValue)) {
            this.setState({result: this.props.resultData})
        } else {
            let queryResults = [] as TechnicianProfileEditRowObject[];
            for (let index = 0; index < this.props.resultData.length; index++) {
                if (searchQuery(this.props.resultData[index], this.state.searchValue)) {
                    queryResults.push(this.props.resultData[index]);
                }
            }
            this.setState({result: queryResults})
        }
    }

    clearSearchQuery(event: boolean) {
        this.setState({result: this.props.resultData})
    }

    async loadTechnicianDetailsData(userName: string, payload: string, event: string) {
        let response;
        response = await technicianProfileEditApiCall(apiCallParameters.LOAD_TECHNICIAN_DETAILS, userName, payload, EMPTY_STRING);

        this.setState({responsePayload: deserializeLoadData(response.ResponsePayload)});
        this.setState({isEdit: true});
        this.setState({technicianID: event});
    }

    onEditButtonClick = (event: string) => {
        this.loadTechnicianDetailsData(this.props.userLogin,
            JSON.stringify(createPayloadObject(this.props.marketplaceId, this.props.merchantId, event)), event);

    }

    onCancelButtonClick() {
        this.setState({isEdit: false});
        this.setState({technicianID: EMPTY_STRING});
    }


    decorateTextValue(textValue: string) {
        if (textValue === FAILED) {
            return <SmallText text={FAILED} color="error"/>
        } else {
            return (textValue);
        }
    }

    render() {
        const sortedTabularResult = orderBy(
            this.state.result,
            [this.state.sortColumn],
            [this.state.sortDirection === ASCENDING ? ASC : DESC]
        )

        const itemsPerPage = 10;
        const firstVisibleIndex = (this.state.currentPage - 1) * itemsPerPage;
        const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
        const numberOfPages = Math.ceil(sortedTabularResult.length / itemsPerPage);
        return (
            <div>
                <Row width="100%" widths={["grid-8", "grid-4"]} spacing="large" spacingInset="medium">
                    <Level4Heading text={"Technician(s)"}/>
                    <SearchField
                        value={this.state.searchValue}
                        onChange={this.changeSearchValue}
                        onSubmit={this.submitSearchQuery}
                        onClear={this.clearSearchQuery}
                        size="small"
                        placeholder="Enter data to search in table"
                    />
                </Row>
                <div style={{overflowX: "auto", maxWidth: "100%"}}>
                    <Table headerRows={1} showDividers={true} sortColumn={this.state.sortColumn}
                           sortDirection={this.state.sortDirection} onSort={this.sortTable}>
                        <TableRow highlightOnHover={true}>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>S.No.</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="technicianId">Technician
                                Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Profile Name</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="merchantId">Merchant
                                Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="profilePicture">Profile
                                Picture</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="govtIdName">Govt Id
                                Name</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="govtIdPicture">Govt Id
                                Picture</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="backgroundCheckStatus">Background
                                Check Status</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Action</TableCell>
                        </TableRow>
                        {
                            sortedTabularResult.slice(firstVisibleIndex, lastVisibleIndex).map((row: TechnicianProfileEditRowObject, serialNumber: number) => (
                                <TableRow key={serialNumber} highlightOnHover={true} open={this.state.isEdit}>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{serialNumber + 1}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.technicianId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.technicianName}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.merchantId}</TableCell>

                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <div>
                                            {
                                                this.decorateTextValue(row.profileImageStatus)
                                            }
                                        </div>
                                    </TableCell>

                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <div>
                                            {
                                                this.decorateTextValue(row.govtIdNameStatus)
                                            }
                                        </div>
                                    </TableCell>

                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <div>
                                            {
                                                this.decorateTextValue(row.govtIdPictureStatus)
                                            }
                                        </div>
                                    </TableCell>

                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <div>
                                            {
                                                this.decorateTextValue(row.backgroundCheckStatus)
                                            }
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <Column height="30px" heights="fill" alignmentHorizontal="center"
                                                spacing="small" alignmentVertical="center">
                                                <Button type={"primary"}
                                                        size="small" minWidth={"100px"}
                                                        onClick={() => this.onEditButtonClick(row.technicianId)}
                                                        disabled={this.state.isEdit}> Edit </Button>

                                        </Column>
                                    </TableCell>
                                    {(() => {
                                        if (this.state.isEdit && this.state.technicianID == row.technicianId) {
                                            return <TableRow><TableCell columnSpan={9}>
                                                <TechnicianProfileEditDetailsPane technicianData={this.state.responsePayload[0]}
                                                                                  onCancelButtonClick={this.onCancelButtonClick}
                                                                                  userLogin={this.props.userLogin}
                                                                                  marketplaceId={this.props.marketplaceId}
                                                                                  merchantId={this.props.merchantId}
                                                                                  technicianId={this.state.technicianID}/>
                                            </TableCell></TableRow>
                                        }
                                    })()
                                    }
                                </TableRow>))
                        }
                    </Table>
                    <Row width="100%" widths={["grid-2"]} spacing="large">
                        <Button onClick={this.props.nextButtonClick} disabled={this.props.disableNextButton} type="link" size="small"> Next Page </Button>
                    </Row>
                </div>
            </div>

        );
    }
}

export default TechnicianProfileEditTable;