import React from "react";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import Row from "@amzn/meridian/row";
import MarketplaceValueDropdown
    from "src/components/screens/technician-profile-edit/MarketplaceValueDropDown";
import Button from "@amzn/meridian/button";
import {TechnicianProfileEditInputStates} from "src/interfaces/states/TechnicianProfileEditStates";
import {TechnicianProfileEditInputProps} from "src/interfaces/props/TechnicianProfileEditProps";
import XLargeDropDown from "src/components/common/dropdown/XLargeDropDown";
import {
    BY_MERCHANT,
    SEARCH_TYPE_LABEL_LIST,
    SEARCH_TYPE_VALUE_LIST
} from "src/constants/TechnicianProfileEditApiConstants";
import XLInput from "src/components/common/input/XLInput";

export class TechnicianProfileEditInput extends React.Component<TechnicianProfileEditInputProps, TechnicianProfileEditInputStates> {
    constructor(props: TechnicianProfileEditInputProps) {
        super(props);
        this.state = {
            searchType: BY_MERCHANT,
            technicianId: EMPTY_STRING,
            merchantId: EMPTY_STRING,
            marketplaceValue: EMPTY_STRING
        }
        this.selectSearchType = this.selectSearchType.bind(this);
        this.onChangeTechnicianId = this.onChangeTechnicianId.bind(this);
        this.onChangeMerchantId = this.onChangeMerchantId.bind(this);
        this.selectMarketplace = this.selectMarketplace.bind(this);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    }

    selectSearchType(event: string) {
        this.setState({searchType: event});
    }

    onChangeMerchantId(event: string) {
        this.setState({merchantId: event});
    }

    onChangeTechnicianId(event: string) {
        this.setState({technicianId: event});
    }

    selectMarketplace(event: string) {
        this.setState({marketplaceValue: event});
    }

    onSubmitButtonClick() {
        this.props.onButtonClick(this.state.searchType, this.state.technicianId, this.state.marketplaceValue, this.state.merchantId
        );
    }

    render() {
        return (
            <Row width="100%" widths={["grid-3", "grid-2", "grid-2", "grid-2", "grid-3"]} spacing="large">

                <XLargeDropDown key={"searchTypeValueDropdown"}
                                defaultValue={this.state.searchType}
                                onSelect={this.selectSearchType}
                                label={"Search Type"}
                                valueList={SEARCH_TYPE_VALUE_LIST}
                                labelList={SEARCH_TYPE_LABEL_LIST}
                                width={200}
                />

                <XLInput value={this.state.merchantId} onChange={this.onChangeMerchantId}
                         type={"text"}
                         placeholder={"Merchant Id"}
                         id={"merchantIdOfTechnicianProfileEdit"}
                />

                <div hidden={this.state.searchType == BY_MERCHANT}>
                    <XLInput value={this.state.technicianId} onChange={this.onChangeTechnicianId}
                             type={"text"}
                             placeholder={"Technician Id"}
                             id={"technicianIdOfTechnicianProfileEdit"}
                    />
                </div>

                <MarketplaceValueDropdown
                    realmValue={this.props.realmValue}
                    stage={this.props.stage}
                    onSelect={this.selectMarketplace}
                    defaultValue={this.state.marketplaceValue}
                />
                <Button onClick={this.onSubmitButtonClick} type="secondary" size="large"> Submit </Button>
            </Row>
        );
    }
}
