import {API} from "aws-amplify";
import {apiCallParameters} from "src/constants/ContactSupportCaseApiConstants";
import {commonApiCallParameters, FALSE} from "src/constants/ApplicationCommonConstants";
import {refreshMidway} from "src/components/authentication/MidwayTokenRetriever";

export const createOrUpdateContactSupportCase = async (userLogin: string, payload: string) => {
    const credentials = await refreshMidway();
    try {
        return await API.post(apiCallParameters.VAS_CONTACT_SUPPORT_DATA, commonApiCallParameters.EXECUTE_REQUEST, {
            body: {
                'RequestType': apiCallParameters.CREATE_CONTACT_SUPPORT_CASE_DATA,
                'EncryptedPayloadRequired': FALSE,
                'Tool': apiCallParameters.VAS_CONTACT_SUPPORT_LAMBDA,
                'UserLogin': userLogin,
                'Payload': payload,
                'Token': credentials.token,
                'ClientId': location.host
            },
            headers: {
                'Content-Type': commonApiCallParameters.CONTENT_TYPE,
            },
        });
    } catch (error) {
        // TODO: Need to fix this as we are eating up the exception here.
        throw new Error(`Error while calling the createContactSupportCase api for input: ${payload}`);
    }
}

export const searchContactSupportCases = async (userLogin: string, payload: string) => {
    const credentials = await refreshMidway();
    try {
        return await API.post(apiCallParameters.VAS_CONTACT_SUPPORT_DATA, commonApiCallParameters.EXECUTE_REQUEST, {
            body: {
                'RequestType': apiCallParameters.SEARCH_CONTACT_SUPPORT_CASE_DATA,
                'EncryptedPayloadRequired': FALSE,
                'Tool': apiCallParameters.VAS_CONTACT_SUPPORT_LAMBDA,
                'UserLogin': userLogin,
                'Payload': payload,
                'Token': credentials.token,
                'ClientId': location.host
            },
            headers: {
                'Content-Type': commonApiCallParameters.CONTENT_TYPE,
            },
        });
    } catch (error) {
        // TODO: Need to fix this as we are eating up the exception here.
        throw new Error(`Error while calling the SearchContactSupportCases api for input: ${payload}`);
    }
}