import {rowObject} from "src/interfaces/props/ExecutionArtifactsProps";

export const deserializeAuditData = (serializedAuditData: string) => {
    let auditStringArray = JSON.parse(serializedAuditData)
    let arrayIndex;
    let auditObjectArray = [] as rowObject[];
    for (arrayIndex = 0; arrayIndex < auditStringArray.length; arrayIndex++) {
        let data = JSON.parse(auditStringArray[arrayIndex]);
        auditObjectArray.push(data);
    }
    return auditObjectArray;
}

export const searchQuery = (object: rowObject, searchValue: string) => {
    let searchResult = ((object.appointmentId == searchValue) || (object.jobId == searchValue) ||
        (object.orderId == searchValue) || (object.merchantId == searchValue) || (object.technicianId == searchValue) ||
        (object.auditStatus == searchValue) || (object.lastUpdatedBy == searchValue) || (object.referenceScore == searchValue) ||
        (object.similarityScore == searchValue))
    return searchResult;
}