export enum SendBulkNotificationsPages {
    NOTIFICATION_DETAILS_PAGE = "NotificationDetailsPage",
    VIEW_NOTIFICATION_PAGE = "ViewNotificationPage",
}

export enum SendBulkNotificationsOperations {
    SEARCH_NOTIFICATIONS = "SearchNotifications",
    SEND_INSTANT_NOTIFICATION = "SendInstantNotification",
    SEND_INSTANT_CUSTOMIZED_NOTIFICATION = "SendInstantCustomizedNotification",
    GET_NOTIFICATION_INFO = "GetNotificationInfo",
    GET_S3_UPLOAD_URL = "GetS3UploadUrl",
}

export enum NotificationType {
    COMMON = "COMMON",
    CUSTOMIZED = "CUSTOMIZED",
}

export enum DispatchStyle {
    INSTANT = "INSTANT",
}

export enum RecipientLevel {
    MERCHANT = "MERCHANT",
    TECHNICIAN = "TECHNICIAN",
}

export enum DeliveryChannel {
    MOBILE_PUSH = "MOBILE_PUSH",
}

export enum SearchNotificationsQueryFieldName {
    CREATION_TIME = "CREATION_TIME",
    USER_ALIAS = "USER_ALIAS",
}
