import React from "react"
import Column from "@amzn/meridian/column"
import MiniText from "src/components/common/text/MiniText";
import { FOOTER_MESSAGE } from "src/constants/ApplicationCommonConstants";

export class ApplicationFooter extends React.Component {

    render() {
        return (
            <div id="AppFooter">
                <Column alignmentHorizontal="center" backgroundColor="alternatePrimary" spacingInset="medium">
                    <MiniText text={ FOOTER_MESSAGE } />
                </Column>
            </div>
        );
    }
}

export default ApplicationFooter;