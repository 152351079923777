import React from "react";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import {EMPTY_STRING, TABLE_ALIGNMENT} from "src/constants/ApplicationCommonConstants";
import {
    CASE_STATUS_LABEL_LIST,
    CASE_STATUS_VALUE_LIST,
    SUPPORT_CASE_TYPE_LABEL_LIST,
    SUPPORT_CASE_TYPE_VALUE_LIST
} from "src/constants/ContactSupportCaseToolConstants";
import DatePicker from "@amzn/meridian/date-picker";
import {searchContactSupportCases} from "src/helpers/contact-support-case/ContactSupportCaseApis";
import StringUtils from "src/utils/StringUtils";
import {ScreenProps} from "src/interfaces/props/CommonProps";
import XLInput from "src/components/common/input/XLInput";
import DateTimeUtils from "src/utils/DateTimeUtils";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import ErrorAlert from "src/components/common/alert/ErrorAlert";
import InformalAlert from "src/components/common/alert/InformalAlert";
import {ContactSupportCase, SearchContactSupportCaseAPIInput} from "src/interfaces/props/ContactSupportCaseProps";
import {
    getDefaultEndDate,
    getDefaultStartDate,
    navigateToCreateOrUpdateContactSupportCasePage
} from "src/helpers/contact-support-case/ContactSupportCaseCommonHelper";
import XLargeDropDown from "src/components/common/dropdown/XLargeDropDown";
import UnauthorizedUserMessage from "src/components/common/message/UnauthorizedUserMessage";

export class SearchContactSupportCasesMain extends React.Component<SearchContactSupportCasesMainProps, SearchContactSupportCasesMainState> {
    constructor(props: SearchContactSupportCasesMainProps) {
        super(props);
        this.state = getDefaultState(props);
        this.onAssignedToSearchValueChange = this.onAssignedToSearchValueChange.bind(this);
        this.selectSupportTypeValue = this.selectSupportTypeValue.bind(this);
        this.selectStartDateValue = this.selectStartDateValue.bind(this);
        this.selectEndDateValue = this.selectEndDateValue.bind(this);
        this.onSearchSupportCasesButtonClicked = this.onSearchSupportCasesButtonClicked.bind(this);
        this.selectCaseStatus = this.selectCaseStatus.bind(this);
        this.onUpdateContactSupportCaseButtonClick = this.onUpdateContactSupportCaseButtonClick.bind(this);
        this.onResetSearchFiltersButtonClicked = this.onResetSearchFiltersButtonClicked.bind(this);
        this.searchContactSupportCase = this.searchContactSupportCase.bind(this);
        this.onSearchSearchSupportCaseWithOffsetButtonClicked =
            this.onSearchSearchSupportCaseWithOffsetButtonClicked.bind(this);
        this.onCaseIdValueChange = this.onCaseIdValueChange.bind(this);
    }

    onAssignedToSearchValueChange(event: string) {
        this.setState({searchFieldAssignedToValue: event});
        this.resetPaginationState(this.state.paginationData.startIndex);
    }

    onCaseIdValueChange(event: string) {
        this.setState({caseId: event});
        this.resetPaginationState(this.state.paginationData.startIndex);
    }

    selectSupportTypeValue(event: string) {
        this.setState({searchFieldSupportTypeValue: event});
        this.resetPaginationState(this.state.paginationData.startIndex);
    }

    selectStartDateValue(event: string) {
        this.setState({startDateValue: event});
        this.resetPaginationState(this.state.paginationData.startIndex);
    }

    selectEndDateValue(event: string) {
        this.setState({endDateValue: event});
        this.resetPaginationState(this.state.paginationData.startIndex);
    }

    selectCaseStatus(event: string) {
        this.setState({caseStatus: event});
        this.resetPaginationState(this.state.paginationData.startIndex);
    }

    onUpdateContactSupportCaseButtonClick(event: string) {
        navigateToCreateOrUpdateContactSupportCasePage(event);
    }

    onResetSearchFiltersButtonClicked(event: string) {
        this.resetPaginationState();
        this.setState({
            searchFieldAssignedToValue: EMPTY_STRING,
            searchFieldSupportTypeValue: EMPTY_STRING,
            endDateValue: EMPTY_STRING,
            startDateValue: EMPTY_STRING,
            caseStatus: EMPTY_STRING,
            caseId: EMPTY_STRING
        })
    }

    async onSearchSupportCasesButtonClicked(event: string) {
        await this.searchContactSupportCase(EMPTY_STRING, 0);
    }

    async onSearchSearchSupportCaseWithOffsetButtonClicked(offset: string) {
        await this.searchContactSupportCase(offset,
            this.state.contactSupportCasesList.length + this.state.paginationData.startIndex);
    }

    async searchContactSupportCase(offset: string, startIndex: number) {
        this.prepareStateForMakingSearchRequest(offset, startIndex);
        const searchContactSupportCasesInput = this.buildSearchContactSupportCasesAPIPayload();
        if (searchContactSupportCasesInput !== undefined) {
            const searchContactSupportCasesPayload = JSON.stringify(searchContactSupportCasesInput);
            try {
                const response = await searchContactSupportCases(this.props.screenInput.username,
                    searchContactSupportCasesPayload);
                this.parseResponse(response);
            } catch (e) {
                this.toggleErrorAlert("Error while fetching the data. Please try again..", false);
            }
        } else {
            this.toggleErrorAlert(
                "Error while creating the search request for the contact support cases. Please check your search parameters",
                false);
        }
        this.setState({showFetchDataTag: false});
    }

    parseResponse(response: any) {
        const responsePayload = JSON.parse(response?.ResponsePayload);
        if (responsePayload?.success) {
            const searchResults = JSON.parse(
                response?.ResponsePayload).contactSupportCaseList as ContactSupportCase[];
            if (searchResults.length != 0) {
                this.setState({
                    contactSupportCasesList: searchResults,
                    paginationData: {
                        offset: responsePayload?.offSet,
                        startIndex: this.state.paginationData.startIndex
                    }
                });
            } else {
                this.resetPaginationState();
                this.toggleInformationalAlert("No results found for the given search input.", false);
            }
        } else if (response.ResponsePayload == null && response.ValidUser == false) {
            this.toggleUnauthorizedUserMessage(false);
        } else {
            this.toggleErrorAlert("Error from the Server side. Please try again after some time.", false);
        }
    }

    resetPaginationState(startIndex?: number) {
        this.setState({
            paginationData: {
                offset: EMPTY_STRING,
                startIndex: startIndex === undefined ? 0 : startIndex as number
            }
        });
    }

    prepareStateForMakingSearchRequest(offset: string, startIndex: number) {
        this.setState({
            showFetchDataTag: true,
            contactSupportCasesList: [],
            paginationData: {
                offset,
                startIndex
            }
        });
        this.toggleInformationalAlert(EMPTY_STRING, true);
        this.toggleErrorAlert(EMPTY_STRING, true);
        this.toggleUnauthorizedUserMessage(true);
    }

    buildSearchContactSupportCasesAPIPayload() {
        return {
            assignedTo: this.state.searchFieldAssignedToValue,
            caseStatus: this.state.caseStatus,
            caseId: this.state.caseId,
            caseType: this.state.searchFieldSupportTypeValue,
            offset: this.state.paginationData.offset,
            startDate: this.getStartDateForQuery(),
            endDate: this.getEndDateForQuery(),
        } as SearchContactSupportCaseAPIInput;
    }

    getStartDateForQuery() {
        if (StringUtils.isEmpty(this.state.startDateValue) && StringUtils.isEmpty(this.state.caseId)) {
            const date = getDefaultStartDate();
            this.setState({
                startDateValue: date
            });
            return DateTimeUtils.getUTCTimeFromDateString(date);
        }
        return DateTimeUtils.getUTCTimeFromDateString(this.state.startDateValue);
    }

    getEndDateForQuery() {
        if (StringUtils.isEmpty(this.state.endDateValue) && StringUtils.isEmpty(this.state.caseId)) {
            const date = getDefaultEndDate();
            this.setState({
                endDateValue: getDefaultEndDate()
            });
            return DateTimeUtils.getUTCTimeFromDateString(date);
        }
        return DateTimeUtils.getUTCTimeFromDateString(this.state.endDateValue);
    }

    toggleUnauthorizedUserMessage(isHidden: boolean) {
        this.setState({
            alerts: {
                errorAlertMessage: EMPTY_STRING,
                hideErrorAlert: true,
                informationAlertMessage: EMPTY_STRING,
                hideInformationAlert: true,
                hideUnauthorizedUserMessage: isHidden
            }
        });
    }

    toggleErrorAlert(message: string, isHidden: boolean) {
        this.setState({
            alerts: {
                errorAlertMessage: message,
                hideErrorAlert: isHidden,
                informationAlertMessage: EMPTY_STRING,
                hideInformationAlert: true,
                hideUnauthorizedUserMessage: true
            }
        });
    }

    toggleInformationalAlert(message: string, isHidden: boolean) {
        this.setState({
            alerts: {
                informationAlertMessage: message,
                hideInformationAlert: isHidden,
                errorAlertMessage: EMPTY_STRING,
                hideErrorAlert: true,
                hideUnauthorizedUserMessage: true
            }
        });
    }

    render() {
        let serialNumber = this.state.paginationData.startIndex;
        return (
            <div>
                <div id={"searchFields"}>
                    <Row alignmentHorizontal="left" spacingInset="large" spacing="small">
                        <XLInput value={this.state.caseId}
                                 onChange={this.onCaseIdValueChange}
                                 placeholder={"Support CaseId"}
                                 id={"caseIdOfContactSupportCase"}
                                 type={"text"}
                        />
                        {/*We cannot set a label on this field as doing that will remove the size constraint from the Date picker. Read the documentation*/}
                        <DatePicker value={this.state.startDateValue} onChange={this.selectStartDateValue}
                                    label={"Start Date"}/>
                        <DatePicker value={this.state.endDateValue} onChange={this.selectEndDateValue}
                                    label={"End Date"}/>
                        <XLargeDropDown key={"supportTypeDropDown"}
                                        valueList={SUPPORT_CASE_TYPE_VALUE_LIST}
                                        placeHolder={"Support Type"}
                                        defaultValue={this.state.searchFieldSupportTypeValue}
                                        labelList={SUPPORT_CASE_TYPE_LABEL_LIST}
                                        width={200}
                                        onSelect={this.selectSupportTypeValue}/>

                        <XLargeDropDown key={"caseStatusTypeDropDown"}
                                        valueList={CASE_STATUS_VALUE_LIST}
                                        placeHolder={"Case Status"}
                                        defaultValue={this.state.caseStatus}
                                        labelList={CASE_STATUS_LABEL_LIST}
                                        width={200}
                                        onSelect={this.selectCaseStatus}/>
                        <XLInput value={this.state.searchFieldAssignedToValue}
                                 onChange={this.onAssignedToSearchValueChange}
                                 placeholder={"Assigned To"}
                                 id={"assignedToSearchField"}
                                 type={"text"}
                        />
                        <Button size={"large"} onClick={this.onSearchSupportCasesButtonClicked}>
                            Search Support cases
                        </Button>
                        <Button size={"large"} onClick={this.onResetSearchFiltersButtonClicked}>
                            Reset
                        </Button>
                    </Row>
                </div>
                <div id={"supportCasesTableData"}>
                    <div id={"actualTableData"} hidden={this.state.showFetchDataTag}>
                        <Row alignmentHorizontal="left" spacingInset="large" spacing="small">
                            <Table headerRows={1} showDividers={true}>
                                <TableRow highlightOnHover={true}>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>S.No.</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Case Id</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Creation Date</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Technician Id</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Service Provider Id</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Case Status</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Phone Number</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Support Type</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Assigned To</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Action</TableCell>
                                </TableRow>
                                {
                                    this.state.contactSupportCasesList.map(
                                        (row: ContactSupportCase) => (
                                            <TableRow key={row.caseId} highlightOnHover={true}>
                                                <TableCell
                                                    alignmentHorizontal={TABLE_ALIGNMENT}>{++serialNumber}</TableCell>
                                                <TableCell
                                                    alignmentHorizontal={TABLE_ALIGNMENT}>{row.caseId}</TableCell>
                                                <TableCell
                                                    alignmentHorizontal={TABLE_ALIGNMENT}>{DateTimeUtils.getDateAndTimeString(
                                                    row.createTimeStamp)}</TableCell>
                                                <TableCell
                                                    alignmentHorizontal={TABLE_ALIGNMENT}>{row.encryptedUserId}</TableCell>
                                                <TableCell
                                                    alignmentHorizontal={TABLE_ALIGNMENT}>{row.encryptedSupervisorId}</TableCell>
                                                <TableCell
                                                    alignmentHorizontal={TABLE_ALIGNMENT}>{row.caseStatus}</TableCell>
                                                <TableCell
                                                    alignmentHorizontal={TABLE_ALIGNMENT}>{row.phoneNumber}</TableCell>
                                                <TableCell
                                                    alignmentHorizontal={TABLE_ALIGNMENT}>{row.supportType}</TableCell>
                                                <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                                    {StringUtils.isEmpty(row.assignedTo) ? EMPTY_STRING :
                                                        row.assignedTo}
                                                </TableCell>
                                                <TableCell>
                                                    <Button size={"medium"}
                                                            onClick={this.onUpdateContactSupportCaseButtonClick}
                                                            id={row.caseId} href={row.caseId}>
                                                        Update Case
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                }
                            </Table>
                        </Row>
                        <Row alignmentHorizontal={"center"}>
                            <div hidden={StringUtils.isEmpty(this.state.paginationData.offset)}>
                                <Button size={"large"}
                                        onClick={this.onSearchSearchSupportCaseWithOffsetButtonClicked}
                                        id={this.state.paginationData.offset}
                                        href={this.state.paginationData.offset}>
                                    Next
                                </Button>
                            </div>
                        </Row>
                    </div>
                    <div id={"searchSpinner"} hidden={!this.state.showFetchDataTag}>
                        <FetchingDataMessage/>
                    </div>
                    <div hidden={this.state.alerts.hideErrorAlert}>
                        <ErrorAlert text={this.state.alerts.errorAlertMessage}/>
                    </div>
                    <div hidden={this.state.alerts.hideInformationAlert}>
                        <InformalAlert text={this.state.alerts.informationAlertMessage}/>
                    </div>
                    <div hidden={this.state.alerts.hideUnauthorizedUserMessage}>
                        <UnauthorizedUserMessage/>
                    </div>
                </div>
            </div>
        );
    }
}

export interface SearchContactSupportCasesMainProps {
    searchResults: ContactSupportCase[],
    screenInput: ScreenProps
}

export interface SearchContactSupportCasesMainState {
    searchFieldAssignedToValue: string,
    searchFieldSupportTypeValue: string,
    startDateValue: string,
    endDateValue: string,
    caseStatus: string,
    contactSupportCasesList: ContactSupportCase[]
    showFetchDataTag: boolean,
    caseId: string,
    paginationData: {
        offset?: string,
        startIndex: number
    }
    alerts: {
        hideErrorAlert?: boolean,
        errorAlertMessage: string,
        hideInformationAlert?: boolean
        informationAlertMessage: string,
        hideUnauthorizedUserMessage?: boolean
    }
}

function getDefaultState(props: SearchContactSupportCasesMainProps): SearchContactSupportCasesMainState {
    return {
        searchFieldAssignedToValue: EMPTY_STRING,
        searchFieldSupportTypeValue: EMPTY_STRING,
        endDateValue: EMPTY_STRING,
        startDateValue: EMPTY_STRING,
        caseStatus: EMPTY_STRING,
        contactSupportCasesList: props.searchResults,
        showFetchDataTag: false,
        caseId: EMPTY_STRING,
        paginationData: {
            offset: EMPTY_STRING,
            startIndex: 0
        },
        alerts: {
            hideErrorAlert: true,
            errorAlertMessage: EMPTY_STRING,
            hideInformationAlert: true,
            informationAlertMessage: EMPTY_STRING,
            hideUnauthorizedUserMessage: true
        }
    } as SearchContactSupportCasesMainState;
}