import React from "react";
import {AUDIT_STATUS, AUDIT_TYPE, EMPTY_STRING, RESOURCE_TYPE} from "src/constants/ApplicationCommonConstants";
import {AUDIT_STATUS_LABEL_LIST, AUDIT_STATUS_VALUE_LIST} from "src/constants/ServiceProvidersApiConstants";
import XLargeDropdownWithLabel from "src/components/common/dropdown/XLargeDropdownWithLabel";
import Row from "@amzn/meridian/row";
import MarketplaceValueDropdown
    from "src/components/common/dropdown/MarketplaceValueDropdown";
import DatePicker from "@amzn/meridian/date-picker";
import Button from "@amzn/meridian/button";
import {ServiceProvidersAuditInputProps} from "src/interfaces/props/ServiceProvidersAuditProps";
import {ServiceProvidersAuditInputStates} from "src/interfaces/states/ServiceProviderAuditStates"

export class ServiceProvidersAuditInput extends React.Component<ServiceProvidersAuditInputProps, ServiceProvidersAuditInputStates> {
    constructor(props: ServiceProvidersAuditInputProps) {
        super(props);
        this.state = {
            resourceType: EMPTY_STRING,
            auditTypeValue: EMPTY_STRING,
            auditStatusValue: EMPTY_STRING,
            marketplaceValue: EMPTY_STRING,
            dateValue: EMPTY_STRING
        }
        this.selectMarketplace = this.selectMarketplace.bind(this);
        this.selectAuditType = this.selectAuditType.bind(this);
        this.selectResourceType = this.selectResourceType.bind(this);
        this.selectAuditStatus = this.selectAuditStatus.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    }

    selectMarketplace(event: string) {
        this.setState({marketplaceValue: event});
    }
    selectResourceType(event: string) {
        this.setState({resourceType: event});
    }

    selectAuditType(event: string) {
        this.setState({auditTypeValue: event});
    }

    selectAuditStatus(event: string) {
        this.setState({auditStatusValue: event});
    }

    selectDate(event: string) {
        this.setState({dateValue: event});
    }

    onSubmitButtonClick() {
        this.props.onButtonClick(this.state.resourceType, this.state.marketplaceValue, this.state.auditTypeValue,
            this.state.auditStatusValue, this.state.dateValue);
    }

    render() {
        const AuditType_ValueList = ["PROFILE_IMAGE", "NAME"]
        const AuditType_LabelList = ["PROFILE IMAGE", "NAME"]
        const ResourceType_List = ["TECHNICIAN"]
        return (
            <Row width="100%" widths={["grid-2", "grid-2", "grid-2", "grid-2", "grid-2", "grid-2"]} spacing="large">
                <DatePicker size="medium" value={this.state.dateValue} onChange={this.selectDate} label="Date"/>
                <XLargeDropdownWithLabel
                    defaultValue={this.state.resourceType}
                    onSelect={this.selectResourceType}
                    label={RESOURCE_TYPE}
                    valueList={ResourceType_List}
                    labelList={ResourceType_List}
                />
                <MarketplaceValueDropdown
                    realmValue={this.props.realmValue}
                    stage={this.props.stage}
                    onSelect={this.selectMarketplace}
                    defaultValue={this.state.marketplaceValue}
                />
                <XLargeDropdownWithLabel
                    defaultValue={this.state.auditStatusValue}
                    onSelect={this.selectAuditStatus}
                    label={AUDIT_STATUS}
                    valueList={AUDIT_STATUS_VALUE_LIST}
                    labelList={AUDIT_STATUS_LABEL_LIST}
                />
                <XLargeDropdownWithLabel
                    defaultValue={this.state.auditTypeValue}
                    onSelect={this.selectAuditType}
                    label={AUDIT_TYPE}
                    valueList={AuditType_ValueList}
                    labelList={AuditType_LabelList}
                />
                <Button onClick={this.onSubmitButtonClick} type="secondary" size="large"> Submit </Button>
            </Row>
        );
    }
}
