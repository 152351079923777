import React from "react";
import Column from "@amzn/meridian/column";
import ErrorAlert from "src/components/common/alert/ErrorAlert";

export class InvalidMarketplaceMessage extends React.Component {
    render() {
        const INVALID_MARKETPLACE_MESSAGE = "Invalid marketplace selected. Tool not available in the selected" +
            " marketplace.";
        return (
            <div id="InvalidMarketplaceMessage">
                <Column alignmentHorizontal="center">
                    <ErrorAlert text={INVALID_MARKETPLACE_MESSAGE}/>
                </Column>
            </div>
        );
    }
}

export default InvalidMarketplaceMessage;