import React from "react";
import {AuditStatusProps} from "src/interfaces/props/ExecutionArtifactsProps";
import XLargeDropdownWithLabel from "src/components/common/dropdown/XLargeDropdownWithLabel";
import {AUDIT_STATUS} from "src/constants/ApplicationCommonConstants";
import {AUDIT_STATUS_LABEL_LIST, AUDIT_STATUS_VALUE_LIST} from "src/constants/ExecutionArtifactsApiConstants";

export class AuditStatusDropdown extends React.Component<AuditStatusProps> {

    constructor(props: AuditStatusProps) {
        super(props);
    }

    render() {
        return(
            <XLargeDropdownWithLabel
                defaultValue={this.props.defaultValue}
                onSelect={this.props.onSelect}
                label={AUDIT_STATUS}
                valueList={AUDIT_STATUS_VALUE_LIST}
                labelList={AUDIT_STATUS_LABEL_LIST}
            />
        );
    }
}
export default AuditStatusDropdown;