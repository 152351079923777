import React from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import {TechnicianAssignmentTrailInputProps} from "src/interfaces/props/TechnicianAssignmentTrailProps";
import {TechnicianAssignmentTrailInputStates} from "src/interfaces/states/TechnicianAssignmentTrailStates";
import MediumInput from "src/components/common/input/MediumInput";

export class TechnicianAssignmentTrailInput extends React.Component<TechnicianAssignmentTrailInputProps, TechnicianAssignmentTrailInputStates> {
    constructor(props: TechnicianAssignmentTrailInputProps) {
        super(props);
        this.setJobId = this.setJobId.bind(this);
        this.setOrderId = this.setOrderId.bind(this);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);

        this.state = {
            jobIdValue: EMPTY_STRING,
            orderIdValue: EMPTY_STRING,
            appointmentIdValue: EMPTY_STRING
        };
    }

    setJobId(event: string) {
        this.setState({jobIdValue: event});
    }

    setOrderId(event: string) {
        this.setState({orderIdValue: event});
    }

    onSubmitButtonClick(event: boolean) {
        this.props.onButtonClick(this.state.jobIdValue, this.state.orderIdValue, this.state.appointmentIdValue);
    }

    render() {
        return (
            <div>
                <Row width="100%" widths={["grid-6", "grid-7", "grid-3"]} spacing="large">
                    <MediumInput
                        value={this.state.orderIdValue}
                        onChange={this.setOrderId}
                        type={"text"}
                        placeholder={"Enter value..."}
                        prefix={"Order-Id"}
                        id={"orderIdOfTechnicianAssignTrail"}
                    />
                    <MediumInput
                        value={this.state.jobIdValue}
                        onChange={this.setJobId}
                        type={"text"}
                        placeholder={"Enter value..."}
                        prefix={"Job-Id"}
                        id={"jobIdOfTechnicianAssignTrail"}
                    />
                    <Button onClick={this.onSubmitButtonClick} type="primary" size="medium"> Submit </Button>
                </Row>
            </div>
        );
    }
}

export default TechnicianAssignmentTrailInput;