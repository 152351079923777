import React from 'react';
import Loader from "@amzn/meridian/loader"
import Column from "@amzn/meridian/column";
import {MIDWAY_REDIRECT_MESSAGE} from "src/constants/ApplicationCommonConstants";
import SmallText from "src/components/common/text/SmallText";

export class UnauthenticatedLayout extends React.Component {

    render() {
        return(
            <div>
                <Column alignmentHorizontal="center" spacingInset="xlarge" spacing="large">
                    <Loader type="circular"/>
                    <SmallText text={MIDWAY_REDIRECT_MESSAGE} />
                </Column>
            </div>
        );
    };
}

export default UnauthenticatedLayout;