import React from "react";
import Column from "@amzn/meridian/column";
import MediumText from "src/components/common/text/MediumText";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import plusTokens from "@amzn/meridian-tokens/base/icon/plus-knockout";
import {navigateToCreateNewNotification} from "src/helpers/send-bulk-notifications/SendBulkNotificationsCommonHelper";
import SearchNotificationsPane from "src/components/screens/send-bulk-notifications/SearchNotificationsPane";
import {SendBulkNotificationsDashboardScreenProps} from "src/interfaces/props/SendBulkNotificationsProps";

export default class SendBulkNotificationsDashboardScreen extends React.Component<SendBulkNotificationsDashboardScreenProps> {

    constructor(props: SendBulkNotificationsDashboardScreenProps) {
        super(props);
    }

    render() {
        return (
            <div id={"SendBulkNotificationsDashboardScreen"}>
                <div id={"titleDiv"}>
                    <Column alignmentHorizontal="left" spacingInset="large" spacing="large">
                        <MediumText text={"Send Bulk Notifications"}/>
                    </Column>
                </div>
                <div id={"createNotificationButtonDiv"}>
                    <Row alignmentHorizontal="right" spacingInset="none large" spacing="small">
                        <Button size={"large"} onClick={navigateToCreateNewNotification}>
                            <Icon tokens={plusTokens}/> Create New Notification
                        </Button>
                    </Row>
                </div>
                <div id={"searchPaneDiv"}>
                    <SearchNotificationsPane screenInput={this.props.screenInput}/>
                </div>
            </div>
        );
    }
}
