import {AuditStatusType} from "src/interfaces/CommonTypes";

export const apiCallParameters = {
    EXECUTION_ARTIFACTS_AUDIT_DATA: "ExecutionArtifactsAuditData",
    EXECUTION_ARTIFACTS_AUDIT_LAMBDA: "ExecutionArtifactsAuditLambda",
    GET_AUDIT_DATA: "getAuditData",
    UPDATE_AUDIT_DATA: "updateAuditData",
    PAGE_SIZE: 50,
}
export const AUDIT_STATUS_VALUE_LIST = ["SUCCEEDED", "PENDING", "FAILED", "DATA_NOT_FOUND"]
export const AUDIT_STATUS_LABEL_LIST = ["Succeeded", "Pending", "Failed", "Data Not Found"]
export const AUDIT_STATUS_MAPPING: AuditStatusType = {
    SUCCEEDED: "Matched",
    FAILED: "Not-Matched",
    DATA_NOT_FOUND: "Data Not Found",
    PENDING: "Pending"
};
export const EXECUTION_ARTIFACTS_TOOL = "ExecutionArtifactsAuditAuthZ";