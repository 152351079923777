import React from "react";
import {serviceProviderRowObject, ServiceProvidersAuditTableProps} from "src/interfaces/props/ServiceProvidersAuditProps";
import {ServiceProvidersAuditTableStates} from "src/interfaces/states/ServiceProviderAuditStates";
import {
    ASC,
    ASCENDING,
    DESC,
    EMPTY_STRING,
    SERIAL_NUMBER,
    TABLE_ALIGNMENT
} from "src/constants/ApplicationCommonConstants";
import {searchQuery} from "src/helpers/service-providers-audit/ServiceProvidersAuditHelper";
import {serviceProvidersAuditData} from "src/helpers/service-providers-audit/ServiceProvidersAuditAPIs";
import {
    apiCallParameters,
    NO_ERROR,
    LAST_UPDATED_BY_SYSTEM, PENDING_MANUAL_REVIEW
} from "src/constants/ServiceProvidersApiConstants";
import Row from "@amzn/meridian/row";
import SearchField from "@amzn/meridian/search-field";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import ServiceProvidersAuditSelfieImage from "src/components/screens/service-providers-audit/ServiceProvidersAuditSelfieImage";
import SmallText from "src/components/common/text/SmallText";
import Column from "@amzn/meridian/column";
import ServiceProvidersAuditButtons from "src/components/screens/service-providers-audit/ServiceProvidersAuditButtons";
import Pagination from "@amzn/meridian/pagination";
import orderBy from "lodash/orderBy"
import {SortState} from "src/interfaces/states/ServiceProviderAuditStates";
import {CSVLink} from "react-csv";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon"
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large"

export class ServiceProvidersAuditTable extends React.Component<ServiceProvidersAuditTableProps, ServiceProvidersAuditTableStates> {
    constructor(props: ServiceProvidersAuditTableProps) {
        super(props);
        this.state = {
            openMatchButtonModal: false,
            openNoMatchButtonModal: false,
            searchValue: EMPTY_STRING,
            result: this.props.resultData,
            currentPage: 1,
            sortColumn: SERIAL_NUMBER,
            sortDirection: ASCENDING,
            auditIdToBeAudited: EMPTY_STRING
        }
        this.onMatchModalClick = this.onMatchModalClick.bind(this);
        this.onNoMatchModalClick = this.onNoMatchModalClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.submitSearchQuery = this.submitSearchQuery.bind(this);
        this.clearSearchQuery = this.clearSearchQuery.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.getAuditRecordToDownloadableFormatMapping = this.getAuditRecordToDownloadableFormatMapping.bind(this);
    }
    changeSearchValue(value: string) {
        this.setState({searchValue: value});
    }

    sortTable(sortState: SortState) {
        this.setState(sortState);
    }
    setCurrentPage(value: number) {
        this.setState({currentPage: value});
    }

    onMatchModalClick(event: string) {
        this.setState({openMatchButtonModal: true});
        this.setState({auditIdToBeAudited: event});
    }

    onNoMatchModalClick(event: string) {
        this.setState({openNoMatchButtonModal: true});
        this.setState({auditIdToBeAudited: event});
    }

    submitSearchQuery(event: boolean) {
        if (this.state.searchValue == EMPTY_STRING) {
            this.setState({result: this.props.resultData})
        } else {
            let queryResults = [] as serviceProviderRowObject[];
            for (let index = 0; index < this.props.resultData.length; index++) {
                if (searchQuery(this.props.resultData[index], this.state.searchValue)) {
                    queryResults.push(this.props.resultData[index]);
                }
            }
            this.setState({result: queryResults})
            this.setState({currentPage: 1})
        }
    }
    clearSearchQuery(event: boolean) {
        this.setState({result: this.props.resultData})
        this.setState({currentPage: 1})
    }

    onCancelClick(event: boolean) {
        this.setState({openMatchButtonModal: false});
        this.setState({openNoMatchButtonModal: false});
        this.setState({auditIdToBeAudited: EMPTY_STRING})
    }

    async onConfirmClick(resourceType: string, auditType: string, marketPlaceId: string, merchantId: string,
                   action: string, auditId: string, resourceId: string, userName: string) {
        let payloadObject = {
            resourceType: resourceType,
            auditType: auditType,
            marketPlaceId: marketPlaceId,
            merchantId: merchantId,
            action: action,
            auditId: auditId,
            resourceId: resourceId,
            userName: userName,
        }
        let response = await serviceProvidersAuditData(apiCallParameters.UPDATE_AUDIT_DATA, userName, JSON.stringify(payloadObject));
        if (response.errorMessage == null) {
            this.setState({result: this.state.result.filter((item) => item.auditId !== auditId)})
        }
        this.setState({openMatchButtonModal: false});
        this.setState({openNoMatchButtonModal: false});
        this.setState({auditIdToBeAudited: EMPTY_STRING})
    }

    getAuditRecordToDownloadableFormatMapping() {
        return this.state.result.map(auditRecord =>
            ({resourceType: auditRecord.resourceType, auditType: auditRecord.auditType, marketplaceId: auditRecord.marketPlaceId,
                merchantId: auditRecord.merchantId, resourceId: auditRecord.resourceId, referenceType: auditRecord.referenceType,
                matchedFacesSimilarityScore: auditRecord.matchedFacesSimilarityScore, auditStatus: auditRecord.auditStatus,
                lastUpdatedBy: auditRecord.lastUpdatedBy, error: auditRecord.errorMessage}))
    }

    render() {
        const sortedTabularResult = orderBy(
           this.state.result,
            [this.state.sortColumn],
            [this.state.sortDirection === ASCENDING ? ASC : DESC]
        )

        const itemsPerPage = 10;
        const firstVisibleIndex = (this.state.currentPage - 1) * itemsPerPage;
        const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
        const numberOfPages = Math.ceil(sortedTabularResult.length / itemsPerPage);
        let serialNumber = firstVisibleIndex;
        return (
            <div>
                <Row width="100%" widths={["grid-8", "grid-4"]} spacing="large" spacingInset="medium">
                    <CSVLink filename={"ServiceProviderAuditRecords.csv"} data={this.getAuditRecordToDownloadableFormatMapping()}>
                        <Heading level={4}> {"Export Records  "} <Icon tokens={downloadLargeTokens} /> </Heading>
                    </CSVLink>
                    <SearchField
                        value={this.state.searchValue}
                        onChange={this.changeSearchValue}
                        onSubmit={this.submitSearchQuery}
                        onClear={this.clearSearchQuery}
                        size="small"
                        placeholder="Enter data to search in table"
                    />
                </Row>
                <div style={{overflowX: "auto", maxWidth: "100%"}}>
                    <Table headerRows={1} showDividers={true} sortColumn={this.state.sortColumn}
                           sortDirection={this.state.sortDirection} onSort={this.sortTable}>
                        <TableRow highlightOnHover={true}>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="sNo">S.No.</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Resource Type</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Audit Type</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>MarketPlaceId</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="merchantId">MerchantId</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="resourceId">ResourceId</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>AuditData</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>ReferenceData</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>ReferenceType</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="matchedFacesSimilarityScore">Matched Faces Similarity Score</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Audit Status</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Last Updated By</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Error</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Audit Action</TableCell>
                        </TableRow>
                        {
                            sortedTabularResult.slice(firstVisibleIndex, lastVisibleIndex)
                                .map((row: serviceProviderRowObject) => (<TableRow highlightOnHover={true}>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{++serialNumber}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.resourceType}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.auditType}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.marketPlaceId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.merchantId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.resourceId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <div>
                                            {(() => {
                                                switch(row.auditType) {
                                                    case "PROFILE_IMAGE":
                                                        return (
                                                            <ServiceProvidersAuditSelfieImage
                                                                text="Profile Image"
                                                                image={row.auditData}
                                                                profileImage={row.auditData}
                                                            />);
                                                    case "NAME":
                                                        return (row.auditData);
                                                }
                                            })()
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <div>
                                            {(() => {
                                                switch(row.auditType) {
                                                    case "PROFILE_IMAGE":
                                                        return (
                                                            <ServiceProvidersAuditSelfieImage
                                                                text="Reference Image"
                                                                image={row.referenceData}
                                                                profileImage={row.auditData}
                                                            />);
                                                    case "NAME":
                                                        return (row.referenceData);
                                                }
                                            })()
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.referenceType}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}  >
                                        {(() => {
                                            if (row.matchedFacesSimilarityScore == null) {
                                                return (
                                                    <SmallText text={"_"} />
                                                );
                                            } else return (row.matchedFacesSimilarityScore);
                                        })()}
                                    </TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.auditStatus}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.lastUpdatedBy}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        {(() => {
                                            if (row.errorMessage == null) {
                                                return (
                                                    <SmallText text={NO_ERROR}/>
                                                );
                                            } else return (row.errorMessage);
                                        })()}
                                    </TableCell>
                                    <TableCell>
                                        <Column height="50px" heights="fill" alignmentHorizontal="center"
                                                spacing="small" alignmentVertical="center">
                                            <ServiceProvidersAuditButtons
                                                title={"Audit Data Match"}
                                                type={"primary"}
                                                minWidth={"100px"}
                                                buttonName={"Match"}
                                                openModal={this.state.openMatchButtonModal && this.state.auditIdToBeAudited == row.auditId}
                                                isDisabled={row.lastUpdatedBy !== LAST_UPDATED_BY_SYSTEM && row.auditStatus !== PENDING_MANUAL_REVIEW}
                                                onModalClick={() => this.onMatchModalClick(row.auditId)}
                                                onCancelClick={this.onCancelClick}
                                                onConfirmClick={() => this.onConfirmClick(row.resourceType, row.auditType, row.marketPlaceId,
                                                    row.merchantId, "MATCH", row.auditId, row.resourceId, this.props.userLogin)}
                                            />
                                            <ServiceProvidersAuditButtons
                                                title={"Audit Data No-Match"}
                                                type={"secondary"}
                                                minWidth={""}
                                                buttonName={"No Match"}
                                                openModal={this.state.openNoMatchButtonModal && this.state.auditIdToBeAudited == row.auditId}
                                                isDisabled={row.lastUpdatedBy !== LAST_UPDATED_BY_SYSTEM && row.auditStatus !== PENDING_MANUAL_REVIEW}
                                                onModalClick={() => this.onNoMatchModalClick(row.auditId)}
                                                onCancelClick={this.onCancelClick}
                                                onConfirmClick={() => this.onConfirmClick(row.resourceType, row.auditType, row.marketPlaceId,
                                                    row.merchantId, "NO_MATCH", row.auditId, row.resourceId, this.props.userLogin)}
                                            />
                                        </Column>
                                    </TableCell>
                                </TableRow>))
                        }
                    </Table>
                    <Pagination
                        showSkipArrows={true}
                        numberOfPages={numberOfPages}
                        onChange={this.setCurrentPage}
                        currentPage={this.state.currentPage}
                    />
                </div>
            </div>

        );
    }
}

export default ServiceProvidersAuditTable;