import {
    DeliveryChannel,
    DispatchStyle,
    NotificationType,
    RecipientLevel
} from "src/interfaces/enums/SendBulkNotificationsEnums";

export const SEND_BULK_NOTIFICATIONS_TOOL = "VasFeuPortalNotificationServiceAuthZ";

export const apiCallParameters = {
    VAS_NOTIFICATION_SERVICE_LAMBDA: "VasFeuPortalNotificationServiceLambda",
    VAS_NOTIFICATION_SERVICE_DATA: "VasFeuPortalNotificationServiceData",
}

export const DELIVERY_CHANNEL_VALUES = [DeliveryChannel.MOBILE_PUSH]
export const DELIVERY_CHANNEL_LABELS = ["Push notification on Sell Services App"]

export const DISPATCH_STYLE_VALUES = [DispatchStyle.INSTANT]
export const DISPATCH_STYLE_LABELS = ["Instant Notification"]

export const RECIPIENT_LEVEL_VALUES = [RecipientLevel.TECHNICIAN, RecipientLevel.MERCHANT]
export const RECIPIENT_LEVEL_LABELS = [
    "Send notification to the specified technicians",
    "Send notification to all the technicians under the specified merchants"
]

export const NOTIFICATION_TYPE_VALUES = [NotificationType.COMMON, NotificationType.CUSTOMIZED]
export const NOTIFICATION_TYPE_LABELS = [
    "Send a common notification to all the recipients",
    "Send a customized notification to each of the recipients"
]

export const PAGE_SIZE_LABELS = ["5", "10", "15", "20", "25"];
export const PAGE_SIZE_VALUES = ["5", "10", "15", "20", "25"];

export const NOTIFICATION_MESSAGE_MAX_LENGTH = 200;

