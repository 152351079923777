import React from "react";
import Button from "@amzn/meridian/button";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import FileInput from "@amzn/meridian/file-input";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import {UploadImageProps} from "src/interfaces/props/TechnicianProfileEditProps";
import {ModalState} from "src/interfaces/states/CommonStates";

export class UploadImage extends React.Component<UploadImageProps, ModalState> {
    constructor(props:UploadImageProps) {
        super(props);

        this.state = {
            openModal: false
        }

        this.onRemoveUploadedFileButtonClick = this.onRemoveUploadedFileButtonClick.bind(this);
        this.onModalUploadButtonClick = this.onModalUploadButtonClick.bind(this);
        this.onUploadButtonClick = this.onUploadButtonClick.bind(this);
    }

    onRemoveUploadedFileButtonClick() {
        this.setState({openModal: false})
        this.props.removeUploadedFile();
    }

    onModalUploadButtonClick() {
        this.setState({openModal:false})
    }

    onUploadButtonClick(acceptedFile: File[]) {
        this.setState({openModal:true})
        this.props.onFileUploadComplete(acceptedFile);
    }

    render() {
        return (
            <div>
                <FileInput
                    onFileAttached={acceptedFile => this.onUploadButtonClick(acceptedFile)}
                    uploadButtonType={"link"}
                    uploadButtonLabel= {EMPTY_STRING}
                    uploadButtonDisabled={this.props.isHidden}
                >
                </FileInput>

                <Modal title={"Uploaded File"} open={this.state.openModal} scrollContainer="viewport" bodySpacingInset="medium" onClose={this.onRemoveUploadedFileButtonClick}>
                    <Row alignmentHorizontal={"center"}>
                        <img id={"ImageId"} src={this.props.imageSource} alt={"Image can not bo displayed"} width={"240"} height={"280"} />
                    </Row>
                    <ModalFooter>
                        <Row alignmentHorizontal="center" widths={["grid-6", "grid-6"]} spacing="large">
                            <Button type="primary" size="small" onClick={this.onModalUploadButtonClick}> Upload </Button>
                            <Button type="secondary" size="small" onClick={this.onRemoveUploadedFileButtonClick}> Cancel </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}