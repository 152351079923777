import React from "react";
import Select, {SelectOption} from "@amzn/meridian/select";
import {XLargeDropdownWithLabelProps} from "src/interfaces/props/CommonProps";
import {DropDown} from "src/components/common/dropdown/DropDown";
/**
 * This component is deprecated in favor of {@link DropDown} and it's other components
 */
export class XLargeDropdownWithLabel extends React.Component<XLargeDropdownWithLabelProps> {

    constructor(props: XLargeDropdownWithLabelProps) {
        super(props);
    }

    render() {
        return(
            <Select value={this.props.defaultValue} onChange={this.props.onSelect} label={this.props.label} width={300} size="xlarge">
                    {(() => {
                        const dropdownElements: JSX.Element[] = [];
                        for(let index = 0; index < this.props.valueList.length; index++) {
                            dropdownElements.push(<SelectOption value={this.props.valueList[index]} label={this.props.labelList[index]}/>)
                        }
                        return(dropdownElements);
                    })()
                    }
            </Select>
        );
    }
}
export default XLargeDropdownWithLabel;
