import React from "react";
import Divider from "@amzn/meridian/divider";
import Column from "@amzn/meridian/column";
import LargeText from "src/components/common/text/LargeText";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import {DataFetchState} from "src/interfaces/CommonTypes";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import {TechnicianAssignmentTrailScreenProps} from "src/interfaces/props/TechnicianAssignmentTrailProps";
import {TechnicianAssignmentTrailScreenStates} from "src/interfaces/states/TechnicianAssignmentTrailStates";
import TechnicianAssignmentTrailInput
    from "src/components/screens/technician-assignment-trail/TechnicianAssignmentTrailInput";
import {fetchTechnicianAssignmentTrail} from "src/helpers/technician-assignment-trail/TechnicianAssignmentTrailApis";
import TechnicianAssignmentTrailResponse
    from "src/components/screens/technician-assignment-trail/TechnicianAssignmentTrailResponse";
import SmallText from "src/components/common/text/SmallText";
import StringUtils from "src/utils/StringUtils";

export class TechnicianAssignmentTrailScreen extends React.Component<TechnicianAssignmentTrailScreenProps, TechnicianAssignmentTrailScreenStates> {

    constructor(props: TechnicianAssignmentTrailScreenProps) {
        super(props);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.validateInputs = this.validateInputs.bind(this);
        this.getTechnicianAssignmentTrail = this.getTechnicianAssignmentTrail.bind(this);

        this.state = {
            resultData: EMPTY_STRING,
            inputValidationStatus: false,
            trailDataFetchState: DataFetchState.RequestNotMade,
            resultDataArray: []
        };
    }

    validateInputs(jobIdValue: string, orderIdValue: string, appointmentIdValue: string) {
        if (StringUtils.isEmpty(jobIdValue) && StringUtils.isEmpty(orderIdValue) && StringUtils.isEmpty(orderIdValue)) {
            this.setState({inputValidationStatus: false});
            return false;
        } else {
            this.setState({inputValidationStatus: true});
            return true;
        }
    };

    async getTechnicianAssignmentTrail(userLogin: string, payload: string) {
        this.setState({trailDataFetchState: DataFetchState.FetchingData});
        let response;
        try {
            response = await fetchTechnicianAssignmentTrail(userLogin, payload);
            this.parseResponse(response.ResponsePayload, response.ValidUser)
        } catch (error) {
            this.setState({trailDataFetchState: DataFetchState.DataFetchFailed});
        }
    };

    parseResponse(responsePayload: string, validUser: boolean) {
        if (responsePayload == null && validUser == true) {
            this.setState({trailDataFetchState: DataFetchState.NoDataFound});
        } else if (responsePayload == null && validUser == false) {
            this.setState({trailDataFetchState: DataFetchState.UnauthorizedUser});
        } else {
            this.setState({resultData: responsePayload});
            this.setState({resultDataArray: JSON.parse(this.state.resultData)});
            this.setState({trailDataFetchState: DataFetchState.DataFetchedSuccessfully});
        }
    }

    onSubmitButtonClick(jobIdValue: string, orderIdValue: string, appointmentIdValue: string) {
        if (this.validateInputs(jobIdValue, orderIdValue, appointmentIdValue)) {
            this.getTechnicianAssignmentTrail(this.props.screenInput.username,
                JSON.stringify(this.createPayloadObject(jobIdValue, orderIdValue, appointmentIdValue)));
        } else {
            this.setState({trailDataFetchState: DataFetchState.InvalidRequestMade});
        }
    };

    createPayloadObject(jobIdValue: string, orderIdValue: string, appointmentIdValue: string) {
        let payloadObject = {
            orderId: orderIdValue,
            jobId: jobIdValue,
            appointmentId: appointmentIdValue
        }
        return payloadObject;
    }

    render() {
        return (
            <div id="TechnicianAssignmentTrailScreen">
                <div id="ParameterSelectionSection">
                    <Column alignmentHorizontal="left" spacingInset="xlarge" spacing="large">
                        <LargeText text={ApplicationScreenConstants.TECHNICIAN_ASSIGNMENT_TRAIL.SCREEN_NAME}/>
                        <SmallText text={ApplicationScreenConstants.TECHNICIAN_ASSIGNMENT_TRAIL.SCREEN_INTRO}/>
                        <TechnicianAssignmentTrailInput
                            onButtonClick={this.onSubmitButtonClick}
                        />
                        <Divider size="small"/>
                    </Column>
                </div>

                <div id="ResponseSection">
                    <TechnicianAssignmentTrailResponse
                        trailDataFetchState={this.state.trailDataFetchState}
                        tableInput={this.state.resultDataArray}
                    />
                </div>
            </div>
        );
    }
}

export default TechnicianAssignmentTrailScreen;