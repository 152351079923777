/* eslint-disable @typescript-eslint/no-magic-numbers */
/*
 * Copy of https://code.amazon.com/packages/MidwayIdentityCredentialProvider/blobs/mainline/--/src/tokenRetriever.js
 * that can't be imported because it's not defined in the credentials.js file or exported there.
 */
import {ID_TOKEN, OPEN_ID, GET, CHARACTER_SETS} from "src/constants/MidwayAuthNConstants";
const generateNonce = function () {
    let nonce = '';
    const characterSet = CHARACTER_SETS;

    for (let i = 0; i < 64; i = i + 1) {
        nonce = nonce + characterSet.charAt(Math.floor(Math.random() * characterSet.length));
    }
    return nonce;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildQuery = function (parameters: any) {
    return Object.keys(parameters).reduce((accumulator, key) => `${accumulator + key}=${parameters[key]}&`, '?');
};

const buildSSOUrl = function () {
    const queryParams = {
        response_type: ID_TOKEN,
        client_id: encodeURIComponent(window.location.host),
        redirect_uri: encodeURIComponent(window.location.href),
        scope: OPEN_ID,
        nonce: generateNonce(),
    };

    const midwaySSOURL = `https://midway-auth.amazon.com/SSO${buildQuery(queryParams)}`;
    return midwaySSOURL;
};

const buildRedirectUrl = function () {
    const queryParams = {
        client_id: encodeURIComponent(window.location.host),
        redirect_uri: encodeURIComponent(window.location.href),
        response_type: ID_TOKEN,
        scope: OPEN_ID,
        nonce: generateNonce(),
    };

    const midwayRedirectURL = `https://midway-auth.amazon.com/login?next=/SSO/redirect${encodeURIComponent(buildQuery(queryParams))}`;
    return midwayRedirectURL;
};

export const getTokenOrRedirect = async function () {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.withCredentials = true;
        xhr.open(GET, buildSSOUrl());
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.responseText);
            } else {
                window.location.assign(buildRedirectUrl());
            }
        };
        xhr.send();
    });
};