export const apiCallParameters = {
    LOAD_AUDIT_DATA: "loadAudits",
    UPDATE_AUDIT_DATA: "auditAction",
    SERVICE_PROVIDERS_API_NAME: "ServiceProvidersAudit",
    SERVICE_PROVIDERS_LAMBDA: "ServiceProvidersAuditLambda",
}

export const AUDIT_STATUS_VALUE_LIST = ["APPROVED", "IN_PROGRESS", "PENDING_MANUAL_REVIEW", "FAILED", "NOT_STARTED"]
export const AUDIT_STATUS_LABEL_LIST = ["APPROVED", "IN PROGRESS", "PENDING MANUAL REVIEW", "FAILED", "NOT STARTED"]
export const TABLE_NAME = "Audit Service Providers Table"
export const NO_ERROR = "No Error"
export const RESOURCE_ID = "resourceId"
export const DATE_FORMAT = "MM/DD/YYYY"
export const SERVICE_PROVIDERS_TOOL = "VASServiceProvidersAuditAuthZ"
export const LAST_UPDATED_BY_SYSTEM = "SYSTEM"
export const PENDING_MANUAL_REVIEW = "PENDING_MANUAL_REVIEW"

