import React from "react";
import Column from "@amzn/meridian/column";
import {ExecutionArtifactsAuditActionStates} from "src/interfaces/states/ExecutionArtifactsScreenStates";
import {ExecutionArtifactsAuditActionProps} from "src/interfaces/props/ExecutionArtifactsProps";
import {updateExecutionArtifactsAuditData} from "src/helpers/audit-execution-artifacts/ExecutionArtifactsApis";
import ExecutionArtifactsActionButtons from "src/components/screens/audit-execution-artifacts/ExecutionArtifactsActionButtons";

export class ExecutionArtifactsAuditAction extends React.Component<ExecutionArtifactsAuditActionProps, ExecutionArtifactsAuditActionStates> {

    constructor(props: ExecutionArtifactsAuditActionProps) {
        super(props);
        this.onMatchModalClick = this.onMatchModalClick.bind(this);
        this.onNoMatchModalClick = this.onNoMatchModalClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.updateAuditAction = this.updateAuditAction.bind(this);
        this.state = {
            openMatchButtonModal: false,
            openNoMatchButtonModal: false,
            isRecordUpdateSuccessful: true
        }
    }

    onMatchModalClick(event: boolean) {
        this.setState({openMatchButtonModal: true});
    }

    onNoMatchModalClick(event: boolean) {
        this.setState({openNoMatchButtonModal: true});
    }

    onCancelClick(event: boolean) {
        this.setState({openMatchButtonModal: false});
        this.setState({openNoMatchButtonModal: false});
    }

    async updateAuditAction(userLogin: string, payload: string) {
        try {
            await updateExecutionArtifactsAuditData(userLogin, payload);
        } catch (error) {
            this.setState({isRecordUpdateSuccessful: false});
        }
    }

    onConfirmClick(buttonType: string) {
        let payloadObject = {
            UserLogin: this.props.username,
            AppointmentId: this.props.auditRecord.appointmentId,
            JobId: this.props.auditRecord.jobId,
            OrderId: this.props.auditRecord.orderId,
            MerchantId: this.props.auditRecord.merchantId,
            TechnicianId: this.props.auditRecord.technicianId,
            Action: buttonType,
            Date: this.props.dateValue,
            Version: this.props.auditRecord.version,
            MarketplaceId: this.props.marketplaceId
        }
        this.updateAuditAction(this.props.username, JSON.stringify(payloadObject)).then(Response => {
                if(this.state.isRecordUpdateSuccessful) {
                    this.props.removeRecordAfterGettingAudited(this.props.auditRecord)
                }
            });
        this.setState({openMatchButtonModal: false});
        this.setState({openNoMatchButtonModal: false});
    }

    render() {
        return (
            <div>
                <Column height="50px" heights="fill" alignmentHorizontal="center" spacing="small" alignmentVertical="center">
                    <ExecutionArtifactsActionButtons
                        title={"Audit Data Match"}
                        type={"primary"}
                        minWidth={"100px"}
                        buttonName={"Match"}
                        disableFlag={(this.props.auditRecord.version > 1)}
                        openModal={this.state.openMatchButtonModal}
                        onModalClick={this.onMatchModalClick}
                        onCancelClick={this.onCancelClick}
                        onConfirmClick={() => this.onConfirmClick("MATCH")}
                    />
                    <ExecutionArtifactsActionButtons
                        title={"Audit Data No-Match"}
                        type={"secondary"}
                        minWidth={""}
                        buttonName={"No Match"}
                        disableFlag={(this.props.auditRecord.version > 1)}
                        openModal={this.state.openNoMatchButtonModal}
                        onModalClick={this.onNoMatchModalClick}
                        onCancelClick={this.onCancelClick}
                        onConfirmClick={() => this.onConfirmClick("NO_MATCH")}
                    />
                </Column>
            </div>
        );
    }
}
export default ExecutionArtifactsAuditAction;