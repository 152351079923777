import {RealmValues, Stages} from "src/interfaces/CommonTypes";
import {ScreenProps} from "src/interfaces/props/CommonProps";

export const setScreenProps = (username: string, stage: Stages, realmValue: RealmValues) => {
    const props: ScreenProps = {
        username: username,
        stage: stage,
        realmValue: realmValue
    }
    return props;
}