import React from "react";
import {AuditTypeProps} from "src/interfaces/props/ExecutionArtifactsProps";
import {AUDIT_TYPE} from "src/constants/ApplicationCommonConstants";
import XLargeDropdownWithLabel from "src/components/common/dropdown/XLargeDropdownWithLabel";

export class AuditTypeDropdown extends React.Component<AuditTypeProps> {

    constructor(props: AuditTypeProps) {
        super(props);
    }

    render() {
        const ValueList = ["AUDIT_TYPE_SELFIE"]
        const LabelList = ["Audit Selfie"]

        return(
            <XLargeDropdownWithLabel
                defaultValue={this.props.defaultValue}
                onSelect={this.props.onSelect}
                label={AUDIT_TYPE}
                valueList={ValueList}
                labelList={LabelList}
            />
        );
    }
}
export default AuditTypeDropdown;