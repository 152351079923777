import {Auth} from 'aws-amplify';
import {getUsername, refreshMidway} from './MidwayTokenRetriever';
import {MIDWAY_DOMAIN} from 'src/constants/MidwayAuthNConstants';

export const authenticateAmplify = async () => {
    try {
        const credentials = await refreshMidway();
        const user = await getUsername(credentials);
        try {
            /*
             * currentAuthenticated can be used to check if a user is logged in when the page is loaded.
             * It will throw an error if there is no user logged in
             * https://aws-amplify.github.io/docs/js/authentication#retrieve-current-authenticated-user
             */
            await Auth.currentAuthenticatedUser();
            return user;
        } catch (err) {
            await Auth.federatedSignIn(MIDWAY_DOMAIN, credentials, user);
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Error occured during authentication ${err}`);
    }
};