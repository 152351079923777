import React from "react";
import {DropDownCommonProps} from "src/interfaces/props/CommonProps";
import DropDown from "src/components/common/dropdown/DropDown";

export class XLargeDropDown extends React.Component<DropDownCommonProps> {
    constructor(props: DropDownCommonProps) {
        super(props);
    }

    render() {
        return (
            <DropDown commonProps={this.props} size={"xlarge"}/>
        );
    }

}

export default XLargeDropDown;
