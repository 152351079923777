import React from "react";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import bulbTokens from "@amzn/meridian-tokens/base/icon/bulb";
import SmallText from "src/components/common/text/SmallText";

export class DefaultStartScreenMessage extends React.Component {
    render() {
        const DEFAULT_SCREEN_MESSAGE = "Tip: Select parameters from the section above to load the desired results.";
        return(
            <div id="DefaultStartScreenMessage">
                <Column alignmentHorizontal="center">
                    <Icon tokens={bulbTokens}/>
                    <SmallText text={DEFAULT_SCREEN_MESSAGE} />
                </Column>
            </div>
        );
    }
}
export default DefaultStartScreenMessage;