import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";

class StringUtils {
    static isEmpty(str: string | undefined): boolean {
        return str === EMPTY_STRING || str === null ||str === undefined;
    }

    static isNotEmpty(str: string | undefined): boolean {
        return !this.isEmpty(str);
    }

    static getValueOrDefault(str: string | undefined | null, defaultValue: string): string {
        return str ? str : defaultValue;
    }
}

export default StringUtils;
