import React from "react";
import RadioButton from "@amzn/meridian/radio-button";
import Column from "@amzn/meridian/column";
import {RadioButtonGroupProps} from "src/interfaces/props/CommonProps";

export default class RadioButtonGroup<T>
    extends React.Component<RadioButtonGroupProps<T>> {

    constructor(props: RadioButtonGroupProps<T>) {
        super(props);
    }

    render() {
        return (
            <div id={"radioButtonGroup"}>
                <Column>
                    {this.props.values.map((value, index) => {
                        return (
                            <RadioButton checked={this.props.selectedValue == value}
                                     onChange={this.props.onChange} value={value}
                                     disabled={this.props.disabled} key={index}>
                                {this.props.labels[index]}
                            </RadioButton>
                        )
                    })}
                </Column>
            </div>
        );
    }
}