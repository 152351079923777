import React from "react"
import ApplicationLayout from "src/components/layout/ApplicationLayout";
import {LayoutProps} from "src/interfaces/props/CommonProps";

export class AuthenticatedLayout extends React.Component<LayoutProps> {

    constructor(props: LayoutProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <ApplicationLayout
                    username={this.props.username}
                    changeRealm={this.props.changeRealm}
                    stage={this.props.stage}
                    realmValue={this.props.realmValue}
                    nextPageOffset={this.props.nextPageOffset}
                    switchApplicationTheme={this.props.switchApplicationTheme}
                    darkTheme={this.props.darkTheme}
                />
            </div>
        );
    }
}
export default AuthenticatedLayout;