import React from "react";
import Column from "@amzn/meridian/column";
import ErrorAlert from "src/components/common/alert/ErrorAlert";

export class InvalidRequestMessage extends React.Component {
    render() {
        const INVALID_REQUEST_MESSAGE = "Input field(s) missing. Please select all input fields to fetch desired results.";
        return(
            <div id="InvalidRequestMessage">
                <Column alignmentHorizontal="center">
                    <ErrorAlert text={INVALID_REQUEST_MESSAGE} />
                </Column>
            </div>
        );
    }
}
export default InvalidRequestMessage;