import React from "react";
import Column from "@amzn/meridian/column";
import ErrorAlert from "src/components/common/alert/ErrorAlert";

export class UnauthorizedUserMessage extends React.Component {
    render() {
        const UNAUTHORIZED_USER_MESSAGE = "You don't have required permissions to access this tool. " +
            "Please request for required permission(s) to access the tool.";
        return(
            <div id="UnauthorizedUserMessage">
                <Column alignmentHorizontal="center" spacingInset="xlarge" spacing="large">
                    <ErrorAlert text={UNAUTHORIZED_USER_MESSAGE} />
                </Column>
            </div>
        );
    }
}
export default UnauthorizedUserMessage;