import React from "react";
import Column from "@amzn/meridian/column";
import InformalAlert from "src/components/common/alert/InformalAlert";

export class AuthorizingUserAccessMessage extends React.Component {
    render() {
        const AUTHORIZATION_MESSAGE = "Please wait. Authorizing your sign in.";
        return(
            <div id="AuthorizingUserAccessMessage">
                <Column alignmentHorizontal="center" spacingInset="xlarge" spacing="large">
                    <InformalAlert text={AUTHORIZATION_MESSAGE} />
                </Column>
            </div>
        );
    }
}
export default AuthorizingUserAccessMessage;