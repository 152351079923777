import React from "react";
import {
    TechnicianAssignmentTrailDataProps,
    TechnicianAssignmentTrailObject
} from "src/interfaces/props/TechnicianAssignmentTrailProps";
import {
    ASC,
    ASCENDING,
    COMMA_SEPARATOR,
    DESC,
    DESCENDING,
    EMPTY_STRING,
    NO_DATA,
    TABLE_ALIGNMENT,
    UPDATE_TIME, WHITESPACE_SEPARATOR
} from "src/constants/ApplicationCommonConstants";
import {SortState, TechnicianAssignmentTrailDataStates} from "src/interfaces/states/TechnicianAssignmentTrailStates";
import orderBy from "lodash/orderBy";
import Level4Heading from "src/components/common/heading/Level4Heading";
import Row from "@amzn/meridian/row";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Pagination from "@amzn/meridian/pagination"
import DateTimeUtils from "src/utils/DateTimeUtils";
import SmallText from "src/components/common/text/SmallText";
import StringUtils from "src/utils/StringUtils";

export class TechnicianAssignmentTrailData extends React.Component<TechnicianAssignmentTrailDataProps, TechnicianAssignmentTrailDataStates> {
    constructor(props: TechnicianAssignmentTrailDataProps) {
        super(props);
        this.sortTable = this.sortTable.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.state = {
            resultData: this.props.tableInput,
            sortColumn: UPDATE_TIME,
            sortDirection: DESCENDING,
            currentPage: 1
        }
    }

    sortTable(sortState: SortState) {
        this.setState(sortState);
    }

    setCurrentPage(event: number) {
        this.setState({currentPage: event})
    }

    render() {
        const itemsPerPage = 10;
        const numberOfPages = Math.ceil(this.state.resultData.length / itemsPerPage);
        const startIndex = (this.state.currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage

        const sortedResult = orderBy(
            this.state.resultData,
            [this.state.sortColumn],
            [this.state.sortDirection === ASCENDING ? ASC : DESC]
        )
        const TABLE_NAME = "Technician Assignment Audit Trail"

        return (
            <div id="TrailData">
                <Row width="100%" widths={["grid-8", "grid-4"]} spacing="large" spacingInset="medium">
                    <Level4Heading text={TABLE_NAME}/>
                </Row>
                <div style={{overflowX: "auto", maxWidth: "100%"}}>
                    <Table headerRows={1} showDividers={true} sortColumn={this.state.sortColumn}
                           sortDirection={this.state.sortDirection} onSort={this.sortTable}>
                        <TableRow highlightOnHover={true}>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>S.No.</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="orderId">Order Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="jobId">Job Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="appointmentId">Appointment
                                Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="technicianIds">Technician
                                Id(s)</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="assigneeId">Assignee
                                Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="updateTime">Update
                                Time</TableCell>
                        </TableRow>
                        {
                            sortedResult.slice(startIndex, endIndex)
                                .map((row: TechnicianAssignmentTrailObject, serialNumber: number) => (
                                    <TableRow key={serialNumber} highlightOnHover={true}>
                                        <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{serialNumber + 1}</TableCell>
                                        <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.orderId}</TableCell>
                                        <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.jobId}</TableCell>
                                        <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.appointmentId}</TableCell>
                                        <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                            {(() => {
                                                if (row.assignedTechnicians.length == 0) {
                                                    return (
                                                        <SmallText text={NO_DATA}/>
                                                    );
                                                } else {
                                                    var assignedTechnicianIds = EMPTY_STRING;
                                                    for (var i = 0; i < row.assignedTechnicians.length - 1; i++) {
                                                        assignedTechnicianIds = assignedTechnicianIds.concat(row.assignedTechnicians[i].technicianId, COMMA_SEPARATOR, WHITESPACE_SEPARATOR);
                                                    }
                                                    assignedTechnicianIds = assignedTechnicianIds.concat(row.assignedTechnicians[i].technicianId);
                                                    return (assignedTechnicianIds);
                                                }
                                            })()
                                            }
                                        </TableCell>
                                        <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                            {(() => {
                                                if (StringUtils.isEmpty(row.updatePerformer.encryptedCustomerId)) {
                                                    return (
                                                        <SmallText text={NO_DATA}/>
                                                    );
                                                } else return (row.updatePerformer.encryptedCustomerId);
                                            })()
                                            }
                                        </TableCell>
                                        <TableCell
                                            alignmentHorizontal={TABLE_ALIGNMENT}>{DateTimeUtils.getDateAndTimeString(row.updateTime)}</TableCell>
                                    </TableRow>
                                ))
                        }
                    </Table>
                    <Pagination
                        showSkipArrows={true}
                        numberOfPages={numberOfPages}
                        onChange={this.setCurrentPage}
                        currentPage={this.state.currentPage}
                    />
                </div>
            </div>
        );
    }
}

export default TechnicianAssignmentTrailData;