import React from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Link from "@amzn/meridian/link";
import Button from "@amzn/meridian/button";
import FileInput, {FileDetails} from "@amzn/meridian/file-input";
import MiniText from "src/components/common/text/MiniText";
import SmallText from "src/components/common/text/SmallText";
import MediumText from "src/components/common/text/MediumText";
import SmallInput from "src/components/common/input/SmallInput";
import ErrorAlert from "src/components/common/alert/ErrorAlert";
import SuccessAlert from "src/components/common/alert/SuccessAlert";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import {COMMA_SEPARATOR, EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import StringUtils from "src/utils/StringUtils";
import {
    getNotificationInfo,
    sendInstantCustomizedNotification,
    sendInstantNotification,
} from "src/helpers/send-bulk-notifications/SendBulkNotificationsAPIs";
import MarketplaceValueDropdown from "src/components/common/dropdown/MarketplaceValueDropdown";
import {
    GetNotificationInfoAPIOutput,
    SendNotificationAPIOutput
} from "src/interfaces/models/SendBulkNotificationsModels";
import {
    buildGetNotificationInfoPayload,
    buildSendInstantCommonNotificationInput,
    buildSendInstantCustomizedNotificationInput
} from "src/helpers/send-bulk-notifications/SendBulkNotificationsAPIPayloadBuilders";
import {
    getRecipientsList,
    getS3ObjectKeyFromURL,
} from "src/helpers/send-bulk-notifications/SendBulkNotificationsCommonHelper";
import {BulkNotificationDetailsScreenProps} from "src/interfaces/props/SendBulkNotificationsProps";
import {BulkNotificationDetailsScreenState} from "src/interfaces/states/SendBulkNotificationsStates";
import {
    DeliveryChannel,
    DispatchStyle,
    NotificationType,
    RecipientLevel,
    SendBulkNotificationsPages
} from "src/interfaces/enums/SendBulkNotificationsEnums";
import {uploadFileToS3AndGetReferenceId} from "src/helpers/send-bulk-notifications/SendBulkNotificationsS3Uploader";
import Level4Heading from "src/components/common/heading/Level4Heading";
import RadioButtonGroup from "src/components/common/radiobutton/RadioButtonGroup";
import {
    DELIVERY_CHANNEL_LABELS,
    DELIVERY_CHANNEL_VALUES,
    DISPATCH_STYLE_LABELS,
    DISPATCH_STYLE_VALUES,
    NOTIFICATION_TYPE_LABELS,
    NOTIFICATION_TYPE_VALUES,
    RECIPIENT_LEVEL_LABELS,
    RECIPIENT_LEVEL_VALUES
} from "src/constants/SendBulkNotificationsConstants";
import MediumTextArea from "src/components/common/textarea/MediumTextArea";
import ObjectUtils from "src/utils/ObjectUtils";
import {AlertType} from "src/interfaces/CommonTypes";

export default class BulkNotificationDetailsScreen
    extends React.Component<BulkNotificationDetailsScreenProps, BulkNotificationDetailsScreenState> {

    constructor(props: BulkNotificationDetailsScreenProps) {
        super(props);
        this.state = getDefaultState(props);
        this.onChangeNotificationType = this.onChangeNotificationType.bind(this);
        this.onChangeNotificationTitle = this.onChangeNotificationTitle.bind(this);
        this.onChangeNotificationMessageValue = this.onChangeNotificationMessageValue.bind(this);
        this.onMarketplaceIdSelect = this.onMarketplaceIdSelect.bind(this);
        this.onChangeDispatchStyle = this.onChangeDispatchStyle.bind(this);
        this.onChangeDeliveryChannel = this.onChangeDeliveryChannel.bind(this);
        this.onChangeRecipientLevel = this.onChangeRecipientLevel.bind(this);
        this.onChangeRecipientsBlockValue = this.onChangeRecipientsBlockValue.bind(this);
        this.onChangeNotificationDetailsFile = this.onChangeNotificationDetailsFile.bind(this);
        this.onSendNotificationButtonClick = this.onSendNotificationButtonClick.bind(this);
    }

    async componentDidMount() {
        if (StringUtils.isNotEmpty(this.props.referenceId)) {
            try {
                this.setState({showFetchingNotificationInfoMessage: true});
                const getNotificationInfoInput =
                    buildGetNotificationInfoPayload(StringUtils.getValueOrDefault(this.props.referenceId, EMPTY_STRING));
                const getNotificationInfoOutput = await getNotificationInfo(getNotificationInfoInput);
                this.setState({showFetchingNotificationInfoMessage: false});
                this.handleGetNotificationInfoResponse(getNotificationInfoOutput);
            } catch (e) {
                this.showAlert(AlertType.ERROR, e);
            }
        }
    }

    handleGetNotificationInfoResponse(apiOutput: GetNotificationInfoAPIOutput) {
        this.setState({
            referenceId: apiOutput.notificationMetadata.referenceId,
            notificationTitle: apiOutput.notificationMetadata.title,
            notificationType: apiOutput.notificationMetadata.notificationType,
            creationTime: apiOutput.notificationMetadata.creationTime,
            dispatchStyle: apiOutput.notificationMetadata.dispatchStyle,
            encryptedMarketplaceId: apiOutput.notificationMetadata.encryptedMarketplaceId,
            notificationMessage: StringUtils.getValueOrDefault(apiOutput.notificationMessageDetails.message,
                this.state.notificationMessage),
            recipientLevel: ObjectUtils.getValueOrDefault(apiOutput.notificationMessageDetails.recipientLevel,
                this.state.recipientLevel),
            recipients: ObjectUtils.getValueOrDefault(apiOutput.notificationMessageDetails.recipientIds,
                this.state.recipients),
            recipientsBlock: StringUtils.getValueOrDefault(
                apiOutput.notificationMessageDetails.recipientIds?.join(COMMA_SEPARATOR), this.state.recipientsBlock),
            s3Url: StringUtils.getValueOrDefault(apiOutput.notificationMessageDetails.s3Url, this.state.s3Url),
        });
    }

    onChangeNotificationTitle(notificationTitle: string) {
        this.setState({notificationTitle: notificationTitle});
    }

    onChangeDispatchStyle(dispatchStyle: DispatchStyle) {
        this.setState({dispatchStyle: dispatchStyle});
    }

    onChangeDeliveryChannel(deliveryChannel: DeliveryChannel) {
        this.setState({deliveryChannel: deliveryChannel});
    }

    onChangeNotificationType(notificationType: NotificationType) {
        this.setState({notificationType: notificationType});
    }

    onChangeRecipientLevel(recipientLevel: RecipientLevel) {
        this.setState({recipientLevel: recipientLevel});
    }

    onChangeNotificationMessageValue(notificationMessage: string) {
        this.setState({notificationMessage: notificationMessage})
    }

    onChangeRecipientsBlockValue(recipientsBlock: string) {
        this.setState({recipientsBlock: recipientsBlock, recipients: getRecipientsList(recipientsBlock)});
    }

    onMarketplaceIdSelect(encryptedMarketplaceId: string) {
        this.setState({encryptedMarketplaceId: encryptedMarketplaceId});
    }

    onChangeNotificationDetailsFile(chosenFiles: File[]) {
        this.setState({notificationDetailsFile: chosenFiles[0]});
    }

    async onSendNotificationButtonClick() {
        this.setState({showSendingNotificationsMessage: true});
        try {
            if (this.state.notificationType == NotificationType.COMMON) {
                const sendInstantNotificationInput = buildSendInstantCommonNotificationInput(this.state);
                const output = await sendInstantNotification(sendInstantNotificationInput);
                this.handleSendNotificationResponse(output);
            } else {
                let referenceId: string;
                if (this.state.isResendPage) {
                    referenceId = getS3ObjectKeyFromURL(this.state.s3Url);
                } else {
                    referenceId = await uploadFileToS3AndGetReferenceId(this.state);
                }
                const sendInstantCustomizedNotificationInput = buildSendInstantCustomizedNotificationInput(this.state, referenceId);
                const output = await sendInstantCustomizedNotification(sendInstantCustomizedNotificationInput);
                this.handleSendNotificationResponse(output);
            }
        } catch (e) {
            this.showAlert(AlertType.ERROR, e);
        }
        this.setState({showSendingNotificationsMessage: false});
    }

    handleSendNotificationResponse(
        responsePayload: SendNotificationAPIOutput) {
        if (responsePayload.success) {
            this.showAlert(AlertType.SUCCESS, "Notification sent!");
            this.setState({notificationSent: true});
        } else {
            this.showAlert(AlertType.ERROR, "An error occurred while trying to send notification.");
        }
    }

    showAlert(type: AlertType, message: string) {
        this.setState({
            alertType: type,
            alertMessage: message,
            showAlert: true,
        });
    }

    getScreenName(): string {
        if (this.state.isResendPage) {
            return "Edit & Resend Notification";
        } else {
            return "Create New Notification";
        }
    }

    render() {
        const isReadOnlyPage = this.props.page == SendBulkNotificationsPages.VIEW_NOTIFICATION_PAGE;
        return (
            <div id={"CreateOrResendNotificationScreen"}>
                <div id={"titleDiv"} hidden={isReadOnlyPage}>
                    <Column alignmentHorizontal="center" spacingInset="large" spacing="large">
                        <MediumText text={this.getScreenName()}/>
                    </Column>
                </div>
                <div id={"alertsAndSpinnersDiv"}>
                    <Row alignmentHorizontal={"center"} spacingInset="large">
                        <div hidden={!this.state.showAlert}>
                            {this.state.alertType === "error"
                                ? <ErrorAlert text={this.state.alertMessage}/>
                                : <SuccessAlert text={this.state.alertMessage}/>
                            }
                        </div>
                        <FetchingDataMessage isHidden={!this.state.showFetchingNotificationInfoMessage}
                                             message={"Fetching notification details"}/>
                        <FetchingDataMessage isHidden={!this.state.showSendingNotificationsMessage}
                                             message={"Sending Notification"}/>
                    </Row>
                </div>
                <div id={"notificationDetailsDiv"} hidden={this.state.showFetchingNotificationInfoMessage ||
                this.state.notificationSent || this.state.showSendingNotificationsMessage}>
                    <Row alignmentHorizontal="center" spacingInset="large" spacing="small">
                        <Column>
                            <Level4Heading text={"Notification Title"}/>
                            <SmallInput id={"notificationTitle"} type={"text"} width={600}
                                        value={this.state.notificationTitle} isDisabled={isReadOnlyPage}
                                        onChange={this.onChangeNotificationTitle}/>

                            <Level4Heading text={"Dispatch Style"}/>
                            <RadioButtonGroup labels={DISPATCH_STYLE_LABELS}
                                              values={DISPATCH_STYLE_VALUES} selectedValue={this.state.dispatchStyle}
                                              onChange={this.onChangeDispatchStyle} disabled={isReadOnlyPage}/>

                            <Level4Heading text={"Delivery Channel"}/>
                            <RadioButtonGroup labels={DELIVERY_CHANNEL_LABELS}
                                              values={DELIVERY_CHANNEL_VALUES} selectedValue={this.state.deliveryChannel}
                                              onChange={this.onChangeDeliveryChannel} disabled={isReadOnlyPage}/>

                            <Level4Heading text={"Marketplace"}/>
                            <MarketplaceValueDropdown realmValue={this.props.screenInput.realmValue}
                                                      defaultValue={this.state.encryptedMarketplaceId}
                                                      stage={this.props.screenInput.stage} disabled={isReadOnlyPage}
                                                      onSelect={this.onMarketplaceIdSelect}/>

                            <Level4Heading text={"Notification Type"}/>
                            <RadioButtonGroup labels={NOTIFICATION_TYPE_LABELS}
                                              values={NOTIFICATION_TYPE_VALUES}
                                              selectedValue={this.state.notificationType}
                                              onChange={this.onChangeNotificationType} disabled={isReadOnlyPage}/>

                            <div id={"commonNotificationMessageDetailsDiv"}
                                 hidden={this.state.notificationType != NotificationType.COMMON}>
                                <Row alignmentHorizontal="left" spacingInset="none" spacing="small">
                                    <Column>
                                        <Level4Heading text={"Notification Message"}/>
                                        <MediumTextArea value={this.state.notificationMessage} rows={3} resize={"auto"}
                                                        onChange={this.onChangeNotificationMessageValue}
                                                        isDisabled={isReadOnlyPage}/>

                                        <Level4Heading text={"Recipient Level"}/>
                                        <RadioButtonGroup labels={RECIPIENT_LEVEL_LABELS}
                                                          values={RECIPIENT_LEVEL_VALUES}
                                                          selectedValue={this.state.recipientLevel}
                                                          onChange={this.onChangeRecipientLevel}
                                                          disabled={isReadOnlyPage}/>

                                        <Level4Heading text={`${this.state.recipientLevel} IDs (separated by commas)`}/>
                                        <MediumTextArea value={this.state.recipientsBlock} rows={8} resize={"none"}
                                                        onChange={this.onChangeRecipientsBlockValue}
                                                        isDisabled={isReadOnlyPage}/>
                                        <MiniText
                                            text={`${this.state.recipients.length} ${this.state.recipientLevel} IDs`}/>
                                    </Column>
                                </Row>
                            </div>

                            <div id={"customizedNotificationFileUploadDiv"}
                                 hidden={this.state.notificationType != NotificationType.CUSTOMIZED || isReadOnlyPage}>
                                <Row alignmentHorizontal="left" spacingInset="none" spacing="small">
                                    <Column>
                                        <Level4Heading text={"File containing notification details"}/>
                                        <div hidden={!this.state.isResendPage}>
                                            <SmallText text={"The notification will be sent using the file" +
                                            " uploaded for the old notification."}/>
                                            <Link href={this.state.s3Url} target="_blank">
                                                Click here to download the file uploaded for the old notification.
                                            </Link>
                                            <SmallText text={"If you wish to change the file, use the upload button" +
                                            " below."}/>
                                        </div>
                                        <FileInput onFileAttached={this.onChangeNotificationDetailsFile}/>
                                        {this.state.notificationDetailsFile &&
                                        // display only if `notificationDetailsFile` is not undefined
                                        <FileDetails
                                            file={this.state.notificationDetailsFile}
                                            key={this.state.notificationDetailsFile.name}
                                            uploadComplete={true} uploadCompleteLabel={""}/>
                                        }
                                    </Column>
                                </Row>
                            </div>

                            <div id={"customizedNotificationFileDownloadDiv"}
                                 hidden={this.state.notificationType != NotificationType.CUSTOMIZED || !isReadOnlyPage}>
                                <Row alignmentHorizontal="left" spacingInset="none" spacing="small">
                                    <Column>
                                        <Level4Heading text={"File containing notification details"}/>
                                        <Link href={this.state.s3Url} target="_blank">
                                            Download
                                        </Link>
                                    </Column>
                                </Row>
                            </div>
                        </Column>
                    </Row>
                    <div id={"sendNotificationButtonDiv"} hidden={isReadOnlyPage}>
                        <Row alignmentHorizontal="center" spacingInset="large" alignmentVertical="bottom"
                             spacing="small">
                            <Button onClick={this.onSendNotificationButtonClick}>
                                Send Notification
                            </Button>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

function getDefaultState(props: BulkNotificationDetailsScreenProps): BulkNotificationDetailsScreenState {
    return {
        referenceId: EMPTY_STRING,
        dispatchStyle: DispatchStyle.INSTANT,
        notificationType: NotificationType.COMMON,
        notificationTitle: EMPTY_STRING,
        notificationMessage: EMPTY_STRING,
        recipients: [],
        recipientsBlock: EMPTY_STRING,
        recipientLevel: RecipientLevel.TECHNICIAN,
        deliveryChannel: DeliveryChannel.MOBILE_PUSH,
        notificationDetailsFile: null,
        alertMessage: EMPTY_STRING,
        showAlert: false,
        alertType: AlertType.ERROR,
        isResendPage: StringUtils.isNotEmpty(props.referenceId),
        creationTime: 0,
        s3Url: EMPTY_STRING,
        encryptedMarketplaceId: EMPTY_STRING,
        showSendingNotificationsMessage: false,
        showFetchingNotificationInfoMessage: false,
        notificationSent: false,
    };
}
