import React from "react";
import Column from "@amzn/meridian/column";
import LargeText from "src/components/common/text/LargeText";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import Divider from "@amzn/meridian/divider";
import {
    EMPTY_STRING,
    EU_AMAZON
} from "src/constants/ApplicationCommonConstants";
import {DataFetchState} from "src/interfaces/CommonTypes";
import {TechnicianProfileEditScreenProps} from "src/interfaces/props/TechnicianProfileEditProps"
import {TechnicianProfileEditInput} from "src/components/screens/technician-profile-edit/TechnicianProfileEditInput";
import TechnicianProfileEditResponse
    from "src/components/screens/technician-profile-edit/TechnicianProfileEditResponse";
import {TechnicianProfileEditScreenStates} from "src/interfaces/states/TechnicianProfileEditStates";
import SmallText from "src/components/common/text/SmallText";
import StringUtils from "src/utils/StringUtils";
import {apiCallParameters, MARKETPLACE_US_LIST, OFFSET_ZERO} from "src/constants/TechnicianProfileEditApiConstants";
import {deserializeSearchData} from "src/helpers/technician-profile-edit/TechnicianProfileEditHelper";
import {technicianProfileEditApiCall} from "src/helpers/technician-profile-edit/TechnicianProfileEditAPIs";
import ErrorAlert from "src/components/common/alert/ErrorAlert";

function createPayloadObject(technicianId: string, marketplace: string, merchantId: string) {
    let payloadObject = {
        technicianId: technicianId,
        marketPlaceId: marketplace,
        merchantId: merchantId
    }
    return payloadObject;
}

export class TechnicianProfileEditScreen extends React.Component<TechnicianProfileEditScreenProps, TechnicianProfileEditScreenStates> {

    constructor(props: TechnicianProfileEditScreenProps) {
        super(props);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.loadTechnicianData = this.loadTechnicianData.bind(this);
        this.onNextButtonClick = this.onNextButtonClick.bind(this);
        this.isLastPage = this.isLastPage.bind(this);
        this.state = {
            dataFetchState: DataFetchState.RequestNotMade,
            responsePayload: [],
            inputValidationStatus: false,
            marketplaceId: EMPTY_STRING,
            merchantId: EMPTY_STRING,
            nextPageOffset: this.props.nextPageOffset,
        }
    }

    validateInputs(searchType: string, technicianId: string, marketplace: string, merchantId: string) {
        if (StringUtils.isEmpty(marketplace) || StringUtils.isEmpty(merchantId)
            || (searchType == "By Technician" && StringUtils.isEmpty(technicianId))) {
            this.setState({inputValidationStatus: false});
            return false;
        } else {
            this.setState({inputValidationStatus: true});
            return true;
        }
    }

    isValidMarketplaceValue(marketplace: string) {
        if (this.props.screenInput.realmValue == EU_AMAZON) {
            return false;
        } else {
            return MARKETPLACE_US_LIST.includes(marketplace);
        }
    }

    async loadTechnicianData(userName: string, payload: string, nextPageOffset: string) {
        this.setState({dataFetchState: DataFetchState.FetchingData});
        let response;
        response = await technicianProfileEditApiCall(apiCallParameters.SEARCH_TECHNICIANS, userName, payload, nextPageOffset);
        if (response.ResponsePayload == null && response.ValidUser == true) {
            this.setState({dataFetchState: DataFetchState.NoDataFound});
        } else if (response.ResponsePayload == null && response.ValidUser == false) {
            this.setState({dataFetchState: DataFetchState.UnauthorizedUser});
        }
        else {
            this.setState({nextPageOffset: response.Offset});
            this.setState({responsePayload: deserializeSearchData(response.ResponsePayload)});
            this.setState({dataFetchState: DataFetchState.DataFetchedSuccessfully});
        }
    }

    onNextButtonClick() {
        this.loadTechnicianData(this.props.screenInput.username,
            JSON.stringify(createPayloadObject(EMPTY_STRING, this.state.marketplaceId, this.state.merchantId)), this.state.nextPageOffset);
    }

    isLastPage() {
        return StringUtils.isEmpty(this.state.nextPageOffset);
    }

    onSubmitButtonClick(searchType: string, technicianId: string, marketplace: string, merchantId: string) {
        if (this.isValidMarketplaceValue(marketplace)) {
            if (this.validateInputs(searchType, technicianId, marketplace, merchantId)) {
                this.loadTechnicianData(this.props.screenInput.username,
                    JSON.stringify(createPayloadObject(technicianId, marketplace, merchantId)), OFFSET_ZERO);
                this.setState({marketplaceId: marketplace});
                this.setState({merchantId: merchantId});
            } else {
                this.setState({dataFetchState: DataFetchState.InvalidRequestMade});
            }
        } else {
            this.setState({dataFetchState: DataFetchState.InvalidMarketplace});
        }
    }

    render() {
        return (
            <div>
                <div id="ParameterSelectionSection">
                    <Column alignmentHorizontal="left" spacingInset="xlarge" spacing="large">
                        <LargeText text={ApplicationScreenConstants.TECHNICIAN_PROFILE_EDIT.SCREEN_NAME}/>
                        <SmallText text={ApplicationScreenConstants.TECHNICIAN_PROFILE_EDIT.SCREEN_INTRO}/>
                        <TechnicianProfileEditInput
                            onButtonClick={this.onSubmitButtonClick}
                            realmValue={this.props.screenInput.realmValue}
                            stage={this.props.screenInput.stage}/>
                        <Divider size="small"/>
                    </Column>
                </div>
                <div id="ResponseSection">
                    <TechnicianProfileEditResponse dataFetchState={this.state.dataFetchState}
                                                   resultData={this.state.responsePayload}
                                                   userLogin={this.props.screenInput.username}
                                                   marketplaceId={this.state.marketplaceId}
                                                   merchantId={this.state.merchantId}
                                                   disableNextButton={this.isLastPage()}
                                                   nextButtonClick={this.onNextButtonClick}/>
                </div>
            </div>
        );
    }
}

export default TechnicianProfileEditScreen;