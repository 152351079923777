import {Cache} from 'aws-amplify';
import {getTokenOrRedirect} from './TokenRetriever';
import {FEDERATED_INFO, ID_TOKEN, STATE} from "src/constants/MidwayAuthNConstants";
import {UserDetails} from "src/interfaces/CommonTypes";

/*
 * Copy of a package we don't want to depend on:
 * https://code/packages/Westgabr-talent-appsync/blobs/49a68b75da0f752d0a24268e732ea9281008e702/--/src/index.js#L22
 * this removes query parameters which is used to removed the parameters that midway adds after a redirection
 */

const deleteQueryParameter = function (key: string) {
    const queryParams = new URLSearchParams(window.location.search);

    if (!queryParams.get(key)) {
        return;
    }
    queryParams.delete(key);
    const newUrl = new URL(window.location.href);

    newUrl.search = queryParams.toString();
    window.history.replaceState({}, '', newUrl.toString());
};

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (err) {
        return null;
    }
};

const parseUser = function (token: string): UserDetails {
    const parsedToken = parseJwt(token);

    return {
        username: parsedToken.sub,
        name: parsedToken.sub,
    };
};

const getMidwayToken = async function () {
    const cachedFederatedInfo = Cache.getItem(FEDERATED_INFO);
    const currentTimestamp = Date.now();

    let midwayToken;
    const oneSecond = 1000;

    if (cachedFederatedInfo && currentTimestamp < cachedFederatedInfo.expires_at * oneSecond) {
        midwayToken = cachedFederatedInfo.token;
    } else {
        midwayToken = await getTokenOrRedirect();
    }
    deleteQueryParameter(ID_TOKEN);
    deleteQueryParameter(STATE);
    return midwayToken;
};

export const refreshMidway = async function () {
    const token = await getMidwayToken();

    const parsedToken = parseJwt(token);

    return {
        token,
        expires_at: parsedToken.exp,
    };
};


export const getUsername = async function getUsername(data: any) {
    const token = (data?.token) || Cache.getItem(FEDERATED_INFO).token;

    return parseUser(token);
};