import {API} from "aws-amplify";
import {apiCallParameters} from "src/constants/ExecutionArtifactsApiConstants";
import {commonApiCallParameters, EMPTY_STRING, FALSE} from "src/constants/ApplicationCommonConstants";
import {refreshMidway} from "src/components/authentication/MidwayTokenRetriever";

export const fetchExecutionArtifactsAuditData = async (userLogin: string, payload: string, offset: string) => {
    let response;
    const credentials = await refreshMidway();
    try {
        response = await API.post(apiCallParameters.EXECUTION_ARTIFACTS_AUDIT_DATA, commonApiCallParameters.EXECUTE_REQUEST, {
            body: {
                'RequestType': apiCallParameters.GET_AUDIT_DATA,
                'EncryptedPayloadRequired': FALSE,
                'Tool': apiCallParameters.EXECUTION_ARTIFACTS_AUDIT_LAMBDA,
                'UserLogin': userLogin,
                'Payload': payload,
                'Offset': offset,
                'PageSize': apiCallParameters.PAGE_SIZE,
                'Token': credentials.token,
                'ClientId': location.host
            },
            headers: {'Content-Type': commonApiCallParameters.CONTENT_TYPE}
        });
        return response;
    }
    catch (error) {
        throw error;
    }
}

export const updateExecutionArtifactsAuditData = async (userLogin: string, payload: string) => {
    let response;
    const credentials = await refreshMidway();
    try {
        response = await API.post(apiCallParameters.EXECUTION_ARTIFACTS_AUDIT_DATA, commonApiCallParameters.EXECUTE_REQUEST, {
            body: {
                'RequestType': apiCallParameters.UPDATE_AUDIT_DATA,
                'EncryptedPayloadRequired': FALSE,
                'Tool': apiCallParameters.EXECUTION_ARTIFACTS_AUDIT_LAMBDA,
                'UserLogin': userLogin,
                'Payload': payload,
                'Token': credentials.token,
                'ClientId': location.host
            },
            headers: {
                'Content-Type': commonApiCallParameters.CONTENT_TYPE,
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
}