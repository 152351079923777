import React from "react"
import Text from "@amzn/meridian/text";
import {TextProps} from "src/interfaces/props/CommonProps";

export class LogoText extends React.Component<TextProps> {

    constructor(props: TextProps) {
        super(props);
    }

    render() {
        return(
            <Text fontFamily="bookerly" type="b500" breakWord={this.props.breakWord}> {this.props.text} </Text>
        );
    }
}

export default LogoText;