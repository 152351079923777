import React, {useCallback, useState} from "react"
import Modal from "@amzn/meridian/modal"
import Row from "@amzn/meridian/row"
import {ExecutionArtifactsSelfieImageProps} from "src/interfaces/props/ExecutionArtifactsProps";
import RotateImage from "src/components/common/image-rotation/RotateImage";

function ExecutionArtifactsSelfieImage(props: ExecutionArtifactsSelfieImageProps) {

    const [open, setOpen] = useState(false)
    const onClickButton = useCallback(() => setOpen(true), [])
    const onClose = useCallback(() => setOpen(false), [])

    return (
        <React.Fragment>
            <img id="ImageButton" onClick={onClickButton} src={props.image} alt={props.text} width="80" height="80"/>
            <Modal title={props.text} open={open} onClose={onClose}>
                <Row alignmentHorizontal="center" widths={["grid-4", "grid-8"]}>
                    <img id="RotatingImage" src={props.profileImage} alt="Profile Picture" width="300" height="300"/>
                    <RotateImage src={props.image} width="300" height="300"/>
                </Row>
            </Modal>
        </React.Fragment>
    );
}

export default ExecutionArtifactsSelfieImage;