import React from 'react';
import Amplify from 'aws-amplify';
import cognitoConfig from 'src/constants/ApplicationAmplifyConstants';
import {AppProps} from "src/interfaces/props/CommonProps";
import {AppStates} from "src/interfaces/states/CommonStates";
import {authenticateAmplify} from 'src/components/authentication/MidwayAuthN';
import {refreshMidway} from 'src/components/authentication/MidwayTokenRetriever';
import Theme from "@amzn/meridian/theme"
import tealDarkTokens from "@amzn/meridian-tokens/theme/teal-dark"
import tealLightTokens from "@amzn/meridian-tokens/theme/teal-light"
import {
    AuthZ_API_NAME,
    DEV,
    EMPTY_STRING,
    EU_AMAZON,
    EXECUTION_ARTIFACTS_AUDIT_API,
    SERVICE_PROVIDERS_AUDIT_API, STAFFS_RATING_PROCESSOR_API_NAME,
    TECHNICIAN_ASSIGNMENT_TRAIL_API, TECHNICIAN_PROFILE_EDIT_API_NAME,
    VAS_CONTACT_SUPPORT_CASE_API, VAS_NOTIFICATION_SERVICE_API
} from "src/constants/ApplicationCommonConstants";
import { apiCallParameters as jobExecutionImagesApiCallParams } from 'src/constants/JobExecutionImagesConstants';
import AuthenticatedLayout from "src/components/layout/AuthenticatedLayout";
import UnauthenticatedLayout from "src/components/layout/UnauthenticatedLayout";
import {RealmValues, UserDetails} from "src/interfaces/CommonTypes";


function getUsernameFromUserDetails(userDetails: UserDetails | undefined) {
    if (userDetails?.username === undefined) {
        throw TypeError("Username is undefined")
    }
    return userDetails.username;
}

function loadAmplifyConfig(realm: RealmValues) {
    if (location.origin in cognitoConfig) {
        return cognitoConfig[location.origin][realm];
    } else {
        const ALERT_MESSAGE = "Invalid origin, configuration not available for requested URL: " + location.origin
        alert(ALERT_MESSAGE);
        console.log(ALERT_MESSAGE)
        return null;
    }
}

async function auth(realm: RealmValues) {
    const amplifyConfig = loadAmplifyConfig(realm);
    Amplify.configure({
        Auth: {
            identityPoolId: amplifyConfig?.IDENTITY_POOL_ID,
            mandatorySignIn: true,
            region: amplifyConfig?.IDENTITY_POOL_REGION,
            refreshHandlers: {'midway-auth.amazon.com': refreshMidway}
        },

        API: {
            endpoints: [
                {
                    name: EXECUTION_ARTIFACTS_AUDIT_API,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                },
                {
                    name: SERVICE_PROVIDERS_AUDIT_API,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                },
                {
                    name: TECHNICIAN_ASSIGNMENT_TRAIL_API,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                },
                {
                    name: AuthZ_API_NAME,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                },
                {
                    name: VAS_CONTACT_SUPPORT_CASE_API,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                },
                {
                    name: TECHNICIAN_PROFILE_EDIT_API_NAME,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                },
                {
                    name: VAS_NOTIFICATION_SERVICE_API,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                },
                {
                    name: STAFFS_RATING_PROCESSOR_API_NAME,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                },
                {
                    name: jobExecutionImagesApiCallParams.FEU_JOB_EXECUTION_IMAGES_API_NAME,
                    endpoint: amplifyConfig?.API_ENDPOINT,
                    region: amplifyConfig?.ENDPOINTS_REGION
                }
            ]
        }
    });
    return amplifyConfig!.ENV;
}

export class App extends React.Component<AppProps, AppStates> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            realmValue: EU_AMAZON,
            userAuthenticated: false,
            stage: DEV,
            userDetails: {username: EMPTY_STRING, name: EMPTY_STRING},
            nextPageOffset: EMPTY_STRING,
            darkTheme: false
        }
        this.changeRealmValue = this.changeRealmValue.bind(this);
        this.switchApplicationTheme = this.switchApplicationTheme.bind(this);
        this.getApplicationTheme = this.getApplicationTheme.bind(this);
    }

    switchApplicationTheme() {
        this.setState({darkTheme: !this.state.darkTheme})
    }

    getApplicationTheme() {
        if(this.state.darkTheme) {
            return tealDarkTokens;
        }
        return tealLightTokens;
    }

    async changeRealmValue(event: RealmValues) {
        auth(event).then(Response => {
            this.setState({stage: Response})
        })
        this.setState({realmValue: event});
    }

    async componentDidMount() {
        auth(this.state.realmValue).then(Response => {
            this.setState({stage: Response})
        });
        const loggedInUser = await authenticateAmplify();
        this.setState({userDetails: loggedInUser});
        this.setState({userAuthenticated: true});
    }

    render() {
        const username: string = getUsernameFromUserDetails(this.state.userDetails)
        return (
            <div>
                {(() => {
                    if (this.state.userAuthenticated) {
                        return (
                            <Theme tokens={this.getApplicationTheme()}>
                                <AuthenticatedLayout
                                    username={username}
                                    changeRealm={this.changeRealmValue}
                                    stage={this.state.stage}
                                    realmValue={this.state.realmValue}
                                    nextPageOffset={this.state.nextPageOffset}
                                    switchApplicationTheme={this.switchApplicationTheme}
                                    darkTheme={this.state.darkTheme}
                                />
                            </Theme>
                        );
                    } else {
                        return (
                            <UnauthenticatedLayout/>
                        );
                    }
                })()
                }
            </div>
        );
    }
}

export default App;
