import React from "react"
import {Route, Switch} from 'react-router-dom';
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import HomeScreen from "src/components/screens/home/HomeScreen";
import {setScreenProps} from "src/helpers/common/ApplicationPropSetters";
import {AppContentProps} from "src/interfaces/props/CommonProps";
import ToolAuthorization from "src/components/authorization/ToolAuthorization";
import {SERVICE_PROVIDERS_TOOL} from "src/constants/ServiceProvidersApiConstants";
import {EXECUTION_ARTIFACTS_TOOL} from "src/constants/ExecutionArtifactsApiConstants";
import {
    CONTACT_SUPPORT_CASES_AUDIT_TOOL,
    CREATE_OR_UPDATE_CONTACT_SUPPORT_CASE_PAGE
} from "src/constants/ContactSupportCaseToolConstants";
import {TECHNICIAN_ASSIGNMENT_TRAIL_TOOL} from "src/constants/TechnicianAssignmentTrailConstants";
import {TECHNICIAN_PROFILE_EDIT_TOOL} from "src/constants/TechnicianProfileEditApiConstants";
import {SEND_BULK_NOTIFICATIONS_TOOL} from "src/constants/SendBulkNotificationsConstants";
import {SendBulkNotificationsPages} from "src/interfaces/enums/SendBulkNotificationsEnums";
import {STAFFS_RATING_PROCESSOR_TOOL} from "src/constants/StaffsRatingProcessorApiConstants";
import {FEU_JOB_EXECUTION_IMAGES_AUTH_TOOL} from "src/constants/JobExecutionImagesConstants";

export class ApplicationContent extends React.Component<AppContentProps> {

    constructor(props: AppContentProps) {
        super(props);
    }

    render() {
        const screenInput = setScreenProps(this.props.screenInput.username,
            this.props.screenInput.stage, this.props.screenInput.realmValue)
        return (
            <Switch>
                <Route exact path={ApplicationScreenConstants.HOME.URL}>
                    <HomeScreen
                        username={this.props.screenInput.username}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.AUDIT_EXECUTION_ARTIFACTS.URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        nextPageOffset={this.props.nextPageOffset}
                        tool={EXECUTION_ARTIFACTS_TOOL}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.SERVICE_PROVIDERS_AUDIT.URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={SERVICE_PROVIDERS_TOOL}
                        nextPageOffset={this.props.nextPageOffset}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.CONTACT_SUPPORT_CASES.URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={CONTACT_SUPPORT_CASES_AUDIT_TOOL}
                        nextPageOffset={this.props.nextPageOffset}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.CONTACT_SUPPORT_CASES.CREATE_OR_UPDATE_CASE_URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={CONTACT_SUPPORT_CASES_AUDIT_TOOL}
                        nextPageOffset={this.props.nextPageOffset}
                        toolPage={CREATE_OR_UPDATE_CONTACT_SUPPORT_CASE_PAGE}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.TECHNICIAN_ASSIGNMENT_TRAIL.URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={TECHNICIAN_ASSIGNMENT_TRAIL_TOOL}
                        nextPageOffset={this.props.nextPageOffset}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.TECHNICIAN_PROFILE_EDIT.URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={TECHNICIAN_PROFILE_EDIT_TOOL}
                        nextPageOffset={this.props.nextPageOffset}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.SEND_BULK_NOTIFICATIONS.URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={SEND_BULK_NOTIFICATIONS_TOOL}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.SEND_BULK_NOTIFICATIONS.NOTIFICATION_DETAILS}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={SEND_BULK_NOTIFICATIONS_TOOL}
                        toolPage={SendBulkNotificationsPages.NOTIFICATION_DETAILS_PAGE}
                    />
                </Route>
                <Route exact path={ApplicationScreenConstants.STAFF_RATING_FILE_UPLOAD.URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={STAFFS_RATING_PROCESSOR_TOOL}
                        nextPageOffset={this.props.nextPageOffset}
                    />
                </Route>

                <Route exact path={ApplicationScreenConstants.JOB_EXECUTION_IMAGES.URL}>
                    <ToolAuthorization
                        screenInput={screenInput}
                        tool={FEU_JOB_EXECUTION_IMAGES_AUTH_TOOL}
                        nextPageOffset={this.props.nextPageOffset}
                    />
                </Route>

            </Switch>
        );
    }
}

export default ApplicationContent;
