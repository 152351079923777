import React from "react"
import AppLayout from "@amzn/meridian/app-layout"
import ApplicationFooter from "src/components/layout/ApplicationFooter"
import {HashRouter as Router} from 'react-router-dom';
import {LayoutStates} from "src/interfaces/states/CommonStates";
import {LayoutProps} from "src/interfaces/props/CommonProps";
import ApplicationContent from "src/components/layout/ApplicationContent";
import ApplicationMenu from "src/components/layout/ApplicationMenu";
import ApplicationHeader from "src/components/layout/ApplicationHeader";
import {setScreenProps} from "src/helpers/common/ApplicationPropSetters";

export class ApplicationLayout extends React.Component<LayoutProps, LayoutStates> {

    constructor(props: LayoutProps) {
        super(props);
        this.menuButtonClick = this.menuButtonClick.bind(this);
        this.state = {
            sideMenuOpen: true,
        };
    }

    menuButtonClick() {
        this.setState({sideMenuOpen: !(this.state.sideMenuOpen)});
    }

    render() {
        const screenInput = setScreenProps(this.props.username, this.props.stage, this.props.realmValue)
        return (
            <div id="AppLayout">
                <Router>
                    <AppLayout
                        headerComponent={ApplicationHeader}
                        sidebarComponent={ApplicationMenu}
                        footerComponent={ApplicationFooter}
                        alwaysShowScrollbar={true}
                        backgroundColor="alternateSecondary"
                    >
                        <ApplicationHeader
                            username={this.props.username}
                            changeRealm={this.props.changeRealm}
                            menuButton={this.menuButtonClick}
                            realmValue={this.props.realmValue}
                            switchApplicationTheme={this.props.switchApplicationTheme}
                            darkTheme={this.props.darkTheme}
                        />
                        <ApplicationMenu input={this.state.sideMenuOpen}/>
                        <ApplicationFooter/>
                        <ApplicationContent
                            screenInput={screenInput}
                            nextPageOffset={this.props.nextPageOffset}
                        />
                    </AppLayout>
                </Router>
            </div>
        );
    }
}

export default ApplicationLayout;