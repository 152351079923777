import React, { SyntheticEvent } from "react";
import { DataFetchState } from "src/interfaces/CommonTypes";
import DefaultStartScreenMessage from "src/components/common/message/DefaultStartScreenMessage";
import InvalidRequestMessage from "src/components/common/message/InvalidRequestMessage";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import NoDataFoundMessage from "src/components/common/message/NoDataFoundMessage";
import UnauthorizedUserMessage from "src/components/common/message/UnauthorizedUserMessage";
import JobExecutionImagesMessage from "./JobExecutionImagesMessage";
import { JobExecutionImagesResponseProps } from "src/interfaces/props/JobExecutionImagesProps";
import { GetJobExecutionImagesOutput, StatusResponse } from "src/interfaces/models/JobExecutionImagesModels";
import { STATUS_CODE_TO_MESSAGE_MAP } from "src/constants/JobExecutionImagesConstants";
import Alert from "@amzn/meridian/alert";
import { AlertType } from "@amzn/meridian/alert/alert";
import RequestFailedMessage from "src/components/common/message/RequestFailedMessage";
import { JobExecutionImagesResponseState } from "src/interfaces/states/JobExecutionImagesStates";
import Modal from "@amzn/meridian/modal";

import JobExecutionImageGrid from "./JobExecutionImagesGrid";

export class JobExecutionImagesResponse extends React.Component<
	JobExecutionImagesResponseProps,
	JobExecutionImagesResponseState
> {
	constructor(props: JobExecutionImagesResponseProps) {
		super(props);

		this.state = {
			openModal: false,
			modalImageURL: ""
		};
	}

	onClickImage = (event: SyntheticEvent) => {
		if (!(event.target instanceof HTMLImageElement)) {
			return;
		}
		this.setState({
			openModal: true,
			modalImageURL: event.target.getAttribute("src") || ""
		});
	};

	onClose = () => this.setState({ openModal: false, modalImageURL: "" });

	displayMessage = (responseCode: StatusResponse) => {
		let responseCodeKey = responseCode as keyof typeof STATUS_CODE_TO_MESSAGE_MAP;
		let msg: string = STATUS_CODE_TO_MESSAGE_MAP[responseCodeKey]["text"];
		let msgType: AlertType = STATUS_CODE_TO_MESSAGE_MAP[responseCodeKey]["type"] as AlertType;
		return (
			<Alert type={msgType} size="large" autoFocus={true}>
				{msg}
			</Alert>
		);
	};

	displayImagesGrid = (output: GetJobExecutionImagesOutput) => {
		return (
			<React.Fragment>
				<div
					id="JobExecutionImagesGrid"
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
						gap: "24px"
					}}
					onClick={(event) => this.onClickImage(event)}>
					{this.props.getJobExecutionImagesOutput.map((item, index) => (
						<img
							src={item.imageURL}
							key={index}
							alt="Job Execution Image"
							style={{
								height: "200px",
								width: "100%",
								objectFit: "cover",
								border: "1px solid #BBC0C1",
								background:
									"transparent url(https://m.media-amazon.com/images/G/01/vas/techapp/spinner._CB289032145_._SY200_.gif) center center no-repeat"
							}}
						/>
					))}
				</div>
				<Modal
					open={this.state.openModal}
					onClose={this.onClose}
					closeLabel="Close"
					aria-describedby="job execution image pop up">
					<div style={{ width: "75vw", height: "75vh", margin: "auto" }}>
						<img
							style={{
								objectFit: "contain",
								width: "inherit",
								height: "inherit"
							}}
							src={this.state.modalImageURL}
						/>
					</div>
				</Modal>
			</React.Fragment>
		);
	};

	displayJobExecutionImagesResponse = (output: GetJobExecutionImagesOutput) => {
		if (output.length == 1 && output[0].statusResponse != StatusResponse.FETCHED_PHOTOS) {
			return <JobExecutionImagesMessage statusCode={output[0].statusResponse} />;
		}
		return <JobExecutionImageGrid images={output} />;
	};

	render() {
		return (
			<div>
				{(() => {
					switch (this.props.dataFetchState) {
						case DataFetchState.RequestNotMade:
							return <DefaultStartScreenMessage />;
						case DataFetchState.InvalidRequestMade:
							return <InvalidRequestMessage />;
						case DataFetchState.FetchingData:
							return <FetchingDataMessage />;
						case DataFetchState.NoDataFound:
							return <NoDataFoundMessage />;
						case DataFetchState.DataFetchFailed:
							return <RequestFailedMessage />;
						case DataFetchState.UnauthorizedUser:
							return <UnauthorizedUserMessage />;
						case DataFetchState.DataFetchedSuccessfully:
							return this.displayJobExecutionImagesResponse(this.props.getJobExecutionImagesOutput);
					}
				})()}
			</div>
		);
	}
}

export default JobExecutionImagesResponse;
