import React from "react";
import {authorizeTool} from "src/helpers/common/ApplicationAPIs";
import ServiceProvidersAuditPage from "src/components/screens/service-providers-audit/ServiceProvidersAuditPage";
import TechnicianProfileEditScreen from "src/components/screens/technician-profile-edit/TechnicianProfileEditScreen";
import UnauthorizedUserMessage from "src/components/common/message/UnauthorizedUserMessage";
import AuthorizingUserAccessMessage from "src/components/common/message/AuthorizingUserAccessMessage";
import {ToolAuthorizationProps} from "src/interfaces/props/CommonProps";
import {ToolAuthorizationStates} from "src/interfaces/states/CommonStates";
import {SERVICE_PROVIDERS_TOOL} from "src/constants/ServiceProvidersApiConstants";
import {EXECUTION_ARTIFACTS_TOOL} from "src/constants/ExecutionArtifactsApiConstants";
import {TECHNICIAN_ASSIGNMENT_TRAIL_TOOL} from "src/constants/TechnicianAssignmentTrailConstants";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import ExecutionArtifactsScreen from "src/components/screens/audit-execution-artifacts/ExecutionArtifactsScreen";
import SearchContactSupportCasesScreen
    from "src/components/screens/contact-support-case/SearchContactSupportCasesScreen";
import TechnicianAssignmentTrailScreen from "src/components/screens/technician-assignment-trail/TechnicianAssignmentTrailScreen";
import {
    CONTACT_SUPPORT_CASES_AUDIT_TOOL,
    CREATE_OR_UPDATE_CONTACT_SUPPORT_CASE_PAGE
} from "src/constants/ContactSupportCaseToolConstants";
import CreateOrUpdateContactSupportCaseScreen
    from "src/components/screens/contact-support-case/CreateOrUpdateContactSupportCaseScreen";
import {getCaseIdFromURL} from "src/helpers/contact-support-case/ContactSupportCaseCommonHelper";
import ErrorAlert from "src/components/common/alert/ErrorAlert";
import {TECHNICIAN_PROFILE_EDIT_TOOL} from "src/constants/TechnicianProfileEditApiConstants";
import {SEND_BULK_NOTIFICATIONS_TOOL} from "src/constants/SendBulkNotificationsConstants";
import SendBulkNotificationsDashboardScreen
    from "src/components/screens/send-bulk-notifications/SendBulkNotificationsDashboardScreen";
import BulkNotificationDetailsScreen
    from "src/components/screens/send-bulk-notifications/BulkNotificationDetailsScreen";
import JobExecutionImagesScreen from "src/components/screens/job-execution-images/JobExecutionImagesScreen"
import StringUtils from "src/utils/StringUtils";
import {getNotificationReferenceIdFromURL} from "src/helpers/send-bulk-notifications/SendBulkNotificationsCommonHelper";
import {SendBulkNotificationsPages} from "src/interfaces/enums/SendBulkNotificationsEnums";
import {STAFFS_RATING_PROCESSOR_TOOL} from "src/constants/StaffsRatingProcessorApiConstants";
import {StaffsRatingProcessorScreen} from "src/components/screens/staffs-rating-processor/StaffsRatingProcessorScreen";
import { FEU_JOB_EXECUTION_IMAGES_AUTH_TOOL } from "src/constants/JobExecutionImagesConstants";

export class ToolAuthorization extends React.Component<ToolAuthorizationProps, ToolAuthorizationStates> {

    constructor(props: ToolAuthorizationProps) {
        super(props);
        this.authorizeUserAccess = this.authorizeUserAccess.bind(this);
        this.updateStateVariables = this.updateStateVariables.bind(this);
        this.state = {
            isValidUser: false,
            isAuthorizationCompleted: false,
            currentTool: EMPTY_STRING,
            showErrorMessage: false
        };
    }

    updateStateVariables(currentTool: string, isValidUser: boolean, isAuthorizationCompleted: boolean) {
        this.setState({currentTool: currentTool});
        this.setState({isValidUser: isValidUser});
        this.setState({isAuthorizationCompleted: isAuthorizationCompleted});
    }

    async authorizeUserAccess() {
        let authorizationResponse;
        try {
            this.updateStateVariables(this.props.tool, false, false);
            authorizationResponse = await authorizeTool(this.props.screenInput.username, this.props.tool);
            this.updateStateVariables(this.props.tool, authorizationResponse.ValidUser, true);
        } catch (error) {
            console.error("isAuthorized API call error", error);
            this.setState({
                showErrorMessage: true
            })
        }
    }

    render() {
        return (
            <div>
                <div hidden={!this.state.showErrorMessage}>
                    {
                        (() => {
                            return (
                                <ErrorAlert text={"Unable to authenticate the user. Try again"}
                                            onClose={() => window.location.reload()}/>
                            );
                        })()
                    }
                </div>
                {(() => {
                    if (this.state.currentTool == this.props.tool) {
                        if (this.state.isAuthorizationCompleted) {
                            if (this.state.isValidUser) {
                                switch (this.props.tool) {
                                    case SERVICE_PROVIDERS_TOOL:
                                        return (
                                            <ServiceProvidersAuditPage
                                                screenInput={this.props.screenInput}
                                            />
                                        );
                                    case TECHNICIAN_PROFILE_EDIT_TOOL:
                                        return (
                                            <TechnicianProfileEditScreen
                                                screenInput={this.props.screenInput}
                                                nextPageOffset={StringUtils.getValueOrDefault(this.props.nextPageOffset, EMPTY_STRING)}
                                            />
                                        );
                                    case EXECUTION_ARTIFACTS_TOOL:
                                        return (
                                            <ExecutionArtifactsScreen
                                                screenInput={this.props.screenInput}
                                                nextPageOffset={StringUtils.getValueOrDefault(this.props.nextPageOffset, EMPTY_STRING)}
                                            />
                                        );
                                    case CONTACT_SUPPORT_CASES_AUDIT_TOOL:
                                        if (this.props.toolPage == CREATE_OR_UPDATE_CONTACT_SUPPORT_CASE_PAGE) {
                                            return (
                                                <CreateOrUpdateContactSupportCaseScreen
                                                    screenInput={this.props.screenInput}
                                                    caseId={getCaseIdFromURL(window.location.href)}/>
                                            );
                                        } else {
                                            return (
                                                <SearchContactSupportCasesScreen
                                                    username={this.props.screenInput.username}
                                                    realmValue={this.props.screenInput.realmValue}
                                                    stage={this.props.screenInput.stage}/>
                                            );
                                        }
                                    case TECHNICIAN_ASSIGNMENT_TRAIL_TOOL:
                                        return (
                                            <TechnicianAssignmentTrailScreen
                                                screenInput={this.props.screenInput}
                                            />
                                        );
                                    case SEND_BULK_NOTIFICATIONS_TOOL:
                                        if (this.props.toolPage == SendBulkNotificationsPages.NOTIFICATION_DETAILS_PAGE) {
                                            return (
                                                <BulkNotificationDetailsScreen
                                                    screenInput={this.props.screenInput}
                                                    referenceId={getNotificationReferenceIdFromURL(window.location.href)}
                                                    page={this.props.toolPage}/>
                                            );
                                        } else {
                                            return (
                                                <SendBulkNotificationsDashboardScreen
                                                    screenInput={this.props.screenInput}
                                                />
                                            );
                                        }
                                    case STAFFS_RATING_PROCESSOR_TOOL:
                                        return (
                                          <StaffsRatingProcessorScreen
                                          screenInput={this.props.screenInput}/>
                                        );
                                    case FEU_JOB_EXECUTION_IMAGES_AUTH_TOOL:
                                        return (
                                            <JobExecutionImagesScreen 
                                                screenInput={this.props.screenInput}/>
                                        )
                                }
                            } else {
                                return (
                                    <UnauthorizedUserMessage/>
                                );
                            }
                        } else {
                            return (
                                <AuthorizingUserAccessMessage/>
                            );
                        }
                    } else {
                        this.authorizeUserAccess();
                    }
                })()
                }
            </div>
        );
    }
}

export default (ToolAuthorization);