import React from "react"
import {DataFetchState} from "src/interfaces/CommonTypes";
import DefaultStartScreenMessage from "src/components/common/message/DefaultStartScreenMessage";
import InvalidRequestMessage from "src/components/common/message/InvalidRequestMessage";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import NoDataFoundMessage from "src/components/common/message/NoDataFoundMessage";
import {TechnicianProfileEditResponseProps} from "src/interfaces/props/TechnicianProfileEditProps";
import TechnicianProfileEditTable from "src/components/screens/technician-profile-edit/TechnicianProfileEditTable";
import InvalidMarketplaceMessage from "src/components/common/message/InvaildMarketplaceMessage";
import UnauthorizedUserMessage from "src/components/common/message/UnauthorizedUserMessage";

export class TechnicianProfileEditResponse extends React.Component<TechnicianProfileEditResponseProps> {
    constructor(props: TechnicianProfileEditResponseProps) {
        super(props);
    }
    render() {
        return (
            <div>
                { (() => {
                    switch (this.props.dataFetchState) {
                        case DataFetchState.RequestNotMade:
                            return (
                                <DefaultStartScreenMessage/>
                            );
                        case DataFetchState.InvalidMarketplace:
                            return (
                                <InvalidMarketplaceMessage/>
                            );
                        case DataFetchState.InvalidRequestMade:
                            return (
                                <InvalidRequestMessage/>
                            );
                        case DataFetchState.FetchingData:
                            return (
                                <FetchingDataMessage/>
                            );
                        case DataFetchState.NoDataFound:
                            return (
                                <NoDataFoundMessage/>
                            );
                        case DataFetchState.UnauthorizedUser:
                            return (
                                <UnauthorizedUserMessage/>
                            )
                        case DataFetchState.DataFetchedSuccessfully :
                            return (
                                <TechnicianProfileEditTable resultData={this.props.resultData}
                                                            userLogin={this.props.userLogin}
                                                            marketplaceId={this.props.marketplaceId}
                                                            merchantId={this.props.merchantId}
                                                            nextButtonClick={this.props.nextButtonClick}
                                                            disableNextButton={this.props.disableNextButton}/>
                            );
                    }})()
                }
            </div>
        );
    }
}
export default TechnicianProfileEditResponse;