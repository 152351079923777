import React from "react";
import Select, {SelectOption} from "@amzn/meridian/select";
import {SmallDropdownWithPrefixProps} from "src/interfaces/props/CommonProps";
import {DropDown} from "src/components/common/dropdown/DropDown";
/**
 * This component is deprecated in favor of {@link DropDown} and it's other components
 */
export class SmallDropdownWithPrefix extends React.Component<SmallDropdownWithPrefixProps> {

    constructor(props: SmallDropdownWithPrefixProps) {
        super(props);
    }

    render() {
        return(
            <Select value={this.props.defaultValue} onChange={this.props.onSelect} prefix={this.props.prefix} width={200} size="small">
                {(() => {
                    const dropdownElements: JSX.Element[] = [];
                    for(let index = 0; index < this.props.valueList.length; index++) {
                        dropdownElements.push(<SelectOption value={this.props.valueList[index]} label={this.props.labelList[index]}/>)
                    }
                    return(dropdownElements);
                })()
                }
            </Select>
        );
    }
}
export default SmallDropdownWithPrefix;