import React from 'react';
import {useHistory} from "react-router-dom"
import Row from "@amzn/meridian/row";
import CardComponent from "src/components/common/card/CardComponent";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";

function OnboardedTools() {

    const history = useHistory()
    return (
        <div id="onboardedToolsMain">
            <Row width="100%" widths={["grid-6", "grid-6"]} spacing="large">
                <div id="AuditExecutionArtifacts">
                    <CardComponent
                        history={history.push}
                        URL={ApplicationScreenConstants.AUDIT_EXECUTION_ARTIFACTS.URL}
                        intro={ApplicationScreenConstants.AUDIT_EXECUTION_ARTIFACTS.CARD_INTRO}
                        screenName={ApplicationScreenConstants.AUDIT_EXECUTION_ARTIFACTS.SCREEN_NAME}
                    />
                </div>
                <div id="ServiceProvidersAudit">
                     <CardComponent
                         history={history.push}
                         URL={ApplicationScreenConstants.SERVICE_PROVIDERS_AUDIT.URL}
                         intro={ApplicationScreenConstants.SERVICE_PROVIDERS_AUDIT.CARD_INTRO}
                         screenName={ApplicationScreenConstants.SERVICE_PROVIDERS_AUDIT.SCREEN_NAME}
                     />
                </div>
            </Row>
            <br/>
            <Row width="100%" widths={["grid-6", "grid-6"]} spacing="large">
                <div id="EmergencyContactSupport">
                    <CardComponent
                        history={history.push}
                        URL={ApplicationScreenConstants.CONTACT_SUPPORT_CASES.URL}
                        intro={ApplicationScreenConstants.CONTACT_SUPPORT_CASES.CARD_INTRO}
                        screenName={ApplicationScreenConstants.CONTACT_SUPPORT_CASES.SCREEN_NAME}
                    />
                </div>

                <div id="TechnicianAssignmentTrail">
                    <CardComponent
                        history={history.push}
                        URL={ApplicationScreenConstants.TECHNICIAN_ASSIGNMENT_TRAIL.URL}
                        intro={ApplicationScreenConstants.TECHNICIAN_ASSIGNMENT_TRAIL.CARD_INTRO}
                        screenName={ApplicationScreenConstants.TECHNICIAN_ASSIGNMENT_TRAIL.SCREEN_NAME}
                    />
                </div>
            </Row>
            <br/>
            <Row width="100%" widths={["grid-6", "grid-6"]} spacing="large">
                <div id="TechnicianProfileEdit">
                    <CardComponent
                        history={history.push}
                        URL={ApplicationScreenConstants.TECHNICIAN_PROFILE_EDIT.URL}
                        intro={ApplicationScreenConstants.TECHNICIAN_PROFILE_EDIT.CARD_INTRO}
                        screenName={ApplicationScreenConstants.TECHNICIAN_PROFILE_EDIT.SCREEN_NAME}
                    />
                </div>

                <div id="SendBulkNotifications">
                    <CardComponent
                        history={history.push}
                        URL={ApplicationScreenConstants.SEND_BULK_NOTIFICATIONS.URL}
                        intro={ApplicationScreenConstants.SEND_BULK_NOTIFICATIONS.CARD_INTRO}
                        screenName={ApplicationScreenConstants.SEND_BULK_NOTIFICATIONS.SCREEN_NAME}
                    />
                </div>
            </Row>
            <br/>
            <Row width="100%" widths={["grid-6", "grid-6"]} spacing="large">
                <div id="UploadFiles">
                    <CardComponent
                        history={history.push}
                        URL={ApplicationScreenConstants.STAFF_RATING_FILE_UPLOAD.URL}
                        intro={ApplicationScreenConstants.STAFF_RATING_FILE_UPLOAD.CARD_INTRO}
                        screenName={ApplicationScreenConstants.STAFF_RATING_FILE_UPLOAD.SCREEN_NAME}
                    />
                </div>

                <div id="JobExecutionImages">
                    <CardComponent
                        history={history.push}
                        URL={ApplicationScreenConstants.JOB_EXECUTION_IMAGES.URL}
                        intro={ApplicationScreenConstants.JOB_EXECUTION_IMAGES.CARD_INTRO}
                        screenName={ApplicationScreenConstants.JOB_EXECUTION_IMAGES.SCREEN_NAME}
                    />
                </div>
            </Row>
        </div>
    );
}

export default OnboardedTools;