import React from "react";
import {TechnicianAssignmentTrailResponseProps} from "src/interfaces/props/TechnicianAssignmentTrailProps";
import {DataFetchState} from "src/interfaces/CommonTypes";
import DefaultStartScreenMessage from "src/components/common/message/DefaultStartScreenMessage";
import InvalidRequestMessage from "src/components/common/message/InvalidRequestMessage";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import NoDataFoundMessage from "src/components/common/message/NoDataFoundMessage";
import TechnicianAssignmentTrailData
    from "src/components/screens/technician-assignment-trail/TechnicianAssignmentTrailData";
import ErrorAlert from "src/components/common/alert/ErrorAlert";
import UnauthorizedUserMessage from "src/components/common/message/UnauthorizedUserMessage";

export class TechnicianAssignmentTrailResponse extends React.Component<TechnicianAssignmentTrailResponseProps> {
    constructor(props: TechnicianAssignmentTrailResponseProps) {
        super(props);
    }

    render() {
        const FAILED_REQUEST_MESSAGE = "Something wrong happened. Please try again.";

        return (
            <div>
                {(() => {
                    switch (this.props.trailDataFetchState) {
                        case DataFetchState.RequestNotMade:
                            return (
                                <DefaultStartScreenMessage/>
                            );
                        case DataFetchState.InvalidRequestMade:
                            return (
                                <InvalidRequestMessage/>
                            );
                        case DataFetchState.FetchingData:
                            return (
                                <FetchingDataMessage/>
                            );
                        case DataFetchState.NoDataFound:
                            return (
                                <NoDataFoundMessage/>
                            );
                        case DataFetchState.DataFetchFailed:
                            return (
                                <ErrorAlert text={FAILED_REQUEST_MESSAGE}/>
                            );
                        case DataFetchState.UnauthorizedUser:
                            return (
                                <UnauthorizedUserMessage/>
                            )
                        case DataFetchState.DataFetchedSuccessfully:
                            return (
                                <div id="TechnicianAssignmentTrailData">
                                    <TechnicianAssignmentTrailData
                                        tableInput={this.props.tableInput}
                                    />
                                </div>
                            );
                    }
                })()
                }
            </div>
        );
    }
}

export default TechnicianAssignmentTrailResponse;