import React from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import {RotateImageStates} from "src/interfaces/states/CommonStates";
import {RotateImageProps} from "src/interfaces/props/CommonProps";

export class RotateImage extends React.Component<RotateImageProps, RotateImageStates> {
    constructor(props: RotateImageProps) {
        super(props);
        this.rotateRight = this.rotateRight.bind(this);
        this.rotateLeft = this.rotateLeft.bind(this);
        this.state = {
            rotation: 0
        }
    }

    rotateRight() {
        let newRotation = this.state.rotation + 90;
        if (newRotation >= 360) {
            newRotation = -360;
        }
        this.setState({
            rotation: newRotation,
        })
    }

    rotateLeft() {
        let newRotation = this.state.rotation - 90;
        if (newRotation >= 360) {
            newRotation = -360;
        }
        this.setState({
            rotation: newRotation,
        })
    }

    render() {
        const {rotation} = this.state;
        return (
            <div>
                <Row alignmentHorizontal="center" widths={["grid-6", "grid-6"]}>
                    <img style={{transform: `rotate(${rotation}deg)`}} src={this.props.src} width={this.props.width} height={this.props.width}/>
                    <Column spacingInset="large" spacing="medium">
                        <Button id="LeftClickButton" type="primary" size="small" onClick={this.rotateLeft}> Rotate Left </Button>
                        <Button id="RightClickButton" type="primary" size="small" onClick={this.rotateRight}> Rotate Right </Button>
                    </Column>
                </Row>
            </div>
        );
    }
};

export default RotateImage;