import React from "react";
import {StaffsRatingProcessorProps} from "src/interfaces/props/StaffsRatingProcessorProps";
import Column from "@amzn/meridian/column";
import LargeText from "src/components/common/text/LargeText";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import SmallText from "src/components/common/text/SmallText";
import Divider from "@amzn/meridian/divider";
import {StaffsRatingProcessorInput} from "src/components/screens/staffs-rating-processor/StaffsRatingProcessorInput";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import StringUtils from "src/utils/StringUtils";
import {StaffsRatingProcessorStates} from "src/interfaces/states/StaffsRatingProcessorInputStates";
import ErrorAlert from "src/components/common/alert/ErrorAlert";
import {apiCallParameters, ENABLED_REALM_LIST, SUCCESS} from "src/constants/StaffsRatingProcessorApiConstants";
import {staffsRatingFileUpload} from "src/helpers/service-providers-data-upload/StaffsRatingProcessorAPIs";
import {deserializeUploadStaffsRatingFileResponse} from "src/helpers/service-providers-data-upload/StaffsRatingProcessorHelper";
import SuccessAlert from "src/components/common/alert/SuccessAlert";

function createPayloadObject(fileContent: string, fileType: string, storageType: string) {
    return {
        fileContent: fileContent,
        fileType: fileType,
        storageType: storageType
    };
}

export class StaffsRatingProcessorScreen extends React.Component<StaffsRatingProcessorProps, StaffsRatingProcessorStates> {

    constructor(props: StaffsRatingProcessorProps) {
        super(props);

        this.state = {
            fileContent: EMPTY_STRING,
            isHidden: true,
            responsePayload: [],
            message: EMPTY_STRING,
            isDisabledUploadButton: false
        }

        this.onChooseFileButtonClick = this.onChooseFileButtonClick.bind(this);
        this.onDeleteFileButtonClick = this.onDeleteFileButtonClick.bind(this);
        this.onUploadButtonClick = this.onUploadButtonClick.bind(this);
    }


    onChooseFileButtonClick(event: File[]) {
        this.setState({isHidden: true})
        this.setState({responsePayload: []})
        let reader = new FileReader();
        let file = event[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
            let fileContentURL = reader.result as string;
            this.setState({fileContent: fileContentURL})
        };
        reader.onerror = () => {
            this.setMessageAndShowIt("Error occurred while reading the rating file.")
        };
    }

    onDeleteFileButtonClick() {
        this.setState({fileContent: EMPTY_STRING})
        this.setState({responsePayload: []})
        this.setState({isHidden: true})
    }

    setMessageAndShowIt(messageString: string) {
        this.setState({isDisabledUploadButton: false})
        this.setState({isHidden: false})
        this.setState({message: messageString})
    }

    async uploadStaffsRatingFile(userName: string, payload: string) {
        let response = await staffsRatingFileUpload(apiCallParameters.UPLOAD_STAFFS_RATING_FILE, userName, payload)
        this.setState({responsePayload: deserializeUploadStaffsRatingFileResponse(response.ResponsePayload)});

        if (this.state.responsePayload[0].response === SUCCESS) {
            this.setMessageAndShowIt("Staff's Rating file is successfully uploaded.")
        } else {
            this.setMessageAndShowIt(this.state.responsePayload[0].errorInLineNumbers)
        }
    }

    onUploadButtonClick() {
        this.setState({isHidden: true})
        this.setState({isDisabledUploadButton: true})
        this.setState({message: EMPTY_STRING})
        if (StringUtils.isNotEmpty(this.state.fileContent) && ENABLED_REALM_LIST.includes(this.props.screenInput.realmValue)) {
            this.uploadStaffsRatingFile(this.props.screenInput.username,
                JSON.stringify(createPayloadObject(this.state.fileContent, "csv", "S3")))
        } else {
            if (!ENABLED_REALM_LIST.includes(this.props.screenInput.realmValue)) {
                this.setMessageAndShowIt("This tool is not enabled in selected Realm.")
            } else {
                this.setMessageAndShowIt("Staff's rating file is not chosen. Please choose staff's rating file and click on upload button.")
            }
        }
    }

    render() {
        return (
            <div>
                <div id="RatingFileUploadSection">
                    <Column alignmentHorizontal="left" spacingInset="xlarge" spacing="large">
                        <LargeText text={ApplicationScreenConstants.STAFF_RATING_FILE_UPLOAD.SCREEN_NAME}/>
                        <SmallText text={ApplicationScreenConstants.STAFF_RATING_FILE_UPLOAD.SCREEN_INTRO}/>
                        <StaffsRatingProcessorInput
                            onUploadButtonClick={this.onUploadButtonClick}
                            onDeleteFileButtonClick={this.onDeleteFileButtonClick}
                            onChooseFileButtonClick={this.onChooseFileButtonClick}
                            isDisabledUploadButton={this.state.isDisabledUploadButton}
                        />
                        <Divider size="small"/>
                    </Column>
                </div>

                <div hidden={this.state.isHidden}>
                    {(() => {
                        if (this.state.responsePayload.length && this.state.responsePayload[0].response === SUCCESS) {
                            return <SuccessAlert text={this.state.message}/>
                        } else {
                            return <ErrorAlert text={this.state.message}/>
                        }
                    })()
                    }
                </div>
            </div>
        );
    }
}