import {refreshMidway} from "src/components/authentication/MidwayTokenRetriever";
import {API} from "aws-amplify";
import {commonApiCallParameters, FALSE} from "src/constants/ApplicationCommonConstants";
import {apiCallParameters, FAILURE} from "src/constants/StaffsRatingProcessorApiConstants";

export const staffsRatingFileUpload = async (requestType: string, userLogin: string, payload: string) => {
    let response;
    const credentials = await refreshMidway();
    try {
        response = await API.post(apiCallParameters.STAFFS_RATING_PROCESSOR_API_NAME, commonApiCallParameters.EXECUTE_REQUEST, {
            body: {
                'RequestType': requestType,
                'EncryptedPayloadRequired': FALSE,
                'Tool': apiCallParameters.STAFFS_RATING_PROCESSOR_LAMBDA,
                'UserLogin': userLogin,
                'Payload': payload,
                'Token': credentials.token,
                'ClientId': location.host
            },
            headers: {'Content-Type': commonApiCallParameters.CONTENT_TYPE}
        });
        return response;
    } catch (error) {
        let response = {
            response: FAILURE,
            errorInLineNumbers: "Network error occurred while uploading the rating file"
        }
        return JSON.stringify(response)
    }
}