import React from "react";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Level4Heading from "src/components/common/heading/Level4Heading";
import {UploadImage} from "src/components/screens/technician-profile-edit/UploadImage";
import {TechnicianProfileDetailsEditPaneProps} from "src/interfaces/props/TechnicianProfileEditProps";
import {TechnicianProfileDetailsEditPaneState} from "src/interfaces/states/TechnicianProfileEditStates";
import {
    DISABLED,
    EMPTY_STRING,
    ENABLED,
    GOVT_ID,
    PROFILE_IMAGE
} from "src/constants/ApplicationCommonConstants";
import {
    apiCallParameters, technicianDetailsAttributeConstant
} from "src/constants/TechnicianProfileEditApiConstants";
import {
    createUpdateTechnicianPayloadObject,
    deserializeUpdateData
} from "src/helpers/technician-profile-edit/TechnicianProfileEditHelper";
import {technicianProfileEditApiCall} from "src/helpers/technician-profile-edit/TechnicianProfileEditAPIs";
import Level6Heading from "src/components/common/heading/Level6Heading";
import TechnicianEditProfileRemoveButton
    from "src/components/screens/technician-profile-edit/TechnicianEditProfileRemoveButton";
import SmallInput from "src/components/common/input/SmallInput";
import {ShowAttributeWithValue} from "src/components/screens/technician-profile-edit/ShowAttributeWithValue";
import MiniText from "src/components/common/text/MiniText";
import StringUtils from "src/utils/StringUtils";
import ErrorAlert from "src/components/common/alert/ErrorAlert";
import {TechnicianProfileDisabledButtonModal} from "src/components/screens/technician-profile-edit/TechnicianProfileDisabledButtonModal";
import SuccessAlert from "src/components/common/alert/SuccessAlert";
import Modal, {ModalFooter} from "@amzn/meridian/modal";

export class TechnicianProfileEditDetailsPane extends React.Component<TechnicianProfileDetailsEditPaneProps, TechnicianProfileDetailsEditPaneState> {
    constructor(props: TechnicianProfileDetailsEditPaneProps) {
        super(props);

        this.state = {
            responsePayload: [],
            updatedAttributeList: [],
            govtIdFirstName: this.props.technicianData.govtIdDetails.govtIdExtractedFirstName,
            govtIdLastName: this.props.technicianData.govtIdDetails.govtIdExtractedLastName,
            isProfileDisabledButtonFlag: false, //if true then profile marked as disabled
            profileStatus: ENABLED,
            uploadedProfilePhotoFile: EMPTY_STRING,
            uploadedGovtIdImageFile: EMPTY_STRING,
            openSaveButtonModal: false,
            uploadedProfilePhotoSourceURL: EMPTY_STRING,
            uploadedGovtIdPhotoSourceURL: EMPTY_STRING,
            isSaveButtonDisabled: true,
            isHiddenUpdatedGovtIdDetailsAlertMessage: true,
        }

        this.onChangeGovtIdFirstName = this.onChangeGovtIdFirstName.bind(this);
        this.onChangeGovtIdLastName = this.onChangeGovtIdLastName.bind(this);
        this.onTechnicianDisableButtonClick = this.onTechnicianDisableButtonClick.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.onProfilePhotoUploadComplete = this.onProfilePhotoUploadComplete.bind(this);
        this.onGovtIdUploadComplete = this.onGovtIdUploadComplete.bind(this);
        this.onProfilePhotoRemoveModalContinueClick = this.onProfilePhotoRemoveModalContinueClick.bind(this);
        this.onGovtIdRemoveModalContinueClick = this.onGovtIdRemoveModalContinueClick.bind(this);
        this.onSaveButtonModalCloseClick = this.onSaveButtonModalCloseClick.bind(this);
        this.onRemoveUploadedProfilePhoto = this.onRemoveUploadedProfilePhoto.bind(this);
        this.onRemoveUploadedGovtId = this.onRemoveUploadedGovtId.bind(this);
    }

    onProfilePhotoUploadComplete(event: File[]) {
        this.setState({uploadedProfilePhotoSourceURL: URL.createObjectURL(event[0])})
        this.setState({isSaveButtonDisabled: false});
        this.addUpdatedAttributeToList(PROFILE_IMAGE);
        let reader = new FileReader();
        let file = event[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
            let imageDataURL = reader.result as string;
            this.setState({uploadedProfilePhotoFile:imageDataURL});
        };
    }

    onGovtIdUploadComplete(event: File[]) {
        this.setState({uploadedGovtIdPhotoSourceURL: URL.createObjectURL(event[0])})
        this.setGovtIdAttribute()
        let reader = new FileReader();
        let file = event[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
            let imageDataURL = reader.result as string;
            this.setState({uploadedGovtIdImageFile:imageDataURL});
        };
    }

    onRemoveUploadedProfilePhoto() {
        this.setState({ uploadedProfilePhotoFile: EMPTY_STRING })
        this.setState({uploadedProfilePhotoSourceURL: EMPTY_STRING})
    }

    onRemoveUploadedGovtId() {
        this.setState({uploadedGovtIdImageFile: EMPTY_STRING})
        this.setState({uploadedGovtIdPhotoSourceURL: EMPTY_STRING})
    }

    onChangeGovtIdFirstName(event :string) {
        this.setState({govtIdFirstName: event});
        this.setGovtIdAttribute()
    }

    onChangeGovtIdLastName(event: string) {
        this.setState({govtIdLastName: event});
        this.setGovtIdAttribute()
    }

    setGovtIdAttribute() {
        this.setState({isSaveButtonDisabled: false});
        this.setState({isHiddenUpdatedGovtIdDetailsAlertMessage: true});
        this.addUpdatedAttributeToList(GOVT_ID);
    }

    onTechnicianDisableButtonClick() {
        if (this.state.isProfileDisabledButtonFlag) {
            this.setState({isProfileDisabledButtonFlag: !this.state.isProfileDisabledButtonFlag});
            this.setState({profileStatus: ENABLED});
        }
        else {
            this.setState({isProfileDisabledButtonFlag: !this.state.isProfileDisabledButtonFlag});
            this.setState({profileStatus: DISABLED});
        }
    }

    onProfilePhotoRemoveModalContinueClick() {
        this.setState({isSaveButtonDisabled: false});
        this.setState({uploadedProfilePhotoFile: EMPTY_STRING});
        this.addUpdatedAttributeToList(PROFILE_IMAGE);
    }

    onGovtIdRemoveModalContinueClick() {
        this.setState({isSaveButtonDisabled: false});
        this.setState({uploadedGovtIdImageFile: EMPTY_STRING});
        this.addUpdatedAttributeToList(GOVT_ID);
    }

    addUpdatedAttributeToList(attribute: string) {
        if (!this.state.updatedAttributeList.includes(attribute)) {
            this.state.updatedAttributeList.push(attribute);
        }
    }

    onSaveButtonModalCloseClick() {
        this.setState({openSaveButtonModal: false});
    }

    async updateTechnicianData (userName: string, payload: string) {
        let response;
        response = await technicianProfileEditApiCall(apiCallParameters.UPDATE_TECHNICIAN, userName, payload, EMPTY_STRING);
        this.setState({responsePayload: deserializeUpdateData(response.ResponsePayload)});
        this.setState({openSaveButtonModal: true});
    }

    validateUpdatedGovtIdDetails() {
        return !(this.state.updatedAttributeList.includes(GOVT_ID) &&
            (StringUtils.isEmpty(this.props.technicianData.govtIdDetails.govtIdExtractedImage) && StringUtils.isEmpty(this.state.uploadedGovtIdImageFile) ||
            (StringUtils.isEmpty(this.state.govtIdFirstName) || StringUtils.isEmpty(this.state.govtIdLastName))));
    }

    onSaveButtonClick() {
        if (this.validateUpdatedGovtIdDetails()) {
            this.updateTechnicianData(this.props.userLogin,
                JSON.stringify(createUpdateTechnicianPayloadObject(this.props.marketplaceId, this.props.merchantId, this.props.technicianId, this.state.uploadedProfilePhotoFile,
                    this.props.technicianData.profileDetails.emailId, this.state.isProfileDisabledButtonFlag, this.state.updatedAttributeList,
                    this.state.govtIdFirstName, this.state.govtIdLastName, this.state.uploadedGovtIdImageFile, this.props.technicianData.govtIdDetails.govtIdExtractedImage)));
        }
        else {
            this.setState({isHiddenUpdatedGovtIdDetailsAlertMessage: false});
        }
    }

    render() {
        return(
                <Column>
                    <Row width="100%" widths={["grid-3", "grid-3", "grid-3", "grid-3"]} alignmentVertical={"top"}>

                        {/*=========Profile Basics Information Box===========*/}
                        <Box type={"outline"} height={"425px"}>
                            <Column alignmentHorizontal="left" spacingInset="large" spacing="large" alignmentVertical={"top"}>
                                <Level4Heading text={technicianDetailsAttributeConstant.PROFILE_BASICS} />
                                <Column alignmentHorizontal={"left"} spacingInset={"xsmall"} spacing={"xsmall"}>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.DATE_CREATED} attributeValue={this.props.technicianData.profileDetails.bgcDate}/>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.EMAIL_ADDRESS} attributeValue={this.props.technicianData.profileDetails.emailId}/>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.FIRST_NAME} attributeValue={this.props.technicianData.profileDetails.firstName}/>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.LAST_NAME} attributeValue={this.props.technicianData.profileDetails.lastName}/>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.MOBILE_NUMBER} attributeValue={this.props.technicianData.profileDetails.mobileNo}/>
                                </Column>

                                <Column alignmentHorizontal={"left"} spacing={"small"} spacingInset={"small"}>
                                    <Row width="100%" widths={["grid-6", "grid-2"]}>
                                        <Level6Heading text={technicianDetailsAttributeConstant.PROFILE_STATUS}/>
                                        <TechnicianProfileDisabledButtonModal isProfileDisabledButtonFlag={this.state.isProfileDisabledButtonFlag}
                                                                              onToggleButtonClick={this.onTechnicianDisableButtonClick}
                                                                              profileStatus={this.state.profileStatus}
                                        />
                                    </Row>
                                    <Row widths={["grid-12"]}>
                                        <MiniText text={"Note: The system will un-assign any open orders associated with the Technician when \"disabled\" is selected"}/>
                                    </Row>
                                </Column>
                            </Column>
                        </Box>

                        {/*==============GovernmentID Information Box==============*/}
                        <Box type={"outline"} height={"425px"}>
                            <Column alignmentHorizontal="left" spacingInset="large" spacing="large" alignmentVertical={"top"}>
                                <Row width="100%" widths={["grid-7", "grid-5"]} spacing="large">
                                    <Level4Heading text={technicianDetailsAttributeConstant.GOVERNMENT_ID} />

                                    {/*TODO: once govtId photo remove capability is implemented on SPIN side, will un-hide this button
                                    <TechnicianEditProfileRemoveButton
                                        alertText={"Are you sure you want to remove this Government ID? Click Continue to remove. Otherwise, click Cancel."}
                                        modalTitle={"Remove Government ID?"}
                                        profileDisabledButtonFlag={this.state.profileDisabledButtonFlag}
                                        onModalConfirmClick={this.onGovtIdRemoveModalContinueClick}
                                    />*/}
                                </Row>

                                <Column alignmentHorizontal={"left"} spacingInset={"xsmall"} spacing={"xsmall"}>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.STATUS} attributeValue={this.props.technicianData.govtIdDetails.govtIdStatus}/>
                                    <Row width={"100%"} widths={["grid-4", "grid-8"]}>
                                        <Level6Heading text={technicianDetailsAttributeConstant.FIRST_NAME}/>
                                        <SmallInput id={technicianDetailsAttributeConstant.FIRST_NAME} type={"text"} onChange={this.onChangeGovtIdFirstName}
                                                    value={this.state.govtIdFirstName}
                                                    isDisabled={this.state.isProfileDisabledButtonFlag || this.props.technicianData.govtIdDetails.govtIdStatus == "APPROVED"}
                                        />
                                    </Row>
                                    <Row width={"100%"} widths={["grid-4", "grid-8"]}>
                                        <Level6Heading text={technicianDetailsAttributeConstant.LAST_NAME}/>
                                        <SmallInput id={technicianDetailsAttributeConstant.LAST_NAME} type={"text"} onChange={this.onChangeGovtIdLastName}
                                                    value={this.state.govtIdLastName}
                                                    isDisabled={this.state.isProfileDisabledButtonFlag || this.props.technicianData.govtIdDetails.govtIdStatus == "APPROVED"}
                                        />
                                    </Row>
                                    <Row width={"100%"} widths={["grid-4", "grid-6"]}>
                                        <Level6Heading text={technicianDetailsAttributeConstant.IMAGE}/>
                                        <img id={"GovtIdPhoto"} src={this.props.technicianData.govtIdDetails.govtIdExtractedImage} alt={"GovtId photo can not be displayed"} width={"80"} height={"100"} />
                                        <UploadImage imageSource={this.state.uploadedGovtIdPhotoSourceURL}
                                                     onFileUploadComplete={this.onGovtIdUploadComplete}
                                                     removeUploadedFile={this.onRemoveUploadedGovtId}
                                                     isHidden={this.props.technicianData.govtIdDetails.govtIdStatus == "APPROVED"}
                                        />
                                    </Row>
                                    <div hidden={this.state.uploadedGovtIdImageFile.length <= 0}>
                                        <Row widths={["grid-7", "grid-5"]} alignmentHorizontal={"center"}>
                                            <Level6Heading text={technicianDetailsAttributeConstant.FILE_UPLOADED}/>
                                            <Button onClick={this.onRemoveUploadedGovtId} type={"link"}>Remove</Button>
                                        </Row>
                                    </div>
                                    <div hidden={this.state.isHiddenUpdatedGovtIdDetailsAlertMessage}>
                                        <ErrorAlert text={"Atleast one entry is missing of Government Id detail."}/>
                                    </div>
                                </Column>
                            </Column>
                        </Box>

                        {/*==============ProfilePhoto Information Box==============*/}
                        <Box type={"outline"} height={"425px"}>
                            <Column alignmentHorizontal="left" spacingInset="large" spacing="large" alignmentVertical={"top"}>
                                <Row width="100%" widths={["grid-7", "grid-5"]} spacing="large">
                                    <Level4Heading text={technicianDetailsAttributeConstant.PROFILE_PHOTO} />

                                    {/*TODO: once photo remove capability is implemented on SPIN side, will un-hide this button
                                    <TechnicianEditProfileRemoveButton
                                        alertText={"Are you sure you want to remove this Profile Photo? Click Continue to remove. Otherwise, click Cancel."}
                                        modalTitle={"Remove Profile Photo?"}
                                        profileDisabledButtonFlag={this.state.profileDisabledButtonFlag}
                                        onModalConfirmClick={this.onProfilePhotoRemoveModalContinueClick}
                                    />*/}
                                </Row>
                                <Column alignmentHorizontal={"left"} spacing={"medium"} spacingInset={"small"}>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.STATUS} attributeValue={this.props.technicianData.profileDetails.profileImageStatus}/>
                                    <Row width={"100%"} widths={["grid-4", "grid-6"]}>
                                        <Level6Heading text={technicianDetailsAttributeConstant.PHOTO}/>
                                        <img id={"ProfilePhoto"} src={this.props.technicianData.profileDetails.profileImage} alt={"Photo can not be displayed"} width={"80"} height={"100"} />
                                        <UploadImage imageSource={this.state.uploadedProfilePhotoSourceURL}
                                                     onFileUploadComplete={this.onProfilePhotoUploadComplete}
                                                     removeUploadedFile={this.onRemoveUploadedProfilePhoto}
                                        />
                                    </Row>
                                    <div hidden={this.state.uploadedProfilePhotoFile.length <= 0}>
                                        <Row widths={["grid-7", "grid-5"]} alignmentHorizontal={"center"}>
                                                <Level6Heading text={technicianDetailsAttributeConstant.FILE_UPLOADED}/>
                                                <Button onClick={this.onRemoveUploadedProfilePhoto} type={"link"}>Remove</Button>
                                        </Row>
                                    </div>
                                </Column>
                            </Column>
                        </Box>

                        {/*==============BackgroundCheck Information Box==============*/}
                        <Box type={"outline"} height={"425px"}>
                            <Column alignmentHorizontal="left" spacingInset="large" spacing="large" alignmentVertical={"top"}>
                                <Level4Heading text={technicianDetailsAttributeConstant.BACKGROUND_CHECK} />
                                <Column alignmentHorizontal={"left"} spacing={"small"} spacingInset={"small"}>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.CHECK_TYPE} attributeValue={this.props.technicianData.profileDetails.bgcType}/>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.LAST_CHECK} attributeValue={this.props.technicianData.profileDetails.bgcDate}/>
                                    <ShowAttributeWithValue attributeName={technicianDetailsAttributeConstant.STATUS} attributeValue={this.props.technicianData.profileDetails.bgcStatus}/>
                                </Column>
                            </Column>
                        </Box>
                    </Row>

                    {/*==========ActionButtons===========*/}
                    <Row widths={["grid-2", "grid-2"]} alignmentHorizontal={"center"}>
                        <Button size={"small"} type={"primary"} onClick={this.onSaveButtonClick} disabled={this.state.isSaveButtonDisabled && !this.state.isProfileDisabledButtonFlag}> Save </Button>
                        <Button size={"small"} type={"secondary"} onClick={this.props.onCancelButtonClick}> Cancel </Button>

                        <Modal title={"Update received..."} open={this.state.openSaveButtonModal} scrollContainer="viewport" bodySpacingInset="medium" onClose={this.onSaveButtonModalCloseClick}>
                            <SuccessAlert text={"Update received. Check Audit Service Providers Dashboard for status"}/>
                            <ModalFooter>
                                <Row alignmentHorizontal="center">
                                    <Button type="primary" size="small" onClick={this.onSaveButtonModalCloseClick}> OK </Button>
                                </Row>
                            </ModalFooter>
                        </Modal>
                    </Row>
                </Column>
        );
    }
}

export default TechnicianProfileEditDetailsPane;