/**
 * Todo: Replace this with Moment.js
 */
class DateTimeUtils {
    static getUTCTimeFromDateString(dateString: string): number {
        const someDate = new Date(dateString);
        return someDate.getTime() + (Math.round(someDate.getTimezoneOffset()) * 60 * 1000);
    }

    static getDateAndTimeString(epochValue: number): string {
        const someDate = new Date(epochValue);
        return someDate.toDateString() + " " + someDate.toTimeString();
    }
}

export default DateTimeUtils;