import React from "react";
import Column from "@amzn/meridian/column";
import {DataFetchState} from "src/interfaces/CommonTypes";
import {ExecutionArtifactsResponseProps} from "src/interfaces/props/ExecutionArtifactsProps";
import DefaultStartScreenMessage from "src/components/common/message/DefaultStartScreenMessage";
import InvalidRequestMessage from "src/components/common/message/InvalidRequestMessage";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import NoDataFoundMessage from "src/components/common/message/NoDataFoundMessage";
import ExecutionArtifactsTable from "src/components/screens/audit-execution-artifacts/ExecutionArtifactsTable";
import UnauthorizedUserMessage from "src/components/common/message/UnauthorizedUserMessage";
import SmallText from "src/components/common/text/SmallText";
import ErrorAlert from "src/components/common/alert/ErrorAlert";

export class ExecutionArtifactsResponse extends React.Component<ExecutionArtifactsResponseProps> {

    constructor(props: ExecutionArtifactsResponseProps) {
        super(props);
    }

    render() {
        const FAILED_REQUEST_MESSAGE = "Something wrong happened. Please try again.";

        return (
            <div>
                {(() => {
                    switch (this.props.auditDataFetchState) {
                        case DataFetchState.RequestNotMade:
                            return (
                                <DefaultStartScreenMessage />
                            );
                        case DataFetchState.InvalidRequestMade:
                            return (
                                <InvalidRequestMessage />
                            );
                        case DataFetchState.DataFetchFailed:
                            return (
                                <ErrorAlert text={FAILED_REQUEST_MESSAGE}/>
                            );
                        case DataFetchState.FetchingData:
                            return (
                                <Column alignmentHorizontal="center">
                                    <FetchingDataMessage />
                                    <SmallText text={`${this.props.tableInput.result.length} audit records fetched so far...`} />
                                </Column>
                            );
                        case DataFetchState.NoDataFound:
                            return (
                                <NoDataFoundMessage />
                            );
                        case DataFetchState.UnauthorizedUser:
                            return (
                                <UnauthorizedUserMessage/>
                            )
                        case DataFetchState.DataFetchedSuccessfully:
                            return (
                                <div id="ExecutionArtifactsDataTable">
                                    <ExecutionArtifactsTable
                                        result={this.props.tableInput.result}
                                        username={this.props.tableInput.username}
                                        dateValue={this.props.tableInput.dateValue}
                                        marketplaceId={this.props.tableInput.marketplaceId}
                                    />
                                </div>
                            );
                    }
                })()
                }
            </div>
        );
    }
}
export default ExecutionArtifactsResponse;