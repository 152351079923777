import React from "react";
import Button from "@amzn/meridian/button";
import Modal, {ModalFooter} from "@amzn/meridian/modal"
import Row from "@amzn/meridian/row"
import SmallText from "src/components/common/text/SmallText";
import {ExecutionArtifactsActionButtonProps} from "src/interfaces/props/ExecutionArtifactsProps";

export class ExecutionArtifactsActionButtons extends React.Component<ExecutionArtifactsActionButtonProps> {

    constructor(props: ExecutionArtifactsActionButtonProps) {
        super(props);
    }

    render() {
        const CONFIRMATION_TEXT = "Click to confirm";
        return(
            <div>
                <Button disabled={this.props.disableFlag} onClick={this.props.onModalClick} type={this.props.type} size="small" minWidth={this.props.minWidth}> {this.props.buttonName} </Button>
                <Modal title={this.props.title} open={this.props.openModal} onClose={this.props.onCancelClick} scrollContainer="viewport">
                    <SmallText text={CONFIRMATION_TEXT} />
                    <ModalFooter>
                        <Row alignmentHorizontal="center" widths={["grid-6", "grid-6"]} spacing="large">
                            <Button type="secondary" size="small" onClick={this.props.onCancelClick}> Cancel </Button>
                            <Button type="primary" size="small" onClick={this.props.onConfirmClick}> Confirm </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default ExecutionArtifactsActionButtons;