export type Stages = "DEV" | "BETA" | "PROD"
export type RealmValues = "EUAmazon" | "USAmazon"
export type Regions = "eu-west-1" | "us-west-2" | "us-east-1"

export enum DataFetchState {
    RequestNotMade, InvalidRequestMade, FetchingData,
    NoDataFound, DataFetchedSuccessfully, DataFetchFailed,
    InvalidMarketplace,UnauthorizedUser

}

export interface RealmParameters {
    IDENTITY_POOL_ID: string,
    REGION: Regions,
    IDENTITY_POOL_REGION: string,
    API_ENDPOINT: string,
    ENDPOINTS_REGION: Regions,
    ENV: Stages
}

export interface RealmMapping {
    EUAmazon: RealmParameters
    USAmazon: RealmParameters
    JPAmazon: RealmParameters
}

export interface URLMapping {
    [key: string]: RealmMapping
}

export interface UserDetails {
    username: string,
    name: string
}

export type AuditStatusType = {
    [key: string]: string
}

export enum AlertType {
    ERROR = "error",
    SUCCESS = "success",
}

export type XmlEntitiesType = {
    [key: string]: string;
}