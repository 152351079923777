import {UploadRatingFileResponseObject} from "src/interfaces/props/StaffsRatingProcessorProps";
import {FAILURE} from "src/constants/StaffsRatingProcessorApiConstants";

export const deserializeUploadStaffsRatingFileResponse = (serializedData: string) => {
    let deserializedStringArray
    let deserializedArray = [] as UploadRatingFileResponseObject[];
    try {
        deserializedStringArray = JSON.parse(serializedData)
    } catch (error) {
        deserializedStringArray.response = FAILURE
        deserializedStringArray.errorInLineNumbers =
            "Error occurred while deserializing the response."
    }
    deserializedArray.push(deserializedStringArray);
    return deserializedArray;
}