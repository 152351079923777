import StringUtils from "src/utils/StringUtils";
import {BUTTON_URL_PREFIX, EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import queryString from "querystring";

const LEADING_COMMAS_OR_SPACES_REGEX = /^[,\s]+/;
const TRAILING_COMMAS_OR_SPACES_REGEX = /[,\s]+$/;
const ONE_OR_MORE_COMMAS_OR_SPACES_REGEX = /[,\s]+/;

const NOTIFICATION_DETAILS_URL =
    `${BUTTON_URL_PREFIX}${ApplicationScreenConstants.SEND_BULK_NOTIFICATIONS.NOTIFICATION_DETAILS}`;

export const navigateToResendNotification = (referenceId: string) => {
    window.location.assign(`${NOTIFICATION_DETAILS_URL}?referenceId=${referenceId}`);
}

export const navigateToCreateNewNotification = () => {
    window.location.assign(NOTIFICATION_DETAILS_URL);
}

export const getNotificationReferenceIdFromURL = (url: string): string => {
    const referenceId = queryString.parse(url.split("?")[1])?.referenceId as string;
    return StringUtils.isEmpty(referenceId) ? EMPTY_STRING : referenceId;
}

export const getS3ObjectKeyFromURL = (url: string): string => {
    const helperElement = document.createElement('a');
    helperElement.href = url;
    return helperElement.pathname.substring(1);
}

export const getRecipientsList = (recipientsBlock: string): string[] => {
    const strippedBlock = recipientsBlock.replace(LEADING_COMMAS_OR_SPACES_REGEX, EMPTY_STRING)
        .replace(TRAILING_COMMAS_OR_SPACES_REGEX, EMPTY_STRING);
    if (StringUtils.isEmpty(strippedBlock)) {
        // explicit empty list, because splitting an empty string by regex returns a non-empty list with an empty string
        return [];
    }
    return strippedBlock.split(ONE_OR_MORE_COMMAS_OR_SPACES_REGEX);
}