import {getS3UploadUrl} from "src/helpers/send-bulk-notifications/SendBulkNotificationsAPIs";
import {BulkNotificationDetailsScreenState} from "src/interfaces/states/SendBulkNotificationsStates";

export const uploadFileToS3AndGetReferenceId = async (state: BulkNotificationDetailsScreenState):
        Promise<string> => {
    const apiOutput = await getS3UploadUrl();
    if (apiOutput) {
        const s3Response = await fetch(apiOutput.s3Url, {
            method: "PUT",
            body: state.notificationDetailsFile,
        });
        if(s3Response.ok) {
            return apiOutput.s3ObjectKey;
        } else {
            throw "There was an error while uploading the file.";
        }
    } else {
        throw "There was an error while trying to upload the file.";
    }
}
