import {API} from "aws-amplify";
import {apiCallParameters} from "src/constants/TechnicianProfileEditApiConstants";
import {commonApiCallParameters, FALSE} from "src/constants/ApplicationCommonConstants";
import {refreshMidway} from "src/components/authentication/MidwayTokenRetriever";

export const technicianProfileEditApiCall = async (requestType: string, userLogin: string, payload: string, offset: string) => {
    let response;
    const credentials = await refreshMidway();
    try {
        response = await API.post(apiCallParameters.TECHNICIAN_PROFILE_EDIT_API_NAME, commonApiCallParameters.EXECUTE_REQUEST, {
            body: {
                'RequestType': requestType,
                'EncryptedPayloadRequired': FALSE,
                'Tool': apiCallParameters.TECHNICIAN_PROFILE_EDIT_LAMBDA,
                'UserLogin': userLogin,
                'Payload': payload,
                'Offset': offset,
                'Token': credentials.token,
                'ClientId': location.host
            },
            headers: {'Content-Type': commonApiCallParameters.CONTENT_TYPE}
        });
        return response;
    } catch (error) {
        throw error; //TODO: will handle this exception later
    }
}