import React from "react"
import Tooltip from "@amzn/meridian/tooltip";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import userTokens from "@amzn/meridian-tokens/base/icon/user";
import asleepTokens from "@amzn/meridian-tokens/base/icon/asleep"
import LogoText from "src/components/common/text/LogoText";
import {FEU_PORTAL} from "src/constants/ApplicationCommonConstants";
import {HeaderProps} from "src/interfaces/props/CommonProps";
import {REALM, REALM_LABEL_LIST, REALM_VALUE_LIST} from "src/constants/ApplicationCommonConstants";
import Masthead, {MastheadMenuButton, MastheadTitle} from "@amzn/meridian/masthead";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import SmallDropdownWithPrefix from "src/components/common/dropdown/SmallDropdownWithPrefix";
import Toggle from "@amzn/meridian/toggle"

export class ApplicationHeader extends React.Component<HeaderProps> {

    constructor(props: HeaderProps) {
        super(props);
    }

    render() {
        return (
            <Masthead size="large">
                <MastheadMenuButton onClick={this.props.menuButton}/>
                <MastheadTitle href={ApplicationScreenConstants.HOME.URL}>
                    <LogoText text={FEU_PORTAL} />
                </MastheadTitle>

                <SmallDropdownWithPrefix
                    defaultValue={this.props.realmValue}
                    onSelect={this.props.changeRealm}
                    prefix={REALM}
                    valueList={REALM_VALUE_LIST}
                    labelList={REALM_LABEL_LIST}
                />

                <Tooltip position="bottom" title={this.props.username}>
                    <Button type="icon" size="small"> <Icon tokens={userTokens}></Icon> </Button>
                </Tooltip>

                <Toggle checked={this.props.darkTheme} onChange={this.props.switchApplicationTheme} />
                <Icon tokens={asleepTokens} />
            </Masthead>
        );
    }
}

export default ApplicationHeader;
