import React from "react";
import Column from "@amzn/meridian/column";
import MediumText from "src/components/common/text/MediumText";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import Icon from "@amzn/meridian/icon";
import plusTokens from "@amzn/meridian-tokens/base/icon/plus-knockout";
import {SearchContactSupportCasesMain} from "src/components/screens/contact-support-case/SearchContactSupportCasesMain";
import {ScreenProps} from "src/interfaces/props/CommonProps";
import {navigateToCreateOrUpdateContactSupportCasePage} from "src/helpers/contact-support-case/ContactSupportCaseCommonHelper";

export class SearchContactSupportCasesScreen extends React.Component<ScreenProps> {

    constructor(props: ScreenProps) {
        super(props);
    }

    render() {
        const SCREEN_NAME = "Contact Support Cases";
        return (
            <div id={"ContactSupportCasesScreen"}>
                <div id={"Title"}>
                    <Column alignmentHorizontal="left" spacingInset="large" spacing="large">
                        <MediumText text={SCREEN_NAME}/>
                    </Column>
                </div>
                <div id={"CreateButton"}>
                    <Row alignmentHorizontal="right" spacingInset="small" spacing="small">
                        <Column>
                            <Button
                                onClick={() => navigateToCreateOrUpdateContactSupportCasePage(EMPTY_STRING)}>
                                <Icon tokens={plusTokens}/> Create New Case
                            </Button>
                        </Column>
                    </Row>
                </div>
                <div id={"mainScreen"}>
                    <SearchContactSupportCasesMain searchResults={[]} screenInput={this.props}/>
                </div>
            </div>
        );
    }
}

export default SearchContactSupportCasesScreen;
