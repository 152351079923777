import {URLMapping} from "src/interfaces/CommonTypes";

const cognitoConfig: URLMapping = {

    "https://vas-feu-portal.a2z.com:4321": {
        EUAmazon: {
            IDENTITY_POOL_ID: "eu-west-1:89bec44d-a9c3-4704-a5b1-c1716a26077f",
            REGION: "eu-west-1",
            IDENTITY_POOL_REGION: "eu-west-1",
            API_ENDPOINT: "https://oyqhivvmzi.execute-api.eu-west-1.amazonaws.com/beta",
            ENDPOINTS_REGION: "eu-west-1",
            ENV: "DEV"
        },
        USAmazon: {
            IDENTITY_POOL_ID: "us-west-2:d0453270-0ebb-47d6-8c86-eec67f719202",
            REGION: "us-west-2",
            IDENTITY_POOL_REGION: "us-west-2",
            API_ENDPOINT: "https://oi7z402ru1.execute-api.us-west-2.amazonaws.com/beta",
            ENDPOINTS_REGION: "us-west-2",
            ENV: "DEV"
        },
        JPAmazon: {
            IDENTITY_POOL_ID: "us-west-2:d0453270-0ebb-47d6-8c86-eec67f719202",
            REGION: "us-west-2",
            IDENTITY_POOL_REGION: "us-west-2",
            API_ENDPOINT: "https://oi7z402ru1.execute-api.us-west-2.amazonaws.com/beta",
            ENDPOINTS_REGION: "us-west-2",
            ENV: "DEV"
        }
    },

    "https://www.feu.integ.vas.amazon.dev": {
        EUAmazon: {
            IDENTITY_POOL_ID: "eu-west-1:89bec44d-a9c3-4704-a5b1-c1716a26077f",
            REGION: "eu-west-1",
            IDENTITY_POOL_REGION: "eu-west-1",
            API_ENDPOINT: "https://oyqhivvmzi.execute-api.eu-west-1.amazonaws.com/beta",
            ENDPOINTS_REGION: "eu-west-1",
            ENV: "BETA"
        },
        USAmazon: {
            IDENTITY_POOL_ID: "us-west-2:d0453270-0ebb-47d6-8c86-eec67f719202",
            REGION: "us-west-2",
            IDENTITY_POOL_REGION: "us-west-2",
            API_ENDPOINT: "https://oi7z402ru1.execute-api.us-west-2.amazonaws.com/beta",
            ENDPOINTS_REGION: "us-west-2",
            ENV: "BETA"
        },
        JPAmazon: {
            IDENTITY_POOL_ID: "us-west-2:d0453270-0ebb-47d6-8c86-eec67f719202",
            REGION: "us-west-2",
            IDENTITY_POOL_REGION: "us-west-2",
            API_ENDPOINT: "https://oi7z402ru1.execute-api.us-west-2.amazonaws.com/beta",
            ENDPOINTS_REGION: "us-west-2",
            ENV: "BETA"
        }
    },

    "https://www.feu.prod.vas.amazon.dev": {
        EUAmazon: {
            IDENTITY_POOL_ID: "eu-west-1:a47bf574-93c1-4381-81c1-50f588744ea1",
            REGION: "eu-west-1",
            IDENTITY_POOL_REGION: "eu-west-1",
            API_ENDPOINT: "https://p96z1vqqc3.execute-api.eu-west-1.amazonaws.com/prod",
            ENDPOINTS_REGION: "eu-west-1",
            ENV: "PROD"
        },
        USAmazon: {
            IDENTITY_POOL_ID: "us-east-1:7016f6cc-a4f8-4b20-8624-87862da76596",
            REGION: "us-east-1",
            IDENTITY_POOL_REGION: "us-east-1",
            API_ENDPOINT: "https://sbswy683ff.execute-api.us-east-1.amazonaws.com/prod",
            ENDPOINTS_REGION: "us-east-1",
            ENV: "PROD"
        },
        JPAmazon: {
            IDENTITY_POOL_ID: "us-west-2:9e51996a-5f93-48e9-8478-fc3fa6c36c60",
            REGION: "us-west-2",
            IDENTITY_POOL_REGION: "us-west-2",
            API_ENDPOINT: "https://twpmeq4huk.execute-api.us-west-2.amazonaws.com/prod",
            ENDPOINTS_REGION: "us-west-2",
            ENV: "PROD"
        }
    }
}
export default cognitoConfig;