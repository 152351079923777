import {serviceProviderRowObject} from "src/interfaces/props/ServiceProvidersAuditProps";

export const deserializeData = (serializedData: string) => {
    let deserializedStringArray = JSON.parse(serializedData)
    let arrayIndex;
    let deserializedArray = [] as serviceProviderRowObject[];
    for (arrayIndex = 0; arrayIndex < deserializedStringArray.length; arrayIndex++) {
        let data = JSON.parse(deserializedStringArray[arrayIndex]);
        deserializedArray.push(data);
    }
    return deserializedArray;
}

export const searchQuery = (object: serviceProviderRowObject, searchValue: string) => {
    let searchResult = ((object.resourceType == searchValue) || (object.auditType == searchValue) ||
        (object.marketPlaceId == searchValue) || (object.merchantId == searchValue) || (object.resourceId == searchValue) ||
        (object.matchedFacesSimilarityScore == searchValue) || (object.auditStatus == searchValue) || (object.lastUpdatedBy == searchValue) ||
        (object.errorMessage == searchValue))
    return searchResult;
}