import React from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Button from "@amzn/meridian/button";
import {TABLE_ALIGNMENT} from "src/constants/ApplicationCommonConstants";
import {NotificationInfo} from "src/interfaces/models/SendBulkNotificationsModels";
import {SearchNotificationsTableProps} from "src/interfaces/props/SendBulkNotificationsProps";
import DateTimeUtils from "src/utils/DateTimeUtils";

export default class SearchNotificationsTable extends React.Component<SearchNotificationsTableProps> {
    compareByDate(a: NotificationInfo, b: NotificationInfo): number {
        return a.creationTime < b.creationTime ? 1 : 0;
    }

    render() {
        let serialNumber = this.props.serialNumberOffset;
        return (
            <Table headerRows={1} showDividers={true}>
                <TableRow highlightOnHover={true}>
                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>S.No.</TableCell>
                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Creation Time</TableCell>
                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Notification Title</TableCell>
                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Notification Type</TableCell>
                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Sender</TableCell>
                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Actions</TableCell>
                </TableRow>
                {
                    this.props.notifications.sort(this.compareByDate).map((row: NotificationInfo) => (
                        <TableRow key={row.referenceId} highlightOnHover={true}>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{++serialNumber}</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                {DateTimeUtils.getDateAndTimeString(row.creationTime)}
                            </TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.title}</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.notificationType}</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.userAlias}</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                <Button size={"medium"} type={"secondary"} href={row.referenceId}
                                        onClick={this.props.onViewClick}>
                                    View
                                </Button>
                                <Button size={"medium"} type={"secondary"} href={row.referenceId}
                                        onClick={this.props.onResendClick} >
                                    Edit & Resend
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </Table>
        );
    }
}
