export const apiCallParameters = {
	FEU_JOB_EXECUTION_IMAGES_API_NAME: "FEUJobExecutionImageslambda",
	FEU_JOB_EXECUTION_IMAGES_LAMBDA_TOOL: "FEUJobExecutionImageslambda",
	GET_JOB_EXECUTION_IMAGES_REQUEST: "getJobPhotos"
};

export const FEU_JOB_EXECUTION_IMAGES_AUTH_TOOL = "FEUJobExecutionImagesAuthZ";

export const STATUS_CODE_TO_MESSAGE_MAP = {
	NO_PHOTOS_FOUND: {
		text: "No Photos were found for the given job.",
		type: "informational"
	},
	DENIED_TO_SAVE_PHOTOS: {
		text: "User denied permission to save job photos.",
		type: "informational"
	},
	DENIED_TO_CAPTURE_PHOTOS: {
		text: "User denied permission to capture photos.",
		type: "informational"
	},
	FAILURE: {
		text: "Some error occurred while fetching job photos. Please try again.",
		type: "error"
	}
};
