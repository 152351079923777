import React from "react";
import Select, {SelectOption} from "@amzn/meridian/select";
import {SelectSize} from "@amzn/meridian/select/select";
import {DropDownCommonProps} from "src/interfaces/props/CommonProps";

export class DropDown extends React.Component<DropDownProps> {
    constructor(props: DropDownProps) {
        super(props);
    }

    render() {
        return (
            <Select value={this.props.commonProps.defaultValue} onChange={this.props.commonProps.onSelect}
                    label={this.props.commonProps.label}
                    prefix={this.props.commonProps.prefix}
                    width={this.props.commonProps.width} size={this.props.size}
                    placeholder={this.props.commonProps.placeHolder}
                    disabled={this.props.commonProps.isDisabled}>
                {(() => {
                    const dropdownElements: JSX.Element[] = [];
                    for (let index = 0; index < this.props.commonProps.valueList.length; index++) {
                        dropdownElements.push(<SelectOption key={this.props.commonProps.valueList[index]}
                                                            value={this.props.commonProps.valueList[index]}
                                                            label={this.props.commonProps.labelList[index]}/>)
                    }
                    return (dropdownElements);
                })()
                }
            </Select>
        );
    }
}

export default DropDown;

interface DropDownProps {
    commonProps: DropDownCommonProps,
    size: SelectSize,
}
