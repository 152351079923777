import React from "react";
import Row from "@amzn/meridian/row";
import SearchField from "@amzn/meridian/search-field"
import orderBy from "lodash/orderBy"
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon"
import Badge from "@amzn/meridian/badge"
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large"
import Pagination from "@amzn/meridian/pagination"
import Table, {TableCell, TableRow} from "@amzn/meridian/table"
import SmallText from "src/components/common/text/SmallText"
import {searchQuery} from "src/helpers/audit-execution-artifacts/ExecutionArtifactsHelpers";
import {ExecutionArtifactsTableStates, SortState} from "src/interfaces/states/ExecutionArtifactsScreenStates";
import ExecutionArtifactsAuditAction from "src/components/screens/audit-execution-artifacts/ExecutionArtifactsAuditAction";
import ExecutionArtifactsSelfieImage from "src/components/screens/audit-execution-artifacts/ExecutionArtifactsSelfieImage";
import {ExecutionArtifactsTableProps, rowObject} from "src/interfaces/props/ExecutionArtifactsProps";
import {
    EMPTY_STRING,
    ASCENDING,
    ASC,
    DESC,
    NO_DATA,
    TABLE_ALIGNMENT,
    SERIAL_NUMBER
} from "src/constants/ApplicationCommonConstants";
import {AUDIT_STATUS_MAPPING} from "src/constants/ExecutionArtifactsApiConstants";
import {CSVLink} from "react-csv";

export class ExecutionArtifactsTable extends React.Component<ExecutionArtifactsTableProps, ExecutionArtifactsTableStates> {

    constructor(props: ExecutionArtifactsTableProps) {
        super(props);
        this.sortTable = this.sortTable.bind(this);
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.submitSearchQuery = this.submitSearchQuery.bind(this);
        this.clearSearchQuery = this.clearSearchQuery.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.removeRecordAfterGettingAudited = this.removeRecordAfterGettingAudited.bind(this);
        this.getAuditRecordToDownloadableFormatMapping = this.getAuditRecordToDownloadableFormatMapping.bind(this);

        this.state = {
            resultData: this.props.result,
            sortColumn: SERIAL_NUMBER,
            sortDirection: ASCENDING,
            searchValue: EMPTY_STRING,
            currentPage: 1
        }
    }

    sortTable(sortState: SortState) {
        this.setState(sortState);
    }

    changeSearchValue(value: string) {
        this.setState({searchValue: value});
    }

    submitSearchQuery(event: boolean) {
        if (this.state.searchValue == EMPTY_STRING) {
            this.setState({resultData: this.props.result})
        } else {
            let queryResults = [] as rowObject[];
            for (let index = 0; index < this.props.result.length; index++) {
                if (searchQuery(this.props.result[index], this.state.searchValue)) {
                    queryResults.push(this.props.result[index]);
                }
            }
            this.setState({resultData: queryResults})
            this.setState({currentPage: 1})
        }
    }

    clearSearchQuery(event: boolean) {
        this.setState({resultData: this.props.result})
        this.setState({currentPage: 1})
    }

    getAuditRecordToDownloadableFormatMapping() {
        return this.props.result.map(auditRecord =>
                ({appointmentId: auditRecord.appointmentId, jobId: auditRecord.jobId, orderId: auditRecord.orderId,
                merchantId: auditRecord.merchantId, technicianId: auditRecord.technicianId,
                referenceScore: auditRecord.referenceScore, similarityScore: auditRecord.similarityScore,
                caseId: auditRecord.caseId, auditStatus: auditRecord.auditStatus, lastUpdatedBy: auditRecord.lastUpdatedBy}))
    }

    setCurrentPage(event: number) {
        this.setState({currentPage: event})
    }

    removeRecordAfterGettingAudited(item: rowObject) {
        const auditRecords = this.state.resultData;
        auditRecords.splice(auditRecords.indexOf(item), 1);
        this.setState({resultData: auditRecords});
    }

    render() {

        const itemsPerPage = 20;
        const numberOfPages = Math.ceil(this.state.resultData.length / itemsPerPage);
        const startIndex = (this.state.currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage
        let serialNumber = startIndex;

        const sortedResult = orderBy(
            this.state.resultData,
            [this.state.sortColumn],
            [this.state.sortDirection === ASCENDING ? ASC : DESC]
        )

        return (
            <div>
                <Row width="100%" widths={["grid-4", "grid-4", "grid-4"]} spacing="large" spacingInset="medium">
                    <CSVLink filename={"SelfieAuditRecords.csv"} data={this.getAuditRecordToDownloadableFormatMapping()}>
                        <Heading level={4}> {"Export Records  "} <Icon tokens={downloadLargeTokens} /> </Heading>
                    </CSVLink>
                    <Heading level={4}> {"Total Records"} <Badge value={this.state.resultData.length} type="theme" /> </Heading>
                    <SearchField
                        value={this.state.searchValue}
                        onChange={this.changeSearchValue}
                        onSubmit={this.submitSearchQuery}
                        onClear={this.clearSearchQuery}
                        size="small"
                        placeholder="Enter data to search in table"
                    />
                </Row>

                <div style={{overflowX: "auto", maxWidth: "100%"}}>
                    <Table headerRows={1} showDividers={true} sortColumn={this.state.sortColumn}
                           sortDirection={this.state.sortDirection} onSort={this.sortTable}>
                        <TableRow highlightOnHover={true}>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="sNo">S.No.</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="appointmentId">Appointment Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="jobId">Job Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="orderId">Order Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="merchantId">Merchant Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="technicianId">Technician Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Profile Picture</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Audited Selfie</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Reference Selfie</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Reference Selfie</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="referenceScore">Reference Score</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT} sortColumn="similarityScore">Similarity Score</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Case Id</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Audit Status</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Last Updated By</TableCell>
                            <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>Audit Action</TableCell>

                        </TableRow>
                        {
                            sortedResult.slice(startIndex, endIndex)
                                .map((row: rowObject) => (<TableRow highlightOnHover={true}>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{++serialNumber}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.appointmentId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.jobId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.orderId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.merchantId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.technicianId}</TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <ExecutionArtifactsSelfieImage text="Profile Picture" image={row.profilePicUrl} profileImage={row.profilePicUrl}/>
                                    </TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <ExecutionArtifactsSelfieImage text="Audited Selfie" image={row.auditedSelfieUrl} profileImage={row.profilePicUrl}/>
                                    </TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <ExecutionArtifactsSelfieImage text="Reference Selfie" image={row.referenceSelfie1Url} profileImage={row.profilePicUrl}/>
                                    </TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        <ExecutionArtifactsSelfieImage text="Reference Selfie" image={row.referenceSelfie2Url} profileImage={row.profilePicUrl}/>
                                    </TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.referenceScore}</TableCell>

                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        {(() => {
                                            if (row.similarityScore == null) {
                                                return (
                                                    <SmallText text={NO_DATA} />
                                                );
                                            } else return (row.similarityScore);
                                        })()
                                        }
                                    </TableCell>

                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>
                                        {(() => {
                                            if (row.caseUrl == null) {
                                                return (
                                                    <SmallText text={NO_DATA} />
                                                );
                                            } else return (row.caseId);
                                        })()
                                        }
                                    </TableCell>

                                    <TableCell
                                        alignmentHorizontal={TABLE_ALIGNMENT}> {AUDIT_STATUS_MAPPING[row.auditStatus]} </TableCell>
                                    <TableCell alignmentHorizontal={TABLE_ALIGNMENT}>{row.lastUpdatedBy}</TableCell>

                                    <TableCell>
                                        {(() => {
                                            return (
                                                <ExecutionArtifactsAuditAction
                                                    auditRecord={row}
                                                    marketplaceId={this.props.marketplaceId}
                                                    username={this.props.username}
                                                    dateValue={this.props.dateValue}
                                                    removeRecordAfterGettingAudited={this.removeRecordAfterGettingAudited}
                                                />
                                            );
                                        })()
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </Table>
                    <Pagination
                        showSkipArrows={true}
                        numberOfPages={numberOfPages}
                        onChange={this.setCurrentPage}
                        currentPage={this.state.currentPage}
                    />
                </div>

            </div>
        );
    }
}

export default ExecutionArtifactsTable;
