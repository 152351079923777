import React from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import { EMPTY_STRING } from "src/constants/ApplicationCommonConstants";
import MediumInput from "src/components/common/input/MediumInput";
import { JobExecutionImagesInputProps } from "src/interfaces/props/JobExecutionImagesProps";
import { JobExecutionImagesInputState } from "src/interfaces/states/JobExecutionImagesStates";

export class JobExecutionImagesInput extends React.Component<
	JobExecutionImagesInputProps,
	JobExecutionImagesInputState
> {
	constructor(props: JobExecutionImagesInputProps) {
		super(props);
		this.setJobId = this.setJobId.bind(this);
		this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);

		this.state = {
			jobIdValue: EMPTY_STRING
		};
	}

	setJobId(event: string) {
		this.setState({ jobIdValue: event });
	}

	onSubmitButtonClick(event: boolean) {
		this.props.onButtonClick(this.state.jobIdValue);
	}

	render() {
		return (
			<Row width="100%" widths={["grid-6", "grid-2"]} spacing="large">
				<MediumInput
					value={this.state.jobIdValue}
					onChange={this.setJobId}
					type={"text"}
					placeholder={"Enter value..."}
					prefix={"Job-Id"}
					id={"jobIdOfTechnicianAssignTrail"}
				/>
				<Button onClick={this.onSubmitButtonClick} type="primary" size="medium">
					{" "}
					Submit{" "}
				</Button>
			</Row>
		);
	}
}

export default JobExecutionImagesInput;
