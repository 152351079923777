import React from "react";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import LargeText from "src/components/common/text/LargeText";
import SmallText from "src/components/common/text/SmallText";
import {fetchExecutionArtifactsAuditData} from "src/helpers/audit-execution-artifacts/ExecutionArtifactsApis";
import {deserializeAuditData} from "src/helpers/audit-execution-artifacts/ExecutionArtifactsHelpers";
import {DataFetchState} from "src/interfaces/CommonTypes";
import {ExecutionArtifactsScreenStates} from "src/interfaces/states/ExecutionArtifactsScreenStates";
import {ExecutionArtifactsScreenProps, rowObject} from "src/interfaces/props/ExecutionArtifactsProps";
import ExecutionArtifactsResponse from "src/components/screens/audit-execution-artifacts/ExecutionArtifactsResponse";
import ExecutionArtifactsInput from "src/components/screens/audit-execution-artifacts/ExecutionArtifactsInput";
import {
    EMPTY_STRING,
    EU_AMAZON,
    MARKETPLACE_EU_AMAZON_BETA_VALUE_LIST,
    MARKETPLACE_EU_AMAZON_PROD_VALUE_LIST,
    MARKETPLACE_US_AMAZON_VALUE_LIST
} from "src/constants/ApplicationCommonConstants";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";

export class ExecutionArtifactsScreen extends React.Component<ExecutionArtifactsScreenProps, ExecutionArtifactsScreenStates> {

    constructor(props: ExecutionArtifactsScreenProps) {
        super(props);
        this.validateInputs = this.validateInputs.bind(this);
        this.getExecutionArtifactsAuditList = this.getExecutionArtifactsAuditList.bind(this);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.updateStateParameters = this.updateStateParameters.bind(this);
        this.isValidMarketplaceValue = this.isValidMarketplaceValue.bind(this);
        this.getExecutionArtifactsAuditListBatch = this.getExecutionArtifactsAuditListBatch.bind(this);

        this.state = {
            resultData: EMPTY_STRING,
            marketplaceValue: EMPTY_STRING,
            auditTypeValue: EMPTY_STRING,
            auditStatusValue: EMPTY_STRING,
            dateValue: EMPTY_STRING,
            auditDataFetchState: DataFetchState.RequestNotMade,
            resultDataArray: [],
            inputValidationStatus: false,
            nextPageOffset: this.props.nextPageOffset
        };
    }

    updateStateParameters(marketplace: string, auditType: string, auditStatus: string, date: string) {
        this.setState({marketplaceValue: marketplace});
        this.setState({auditTypeValue: auditType});
        this.setState({auditStatusValue: auditStatus});
        this.setState({dateValue: date});
        this.setState({resultData: EMPTY_STRING});
        this.setState({resultDataArray: []});
    }

    isValidMarketplaceValue(marketplace: string) {
        if(this.props.screenInput.realmValue == EU_AMAZON) {
            return MARKETPLACE_EU_AMAZON_PROD_VALUE_LIST.includes(marketplace) || MARKETPLACE_EU_AMAZON_BETA_VALUE_LIST.includes(marketplace);
        }
        else {
            return MARKETPLACE_US_AMAZON_VALUE_LIST.includes(marketplace);
        }
    }

    validateInputs(marketplace: string, auditType: string, auditStatus: string, date: string) {
        if (!this.isValidMarketplaceValue(marketplace) || auditType == EMPTY_STRING || auditStatus == EMPTY_STRING || date == undefined) {
            this.setState({inputValidationStatus: false});
            return false;
        } else {
            this.setState({inputValidationStatus: true});
            return true;
        }
    };

    concatenateAuditRecords(newAuditData: rowObject[]) {
        return this.state.resultDataArray.concat(newAuditData)
    }

    async getExecutionArtifactsAuditList(userLogin: string, payload: string, offset: string) {
        this.setState({auditDataFetchState: DataFetchState.FetchingData});
        let response;
        try {
            response = await fetchExecutionArtifactsAuditData(userLogin, payload, offset);
            if (response.ResponsePayload == null && response.ValidUser == true) {
                this.setState({auditDataFetchState: DataFetchState.NoDataFound});
            } else if (response.ResponsePayload == null && response.ValidUser == false) {
                this.setState({auditDataFetchState: DataFetchState.UnauthorizedUser});
            } else {
                this.setState({resultData: response.ResponsePayload});
                this.setState({nextPageOffset: response.Offset});
                this.setState({resultDataArray: this.concatenateAuditRecords(deserializeAuditData(this.state.resultData))});
            }
            return response;
        } catch (error) {
            this.setState({auditDataFetchState: DataFetchState.DataFetchFailed});
        }
    }

    getExecutionArtifactsAuditListBatch(marketplace: string, auditType: string, auditStatus: string, date: string) {
        if(this.state.nextPageOffset != null) {
            this.getExecutionArtifactsAuditList(this.props.screenInput.username,
                JSON.stringify(createAPICallPayloadObject(this.props.screenInput.username,
                    marketplace, auditType, auditStatus, date)), this.state.nextPageOffset)
                .then(Response => {
                    if(this.state.auditDataFetchState == DataFetchState.FetchingData) {
                        this.getExecutionArtifactsAuditListBatch(marketplace, auditType, auditStatus, date);
                    }
                });
        }
        else {
            this.setState({auditDataFetchState: DataFetchState.DataFetchedSuccessfully});
            this.setState({nextPageOffset: EMPTY_STRING});
        }
    }

    onSubmitButtonClick(marketplace: string, auditType: string, auditStatus: string, date: string) {
        this.updateStateParameters(marketplace, auditType, auditStatus, date);
        if (this.validateInputs(marketplace, auditType, auditStatus, date)) {
            this.getExecutionArtifactsAuditListBatch(marketplace, auditType, auditStatus, date);
        } else {
            this.setState({auditDataFetchState: DataFetchState.InvalidRequestMade});
        }
    }

    render() {
        const tableComponentInput = createExecutionArtifactsTableProps(this.state.resultDataArray,
            this.props.screenInput.username, this.state.dateValue, this.state.marketplaceValue);

        return(
            <div id="ExecutionArtifactsScreen">
                <div id="ParameterSelectionSection">
                    <Column alignmentHorizontal="left" spacingInset="xlarge" spacing="large">
                        <LargeText text={ApplicationScreenConstants.AUDIT_EXECUTION_ARTIFACTS.SCREEN_NAME} />
                        <SmallText text={ApplicationScreenConstants.AUDIT_EXECUTION_ARTIFACTS.SCREEN_INTRO} />
                        <ExecutionArtifactsInput
                            onButtonClick={this.onSubmitButtonClick}
                            realmValue={this.props.screenInput.realmValue}
                            stage={this.props.screenInput.stage}
                        />
                        <Divider size="small"/>
                    </Column>
                </div>

                <div id="ResponseSection">
                    <ExecutionArtifactsResponse
                        auditDataFetchState={this.state.auditDataFetchState}
                        tableInput={tableComponentInput}
                    />
                </div>
            </div>
        );
    }
}
export default ExecutionArtifactsScreen;

function createAPICallPayloadObject(username: string, marketplace: string, auditType: string, auditStatus: string, date: string) {
    let payloadObject = {
        UserLogin: username,
        MarketplaceId: marketplace,
        AuditType: auditType,
        AuditStatus: auditStatus,
        Date: date
    }
    return payloadObject;
}

function createExecutionArtifactsTableProps(resultArray: rowObject[], userName: string, dateValue: string, marketplaceId: string) {
    let executionArtifactsTableProps = {
        result: resultArray,
        username: userName,
        dateValue: dateValue,
        marketplaceId: marketplaceId
    }
    return executionArtifactsTableProps;
}