export interface SearchContactSupportCaseAPIInput {
    caseId?: string,
    assignedTo?: string,
    startDate?: number,
    endDate?: number,
    caseType?: string,
    caseStatus?: string,
    count?: string,
    entitiesToSearch?: ContactSupportCaseEntities [],
    offset?: string
}

export interface ContactSupportCase {
    caseId: string,
    createTimeStamp: number,
    encryptedUserId: string,
    encryptedSupervisorId: string,
    caseStatus: string,
    phoneNumber?: string,
    supportType: string,
    assignedTo?: string,
    versionNumber?: number,
    customNotes: {
        customNotes?: string,
        versionNumber?: number
    }
}

export enum ContactSupportCaseEntities {
    CASE_DETAILS = "caseDetails",
    CUSTOM_NOTES_DETAILS = "customNotesDetails"
}