import React from "react";
import Column from "@amzn/meridian/column";
import InformalAlert from "src/components/common/alert/InformalAlert";

export class NoDataFoundMessage extends React.Component {
    render() {
        const NO_DATA_FOUND_MESSAGE = "Uh-oh, No data found."
        return(
            <div id="NoDataFoundMessage">
                <Column alignmentHorizontal="center">
                    <InformalAlert text={NO_DATA_FOUND_MESSAGE} />
                </Column>
            </div>
        );
    }
}
export default NoDataFoundMessage;