export enum StatusResponse {
	NO_PHOTOS_FOUND = "NO_PHOTOS_FOUND",
	DENIED_TO_CAPTURE_PHOTOS = "DENIED_TO_CAPTURE_PHOTOS",
	DENIED_TO_SAVE_PHOTOS = "DENIED_TO_SAVE_PHOTOS",
	FETCHED_PHOTOS = "FETCHED_PHOTOS",
	FAILURE = "FAILURE"
}

export interface GetJobExecutionImagesInput {
	jobId: string;
}

export interface GetJobExecutionImagesOutput extends Array<GetJobExecutionImagesOutputItem> {}

export interface GetJobExecutionImagesOutputItem {
	statusResponse: StatusResponse;
	imageURL?: string;
}
