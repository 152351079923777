export const apiCallParameters = {
    SEARCH_TECHNICIANS: "searchTechnicians",
    LOAD_TECHNICIAN_DETAILS: "loadTechnicianDetails",
    UPDATE_TECHNICIAN: "updateTechnician",
    TECHNICIAN_PROFILE_EDIT_API_NAME: "TechnicianProfileEdit",
    TECHNICIAN_PROFILE_EDIT_LAMBDA: "TechnicianProfileEditLambda"
}
export const TECHNICIAN_PROFILE_EDIT_TOOL = "VASTechnicianProfileEditAuthZ"
export const TECHNICIAN_ID = "technicianId"
export const SEARCH_TYPE_LABEL_LIST = ["By Merchant", "By Technician"];
export const SEARCH_TYPE_VALUE_LIST = ["By Merchant", "By Technician"];
export const FAILED = "Failed"
export const BY_MERCHANT="By Merchant"
export const MARKETPLACE_US_LIST = ["ATVPDKIKX0DER", "A2EUQ1WTGCTBG2"]
export const OFFSET_ZERO = "0"
export const technicianDetailsAttributeConstant = {
    DATE_CREATED: "Date Created",
    EMAIL_ADDRESS: "Email Address",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    MOBILE_NUMBER: "Mobile Number",
    STATUS: "Status",
    PROFILE_STATUS: "Profile Status",
    FILE_UPLOADED: "File Uploaded",
    PROFILE_BASICS: "Profile Basics",
    GOVERNMENT_ID: "Government ID",
    PROFILE_PHOTO: "Profile Photo",
    CHECK_TYPE: "Check Type",
    LAST_CHECK: "Last Check",
    BACKGROUND_CHECK: "Background Check",
    PHOTO: "Photo",
    IMAGE: "Image"
}