import React from "react";
import {TechnicianProfileDisablButtonModalProps} from "src/interfaces/props/TechnicianProfileEditProps";
import {ModalState} from "src/interfaces/states/CommonStates";
import Toggle from "@amzn/meridian/toggle";
import Row from "@amzn/meridian/row";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import Button from "@amzn/meridian/button";
import WarningAlert from "src/components/common/alert/WarningAlert";

export class TechnicianProfileDisabledButtonModal extends React.Component<TechnicianProfileDisablButtonModalProps, ModalState> {
    constructor(props: TechnicianProfileDisablButtonModalProps) {
        super(props);

        this.state = {
            openModal: false
        }
        this.onTechnicianDisableButtonClick = this.onTechnicianDisableButtonClick.bind(this);
        this.onModalContinueButtonClick = this.onModalContinueButtonClick.bind(this);
        this.onModalCancelButtonClick = this.onModalCancelButtonClick.bind(this);
    }

    onTechnicianDisableButtonClick() {
        if (!this.props.isProfileDisabledButtonFlag) {
            this.setState({openModal: true})
        }
        this.props.onToggleButtonClick();
    }

    onModalContinueButtonClick() {
        this.setState({openModal: false})
    }

    onModalCancelButtonClick() {
        this.setState({openModal: false})
        this.props.onToggleButtonClick();
    }

    render() {
        return (
            <div>
                <Toggle checked={!this.props.isProfileDisabledButtonFlag} onChange={this.onTechnicianDisableButtonClick}> {this.props.profileStatus} </Toggle>
                <Modal title={"Are you sure?"} open={this.state.openModal} scrollContainer="viewport" bodySpacingInset="medium" onClose={this.onModalCancelButtonClick}>
                    <WarningAlert text={"Once the technician is disabled, a technician cannot be enabled back. Change will not be saved until click on the Save button."}/>
                    <ModalFooter>
                        <Row alignmentHorizontal="center" widths={["grid-6", "grid-6"]} spacing="large">
                            <Button type="primary" size="small" onClick={this.onModalContinueButtonClick}> Continue </Button>
                            <Button type="secondary" size="small" onClick={this.onModalCancelButtonClick}> Cancel </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}