import React from "react";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import SmallText from "src/components/common/text/SmallText";
import StringUtils from "src/utils/StringUtils";

export class FetchingDataMessage extends React.Component<FetchingDataMessageProps> {

    constructor(props: FetchingDataMessageProps) {
        super(props);
    }

    render() {
        const FETCHING_DATA_MESSAGE = "Please wait. Working on your request.";
        return (
            <div id="FetchingDataMessage" hidden={this.props.isHidden === undefined ? false : this.props.isHidden}>
                <Column alignmentHorizontal="center">
                    <Loader type="linear" size="large"/>
                    <SmallText
                        text={StringUtils.isEmpty(this.props.message) ? FETCHING_DATA_MESSAGE :
                            this.props.message as string}/>
                </Column>
            </div>
        );
    }
}

export default FetchingDataMessage;

export interface FetchingDataMessageProps {
    message?: string
    isHidden?: boolean
}