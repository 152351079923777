import Modal from "@amzn/meridian/modal";
import React from "react";
import { JobExecutionImagesGridProps } from "src/interfaces/props/JobExecutionImagesProps";
import { JobExecutionImagesGridState } from "src/interfaces/states/JobExecutionImagesStates";

export default class JobExecutionImageGrid extends React.Component<
	JobExecutionImagesGridProps,
	JobExecutionImagesGridState
> {
	constructor(props: JobExecutionImagesGridProps) {
		super(props);

		this.state = {
			modal: {
				open: false,
				imageURL: ""
			}
		};
	}

	onClickImage = (event: React.SyntheticEvent) => {
		if (!(event.target instanceof HTMLImageElement)) {
			return;
		}
		this.setState({
			modal: {
				open: true,
				imageURL: event.target.getAttribute("src") || ""
			}
		});
	};

	onClose = () => this.setState({ modal: { open: false, imageURL: "" } });

	render() {
		return (
			<React.Fragment>
				<div
					id="JobExecutionImagesGrid"
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
						gap: "24px"
					}}
					onClick={(event) => this.onClickImage(event)}>
					{this.props.images.map((item, index) => (
						<img
							src={item.imageURL}
							key={index}
							alt="Job Execution Image"
							style={{
								height: "198px",
								width: "100%",
								objectFit: "cover",
								border: "1px solid #BBC0C1",
								background:
									"transparent url(https://m.media-amazon.com/images/G/01/vas/techapp/spinner._CB289032145_._SY200_.gif) center center no-repeat"
							}}
						/>
					))}
				</div>
				<Modal
					open={this.state.modal.open}
					onClose={this.onClose}
					closeLabel="Close"
					aria-describedby="job execution image pop up">
					<div style={{ width: "75vw", height: "75vh", margin: "auto" }}>
						<img
							style={{
								objectFit: "contain",
								width: "inherit",
								height: "inherit"
							}}
							src={this.state.modal.imageURL}
						/>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}