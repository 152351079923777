import React  from "react"
import {DataFetchState} from "src/interfaces/CommonTypes";
import DefaultStartScreenMessage from "src/components/common/message/DefaultStartScreenMessage";
import InvalidRequestMessage from "src/components/common/message/InvalidRequestMessage";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import NoDataFoundMessage from "src/components/common/message/NoDataFoundMessage";
import {ServiceProvidersAuditResponseProps} from "src/interfaces/props/ServiceProvidersAuditProps";
import ServiceProvidersAuditTable from "src/components/screens/service-providers-audit/ServiceProvidersAuditTable";
import UnauthorizedUserMessage from "src/components/common/message/UnauthorizedUserMessage";

export class ServiceProvidersAuditResponse extends React.Component<ServiceProvidersAuditResponseProps> {
    constructor(props: ServiceProvidersAuditResponseProps) {
        super(props);
    }
    render() {
        return (
            <div>
                { (() => {
                    switch (this.props.dataFetchState) {
                        case DataFetchState.RequestNotMade:
                            return (
                                <DefaultStartScreenMessage/>
                            );
                        case DataFetchState.InvalidRequestMade:
                            return (
                                <InvalidRequestMessage/>
                            );
                        case DataFetchState.FetchingData:
                            return (
                                <FetchingDataMessage/>
                            );
                        case DataFetchState.NoDataFound:
                            return (
                                <NoDataFoundMessage/>
                            );
                        case DataFetchState.UnauthorizedUser:
                            return (
                                <UnauthorizedUserMessage/>
                            )
                        case DataFetchState.DataFetchedSuccessfully :
                            return (
                                <ServiceProvidersAuditTable resultData={this.props.resultData} userLogin={this.props.userLogin}/>
                            );
                    }})()
                }
            </div>
        );
    }
}
export default ServiceProvidersAuditResponse;