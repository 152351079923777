import React from "react"
import {InputProps} from "src/interfaces/props/CommonProps";
import Input from "@amzn/meridian/input";

export class MediumInput extends React.Component<InputProps> {

    constructor(props: InputProps) {
        super(props);
    }

    render() {
        return(
            <Input name={this.props.name} id={this.props.id} type={this.props.type}
                   size={"medium"}
                   value={this.props.value}
                   onChange={this.props.onChange}
                   placeholder={this.props.placeholder}
                   disabled={this.props.isDisabled}
                   prefix={this.props.prefix}
                   width={this.props.width}
            />
        );
    }
}

export default MediumInput;