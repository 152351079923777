import React from "react";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import DatePicker from "@amzn/meridian/date-picker";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import {ExecutionArtifactsInputStates} from "src/interfaces/states/ExecutionArtifactsScreenStates";
import {ExecutionArtifactsInputProps} from "src/interfaces/props/ExecutionArtifactsProps";
import MarketplaceValueDropdown from "src/components/common/dropdown/MarketplaceValueDropdown";
import AuditTypeDropdown from "src/components/screens/audit-execution-artifacts/input-dropdowns/AuditTypeDropdown";
import AuditStatusDropdown from "src/components/screens/audit-execution-artifacts/input-dropdowns/AuditStatusDropdown";

export class ExecutionArtifactsInput extends React.Component<ExecutionArtifactsInputProps, ExecutionArtifactsInputStates> {

    constructor(props: ExecutionArtifactsInputProps) {
        super(props);
        this.selectMarketplace = this.selectMarketplace.bind(this);
        this.selectAuditType = this.selectAuditType.bind(this);
        this.selectAuditStatus = this.selectAuditStatus.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.state = {
            marketplaceValue: EMPTY_STRING,
            auditTypeValue: EMPTY_STRING,
            auditStatusValue: EMPTY_STRING,
            dateValue: EMPTY_STRING
        };
    }

    selectMarketplace(event: string) {
        this.setState({marketplaceValue: event});
    }

    selectAuditType(event: string) {
        this.setState({auditTypeValue: event});
    }

    selectAuditStatus(event: string) {
        this.setState({auditStatusValue: event});
    }

    selectDate(event: string) {
        this.setState({dateValue: event});
    }

    onSubmitButtonClick(event: boolean) {
        this.props.onButtonClick(this.state.marketplaceValue,
            this.state.auditTypeValue, this.state.auditStatusValue, this.state.dateValue);
    }

    render() {
        return(
            <Row width="100%" widths={["grid-3", "grid-2", "grid-2", "grid-2", "grid-3"]} spacing="large">
                <DatePicker size="medium" value={this.state.dateValue} onChange={this.selectDate} label="Date"/>
                <MarketplaceValueDropdown
                    realmValue={this.props.realmValue}
                    stage={this.props.stage}
                    onSelect={this.selectMarketplace}
                    defaultValue={this.state.marketplaceValue}
                />
                <AuditTypeDropdown
                    onSelect={this.selectAuditType}
                    defaultValue={this.state.auditTypeValue}
                />
                <AuditStatusDropdown
                    onSelect={this.selectAuditStatus}
                    defaultValue={this.state.auditStatusValue}
                />
                <Button onClick={this.onSubmitButtonClick} type="secondary" size="large"> Submit </Button>
            </Row>
        );
    }
}
export default ExecutionArtifactsInput;