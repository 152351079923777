export const FEU_PORTAL = "FEU Portal";
export const EXECUTION_ARTIFACTS_AUDIT_LAMBDA = "ExecutionArtifactsAuditData";
export const EXECUTION_ARTIFACTS_AUDIT_API = "ExecutionArtifactsAuditData";
export const SERVICE_PROVIDERS_AUDIT_API = "ServiceProvidersAudit";
export const VAS_CONTACT_SUPPORT_CASE_API = "VASContactSupportData";
export const TECHNICIAN_ASSIGNMENT_TRAIL_API = "TechnicianAssignmentTrailData"
export const VAS_NOTIFICATION_SERVICE_API = "VasFeuPortalNotificationServiceData";
export const TECHNICIAN_PROFILE_EDIT_API_NAME = "TechnicianProfileEdit";
export const STAFFS_RATING_PROCESSOR_API_NAME = "StaffsRatingProcessor";
export const EU_AMAZON = "EUAmazon";
export const US_AMAZON = "USAmazon";
export const DEV = "DEV";
export const BETA = "BETA";
export const PROD = "PROD";
export const REALM_VALUE_LIST = ["EUAmazon", "USAmazon", "JPAmazon"]
export const REALM_LABEL_LIST = ["EUAmazon", "USAmazon", "JPAmazon"]

export const TABLE_ALIGNMENT = "center"
export const REALM = "Realm"
export const EMPTY_STRING = "";
export const NO_DATA = "-";
export const ASCENDING = "ascending";
export const DESCENDING = "descending";
export const ASC = "asc";
export const DESC = "desc";
export const APPOINTMENT_ID = "appointmentId";
export const UPDATE_TIME = "updateTime"
export const SERIAL_NUMBER = "sNo"
export const MIDWAY_REDIRECT_MESSAGE = "Midway authentication required.";
export const FOOTER_MESSAGE = "Confidential, for Amazon use only.";
export const COMMA_SEPARATOR = ",";
export const WHITESPACE_SEPARATOR = " ";
export const FALSE = "false";

export const AUDIT_STATUS = "Audit Status"
export const AUDIT_TYPE = "Audit Type"
export const MARKETPLACE = "Marketplace"
export const RESOURCE_TYPE = "Resource Type"
export const ENABLED = "Enabled";
export const DISABLED = "Disabled";
export const PROFILE_IMAGE = "PROFILE_IMAGE";
export const GOVT_ID = "GOVT_ID";
export const NOT_STARTED = "Not started";
export const MARKETPLACE_EU_AMAZON_LABEL_LIST = ["IN", "GB", "DE", "AE"]
export const MARKETPLACE_US_AMAZON_LABEL_LIST = ["US", "CA"]
export const MARKETPLACE_EU_AMAZON_BETA_VALUE_LIST = ["A2XZLSVIQ0F4JT", "A1PA6795UKMFR9", "A1F83G8C2ARO7P",
    "A34GYYCZVDBSIK"]
export const MARKETPLACE_EU_AMAZON_PROD_VALUE_LIST = ["A21TJRUUN4KGV", "A1F83G8C2ARO7P", "A1PA6795UKMFR9",
    "A2VIGQ35RCS4UG"]
export const MARKETPLACE_US_AMAZON_VALUE_LIST = ["ATVPDKIKX0DER", "A2EUQ1WTGCTBG2"]
export const AuthZ_API_NAME = "AuthZ_API"
export const commonApiCallParameters = {
    CONTENT_TYPE: "application/x-amz-json-1.1",
    IS_AUTHORIZED_API_PATH: "/isAuthorized",
    AuthZ_API_NAME: "AuthZ_API",
    EXECUTE_REQUEST: "/executeRequest"
}
/**
 * This is to be used when you are directly changing the URL in the location bar. Check the usage in
 * CreateOrUpdateContactSupportCaseScreen.tsx file. If you are using a standard link components of React/Amazon
 * React link <Link> or <SideLink> then it is not required.
 * Todo: Need to figure out a better way for this.
 */
export const BUTTON_URL_PREFIX = "/#";
// This format should be used for setting the date in the DatePicker.
export const DATE_PICKER_SETTER_FORMAT = "YYYY-MM-DD";
