import React from "react";
import Column from "@amzn/meridian/column";
import MediumText from "src/components/common/text/MediumText";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import SmallText from "src/components/common/text/SmallText";
import Textarea from "@amzn/meridian/textarea";
import {EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import StringUtils from "src/utils/StringUtils";
import {
    createOrUpdateContactSupportCase,
    searchContactSupportCases
} from "src/helpers/contact-support-case/ContactSupportCaseApis";
import ErrorAlert from "src/components/common/alert/ErrorAlert";
import {
    CASE_STATUS_LABEL_LIST,
    CASE_STATUS_VALUE_LIST,
    SUPPORT_CASE_TYPE_LABEL_LIST
} from "src/constants/ContactSupportCaseToolConstants";
import SmallInput from "src/components/common/input/SmallInput";
import {
    ContactSupportCase,
    ContactSupportCaseEntities,
    SearchContactSupportCaseAPIInput
} from "src/interfaces/props/ContactSupportCaseProps";
import FetchingDataMessage from "src/components/common/message/FetchingDataMessage";
import SuccessAlert from "src/components/common/alert/SuccessAlert";
import {ScreenProps} from "src/interfaces/props/CommonProps";
import MediumDropDown from "src/components/common/dropdown/MediumDropDown";

export class CreateOrUpdateContactSupportCaseScreen
    extends React.Component<CreateOrUpdateContactSupportCaseScreenProps, CreateOrUpdateContactSupportCaseScreenStates> {

    constructor(props: CreateOrUpdateContactSupportCaseScreenProps) {
        super(props);
        this.state = getDefaultState(props);
        this.selectSupportTypeValue = this.selectSupportTypeValue.bind(this);
        this.onChangeUserId = this.onChangeUserId.bind(this);
        this.onChangeAssignedToValue = this.onChangeAssignedToValue.bind(this);
        this.onChangeSupervisorIdValue = this.onChangeSupervisorIdValue.bind(this);
        this.onChangeCustomNotesValue = this.onChangeCustomNotesValue.bind(this);
        this.onCreateOrUpdateButtonClick = this.onCreateOrUpdateButtonClick.bind(this);
        this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
        this.selectCaseStatusTypeValue = this.selectCaseStatusTypeValue.bind(this);
        this.dismissErrorAlert = this.dismissErrorAlert.bind(this);
        this.dismissSuccessAlert = this.dismissSuccessAlert.bind(this);
    }

    async componentDidMount() {
        if (StringUtils.isNotEmpty(this.props.caseId)) {
            const payload = this.createSearchContactSupportPayload(this.props.caseId);

            const response = await searchContactSupportCases(this.props.screenInput.username, JSON.stringify(payload));
            this.handleSearchContactSupportCaseResponse(JSON.parse(response?.ResponsePayload))
        } else {
            this.setState({
                isCaseDetailsHidden: false,
                isFetchDataMessageHidden: true
            })
        }
    }

    handleSearchContactSupportCaseResponse(responsePayload: any) {
        this.setState({isFetchDataMessageHidden: true, isCaseDetailsHidden: false});

        if (responsePayload.success) {
            const searchResults = responsePayload.contactSupportCaseList as ContactSupportCase[];
            if (searchResults.length == 0) {
                this.showErrorMessage(`No case found with case Id: ${this.props.caseId}`);
            } else {
                const contactSupportCase: ContactSupportCase = searchResults[0];
                this.setContactSupportCaseOnComponentState(contactSupportCase);
            }
        } else {
            this.showErrorMessage(`Unable to fetch the Case Details for case Id: ${this.props.caseId}`)
            this.setState({
                isCaseDetailsHidden: true
            });
        }
    }

    setContactSupportCaseOnComponentState(contactSupportCase: ContactSupportCase) {
        this.setState({
            userId: contactSupportCase.encryptedUserId,
            supervisorId: contactSupportCase.encryptedSupervisorId,
            assignedToValue: contactSupportCase.assignedTo,
            phoneNumber: contactSupportCase.phoneNumber,
            supportTypeValue: contactSupportCase.supportType,
            caseStatus: contactSupportCase.caseStatus,
            caseDetailsVersionNumber: contactSupportCase.versionNumber,
            customNotesValue: this.getCustomNotesValueFromResponse(contactSupportCase),
            customNotesVersionNumber: contactSupportCase?.customNotes?.versionNumber,
            createTimeStamp: contactSupportCase.createTimeStamp
        });
    }

    createSearchContactSupportPayload(caseId?: string): SearchContactSupportCaseAPIInput {
        return {
            caseId,
            entitiesToSearch: [ContactSupportCaseEntities.CASE_DETAILS,
                ContactSupportCaseEntities.CUSTOM_NOTES_DETAILS]
        } as SearchContactSupportCaseAPIInput;
    }

    getCustomNotesValueFromResponse(contactSupportCase: ContactSupportCase): string {
        return contactSupportCase?.customNotes?.customNotes === undefined ? EMPTY_STRING :
            contactSupportCase?.customNotes?.customNotes as string;
    }

    selectSupportTypeValue(event: string) {
        this.setState({supportTypeValue: event});
    }

    selectCaseStatusTypeValue(event: string) {
        this.setState({caseStatus: event});
    }

    onChangeUserId(event: string) {
        this.setState({userId: event});
    }

    onChangeAssignedToValue(event: string) {
        this.setState({assignedToValue: event})
    }

    onChangeSupervisorIdValue(event: string) {
        this.setState({supervisorId: event})
    }

    onChangeCustomNotesValue(event: string) {
        this.setState({customNotesValue: event})
    }

    onChangePhoneNumber(event: string) {
        this.setState({phoneNumber: event});
    }

    async onCreateOrUpdateButtonClick(event: string) {
        const createOrUpdateContactSupportCaseInput = this.buildCreateOrUpdateContactSupportCasePayload();
        if (createOrUpdateContactSupportCaseInput !== undefined) {
            const createContactCaseInputPayload = JSON.stringify({
                contactSupportCase: createOrUpdateContactSupportCaseInput
            });
            try {
                const response = await createOrUpdateContactSupportCase(this.props.screenInput.username,
                    createContactCaseInputPayload);
                this.handleCreateOrUpdateContactSupportCaseResponse(JSON.parse(response?.ResponsePayload),
                    createOrUpdateContactSupportCaseInput);
            } catch (e) {
                this.showErrorMessage(
                    `Error while creating/updating the case Id with value : ${createOrUpdateContactSupportCaseInput.caseId}`);
            }
        }
    }

    handleCreateOrUpdateContactSupportCaseResponse(responsePayload: any, input: CreateOrUpdateCaseAPIInput) {
        if (responsePayload.success) {
            this.setState({
                showSuccessMessage: true,
                isCreateOrUpdateButtonDisabled: true,
                caseId: responsePayload.caseId,
                successMessage: `Case created/updated with case Id: ${responsePayload.caseId}`
            })
        } else {
            if (StringUtils.isEmpty(input.caseId)) {
                this.showErrorMessage("Not able to create the case with input details");
            } else {
                this.showErrorMessage(
                    `Not able to update the case with case Id: ${input.caseId}`);
            }
        }
    }

    buildCreateOrUpdateContactSupportCasePayload(): undefined | CreateOrUpdateCaseAPIInput {
        this.setState({showError: false, errorMessage: EMPTY_STRING});
        if (StringUtils.isEmpty(this.state.supervisorId) || StringUtils.isEmpty(this.state.userId) ||
            StringUtils.isEmpty(this.state.supportTypeValue)) {
            this.showErrorMessage(
                "One of the required input is not provided from Technician Id, Service Provider Id, Support Case Type. Please enter these values")
            return undefined;
        } else {
            return {
                assignedTo: this.state.assignedToValue,
                encryptedSupervisorId: this.state.supervisorId,
                supportType: this.state.supportTypeValue,
                encryptedUserId: this.state.userId,
                phoneNumber: this.state.phoneNumber,
                caseId: this.state.caseId,
                versionNumber: this.state.caseDetailsVersionNumber,
                caseStatus: StringUtils.isEmpty(this.state.caseStatus) ? "Pending Assignment" : this.state.caseStatus,
                createTimeStamp: this.state.createTimeStamp,
                customNotes: {
                    notes: this.state.customNotesValue,
                    versionNumber: this.state.customNotesVersionNumber
                }
            } as CreateOrUpdateCaseAPIInput;
        }
    }

    showErrorMessage(errorMessage: string) {
        this.setState({
            showError: true,
            errorMessage,
            showSuccessMessage: false,
            isCreateOrUpdateButtonDisabled: true
        });
    }

    dismissErrorAlert() {
        this.setState({
            showError: false,
            isCreateOrUpdateButtonDisabled: false,
        })
    }

    dismissSuccessAlert() {
        this.setState({
            showSuccessMessage: false
        });
        if (this.state.isUpdateCase) {
            window.location.reload();
        }
    }


    render() {
        const SCREEN_NAME = this.state.isUpdateCase ? "Update Contact Support Case" : "Create Contact Support Case";
        const BUTTON_TEXT = this.state.isUpdateCase ? "Update Case" : "Create Case";
        const IS_INPUT_FILED_DISABLED = this.state.isUpdateCase;
        return (
            <div id={"CreateOrUpdateContactSupportCaseScreen"}>
                <div id={"Title"}>
                    <Column alignmentHorizontal="center" spacingInset="large" spacing="large">
                        <MediumText text={SCREEN_NAME}/>
                    </Column>
                </div>
                <div hidden={!(this.state.showError || this.state.showSuccessMessage)}>
                    <Row alignmentHorizontal={"center"} spacingInset="large">
                        {(() => {
                            if (this.state.showError) {
                                return (
                                    <ErrorAlert text={this.state.errorMessage}
                                                onClose={this.dismissErrorAlert}
                                    />
                                );
                            }
                            if (this.state.showSuccessMessage) {
                                return (
                                    <SuccessAlert text={this.state.successMessage}
                                                  onClose={this.dismissSuccessAlert}/>
                                );
                            }
                        })()
                        }
                    </Row>
                </div>
                <FetchingDataMessage isHidden={this.state.isFetchDataMessageHidden}
                                     message={`Fetching the Information for caseId : ${this.props.caseId}`}/>

                <div id={"middle-container"} hidden={this.state.isCaseDetailsHidden}>
                    <Row alignmentHorizontal="center" spacingInset="large" spacing="small">
                        <Column>
                            <SmallText text={"Technician Id * "}/><SmallInput name={"userId"} id={"userId"}
                                                                              type={"text"}
                                                                              value={this.state.userId}
                                                                              onChange={this.onChangeUserId}
                                                                              isDisabled={IS_INPUT_FILED_DISABLED}/>
                            <SmallText text={"Service Provider Id * "}/><SmallInput name={"supervisorId"}
                                                                                    id={"supervisorId"}
                                                                                    type={"text"}
                                                                                    value={this.state.supervisorId}
                                                                                    isDisabled={IS_INPUT_FILED_DISABLED}
                                                                                    onChange={this.onChangeSupervisorIdValue}/>
                            <SmallText text={"Phone Number"}/><SmallInput name={"phoneNumber"} id={"phoneNumber"}
                                                                          type={"tel"}
                                                                          value={this.state.phoneNumber}
                                                                          onChange={this.onChangePhoneNumber}/>
                            <SmallText text={"Support Type * "}/><MediumDropDown key={"supportTypeDropDown"}
                                                                                 valueList={SUPPORT_CASE_TYPE_LABEL_LIST}
                                                                                 defaultValue={this.state.supportTypeValue}
                                                                                 labelList={SUPPORT_CASE_TYPE_LABEL_LIST}
                                                                                 width={300}
                                                                                 isDisabled={IS_INPUT_FILED_DISABLED}
                                                                                 placeHolder={"Select the Support Type"}
                                                                                 onSelect={this.selectSupportTypeValue}/>
                            <SmallText text={"Case Status"}/><MediumDropDown key={"caseStatusTypeDropDown"}
                                                                             valueList={CASE_STATUS_VALUE_LIST}
                                                                             defaultValue={this.state.caseStatus}
                                                                             labelList={CASE_STATUS_LABEL_LIST}
                                                                             width={300}
                                                                             placeHolder={"Select the Case Status"}
                                                                             onSelect={this.selectCaseStatusTypeValue}/>
                            <SmallText text={"Assigned To"}/><SmallInput name={"assignedTo"} id={"assignedTo"}
                                                                         type={"text"}
                                                                         value={this.state.assignedToValue}
                                                                         onChange={this.onChangeAssignedToValue}/>
                            <SmallText text={"Notes You want to capture"}/>
                            <Textarea name={"customNotes"} id={"customNotes"} value={this.state.customNotesValue}
                                      onChange={this.onChangeCustomNotesValue}
                                      width={600} rows={10}
                                      resize={"auto"}/>
                        </Column>
                    </Row>
                    <>
                        <Row alignmentHorizontal="center" spacingInset="large" alignmentVertical="bottom"
                             spacing="small">
                            <Column>
                                <Button onClick={this.onCreateOrUpdateButtonClick}
                                        disabled={this.state.isCreateOrUpdateButtonDisabled}>
                                    {
                                        (() => {
                                            return (
                                                BUTTON_TEXT
                                            );
                                        })()
                                    }
                                </Button>
                            </Column>
                        </Row>
                    </>
                </div>
            </div>
        );
    }
}

interface CreateOrUpdateContactSupportCaseScreenStates {
    phoneNumber?: string,
    supportTypeValue: string,
    caseStatus: string,
    userId: string,
    supervisorId: string,
    assignedToValue?: string,
    customNotesValue: string,
    customNotesVersionNumber?: number
    errorMessage: string,
    showError: boolean,
    showSuccessMessage: boolean,
    successMessage: string,
    isFetchDataMessageHidden: boolean,
    isCaseDetailsHidden: boolean,
    isUpdateCase: boolean,
    caseId?: string,
    caseDetailsVersionNumber?: number,
    isCreateOrUpdateButtonDisabled?: boolean,
    createTimeStamp: number | undefined
}

export interface CreateOrUpdateContactSupportCaseScreenProps {
    caseId?: string,
    screenInput: ScreenProps
}

interface CreateOrUpdateCaseAPIInput {
    encryptedUserId: string,
    encryptedSupervisorId: string,
    supportType: "Amazon Helpline" | "Local Police" | "Service Provider Contact",
    caseStatus: "Pending Assignment" | "Work In Progress" | "Pending Service Provider Action" | "Resolved",
    assignedTo?: string,
    customNotes?: {
        notes?: string,
        versionNumber?: number
    },
    phoneNumber?: string,
    versionNumber?: number,
    caseId?: string,
    createTimeStamp: number | undefined
}

function getDefaultState(props: CreateOrUpdateContactSupportCaseScreenProps) {
    return {
        showSuccessMessage: false,
        successMessage: EMPTY_STRING,
        phoneNumber: EMPTY_STRING,
        supportTypeValue: EMPTY_STRING,
        userId: EMPTY_STRING,
        assignedToValue: EMPTY_STRING,
        supervisorId: EMPTY_STRING,
        customNotesValue: EMPTY_STRING,
        errorMessage: EMPTY_STRING,
        showError: false,
        isFetchDataMessageHidden: false,
        isCaseDetailsHidden: true,
        caseId: props.caseId,
        isUpdateCase: StringUtils.isNotEmpty(props.caseId),
        isCreateOrUpdateButtonDisabled: false,
        createTimeStamp: undefined
    } as CreateOrUpdateContactSupportCaseScreenStates;
}

export default CreateOrUpdateContactSupportCaseScreen;
