import {API} from "aws-amplify";
import {commonApiCallParameters} from "src/constants/ApplicationCommonConstants";
import {refreshMidway} from "src/components/authentication/MidwayTokenRetriever";

export const authorizeTool = async (username: string, tool: string) => {
    let authorizationResponse;
    const credentials = await refreshMidway();
    try {
        authorizationResponse = await API.get(commonApiCallParameters.AuthZ_API_NAME, commonApiCallParameters.IS_AUTHORIZED_API_PATH, {
                'queryStringParameters': {
                    'User': username,
                    'Tool': tool,
                    'Token': credentials.token,
                    'ClientId': location.host
                },
                headers: {
                    'Content-Type': commonApiCallParameters.CONTENT_TYPE
                }
            });
        return authorizationResponse;
    } catch (error) {
        throw error;
    }
}