import React from "react";
import {EU_AMAZON, US_AMAZON, DEV, BETA, PROD} from "src/constants/ApplicationCommonConstants";
import {MARKETPLACE, MARKETPLACE_EU_AMAZON_LABEL_LIST, MARKETPLACE_US_AMAZON_LABEL_LIST} from "src/constants/ApplicationCommonConstants";
import {MARKETPLACE_US_AMAZON_VALUE_LIST, MARKETPLACE_EU_AMAZON_BETA_VALUE_LIST, MARKETPLACE_EU_AMAZON_PROD_VALUE_LIST} from "src/constants/ApplicationCommonConstants";
import {MarketplaceValueProps} from "src/interfaces/props/CommonProps";
import XLargeDropDown from "src/components/common/dropdown/XLargeDropDown";

export class MarketplaceValueDropdown extends React.Component<MarketplaceValueProps> {

    constructor(props: MarketplaceValueProps) {
        super(props);
    }

    render() {
        if (this.props.realmValue == EU_AMAZON && ((this.props.stage == DEV) || (this.props.stage == BETA))) {
            return (
                <XLargeDropDown
                    defaultValue={this.props.defaultValue}
                    onSelect={this.props.onSelect}
                    label={MARKETPLACE}
                    valueList={MARKETPLACE_EU_AMAZON_BETA_VALUE_LIST}
                    labelList={MARKETPLACE_EU_AMAZON_LABEL_LIST}
                    isDisabled={this.props.disabled}
                />
            );
        } else if (this.props.realmValue == EU_AMAZON && this.props.stage == PROD) {
            return (
                <XLargeDropDown
                    defaultValue={this.props.defaultValue}
                    onSelect={this.props.onSelect}
                    label={MARKETPLACE}
                    valueList={MARKETPLACE_EU_AMAZON_PROD_VALUE_LIST}
                    labelList={MARKETPLACE_EU_AMAZON_LABEL_LIST}
                    isDisabled={this.props.disabled}
                />
            );
        } else if (this.props.realmValue == US_AMAZON) {
            return (
                <XLargeDropDown
                    defaultValue={this.props.defaultValue}
                    onSelect={this.props.onSelect}
                    label={MARKETPLACE}
                    valueList={MARKETPLACE_US_AMAZON_VALUE_LIST}
                    labelList={MARKETPLACE_US_AMAZON_LABEL_LIST}
                    isDisabled={this.props.disabled}
                />
            );
        }
    }
}
export default MarketplaceValueDropdown;