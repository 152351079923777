import {BUTTON_URL_PREFIX, DATE_PICKER_SETTER_FORMAT, EMPTY_STRING} from "src/constants/ApplicationCommonConstants";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import StringUtils from "src/utils/StringUtils";
import queryString from 'querystring';
import moment from "moment";
import {END_DATE_OFFSET, START_DATE_OFFSET} from "src/constants/ContactSupportCaseToolConstants";

export const navigateToCreateOrUpdateContactSupportCasePage = (caseId: string) => {
    if (StringUtils.isEmpty(caseId)) {
        window.location.assign(
            `${BUTTON_URL_PREFIX}${ApplicationScreenConstants.CONTACT_SUPPORT_CASES.CREATE_OR_UPDATE_CASE_URL}`);
    } else {
        window.location.assign(
            `${BUTTON_URL_PREFIX}${ApplicationScreenConstants.CONTACT_SUPPORT_CASES.CREATE_OR_UPDATE_CASE_URL}?caseId=${caseId}`);
    }
}

export const getCaseIdFromURL = (url: string): string => {
    const caseId = queryString.parse(url.split("?")[1])?.caseId as string;
    return StringUtils.isEmpty(caseId) ? EMPTY_STRING : caseId;

}

export const getDefaultStartDate = (): string => {
    return moment().subtract(START_DATE_OFFSET, 'day').format(DATE_PICKER_SETTER_FORMAT);
}

export const getDefaultEndDate = (): string => {
    return moment().add(END_DATE_OFFSET, 'day').format(DATE_PICKER_SETTER_FORMAT);
}