import React from 'react';
import SideMenu, {SideMenuLink} from "@amzn/meridian/side-menu"
import {useHistory} from "react-router-dom"
import SmallText from "src/components/common/text/SmallText";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import {BooleanValueProps} from "src/interfaces/props/CommonProps";

function ApplicationMenu(props: BooleanValueProps) {

    const history = useHistory()
    return (
        <SideMenu open={props.input}>
            <SideMenuLink href={ApplicationScreenConstants.HOME.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.HOME.SCREEN_NAME} />
            </SideMenuLink>
            <SideMenuLink href={ApplicationScreenConstants.AUDIT_EXECUTION_ARTIFACTS.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.AUDIT_EXECUTION_ARTIFACTS.SCREEN_NAME} />
            </SideMenuLink>
            <SideMenuLink href={ApplicationScreenConstants.SERVICE_PROVIDERS_AUDIT.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.SERVICE_PROVIDERS_AUDIT.SCREEN_NAME} />
            </SideMenuLink>
            <SideMenuLink href={ApplicationScreenConstants.CONTACT_SUPPORT_CASES.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.CONTACT_SUPPORT_CASES.SCREEN_NAME} />
            </SideMenuLink>
            <SideMenuLink href={ApplicationScreenConstants.TECHNICIAN_ASSIGNMENT_TRAIL.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.TECHNICIAN_ASSIGNMENT_TRAIL.SCREEN_NAME} />
            </SideMenuLink>
            <SideMenuLink href={ApplicationScreenConstants.TECHNICIAN_PROFILE_EDIT.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.TECHNICIAN_PROFILE_EDIT.SCREEN_NAME} />
            </SideMenuLink>
            <SideMenuLink href={ApplicationScreenConstants.SEND_BULK_NOTIFICATIONS.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.SEND_BULK_NOTIFICATIONS.SCREEN_NAME} />
            </SideMenuLink>
            <SideMenuLink href={ApplicationScreenConstants.STAFF_RATING_FILE_UPLOAD.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.STAFF_RATING_FILE_UPLOAD.SCREEN_NAME} />
            </SideMenuLink>
            <SideMenuLink href={ApplicationScreenConstants.JOB_EXECUTION_IMAGES.URL} onClick={history.push}>
                <SmallText text={ApplicationScreenConstants.JOB_EXECUTION_IMAGES.SCREEN_NAME} />
            </SideMenuLink>
        </SideMenu>
    )
}

export default ApplicationMenu;