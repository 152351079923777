import React from "react";
import {StaffsRatingProcessorInputProps} from "src/interfaces/props/StaffsRatingProcessorProps";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import FileInput, {FileDetails} from "@amzn/meridian/file-input";
import {StaffsRatingProcessorInputStates} from "src/interfaces/states/StaffsRatingProcessorInputStates";

export class StaffsRatingProcessorInput extends
    React.Component<StaffsRatingProcessorInputProps, StaffsRatingProcessorInputStates> {

    constructor(props: StaffsRatingProcessorInputProps) {
        super(props);

        this.state = {
            file: []
        }

        this.onChooseFileButtonClick = this.onChooseFileButtonClick.bind(this)
        this.onDeleteFileButtonClick = this.onDeleteFileButtonClick.bind(this)
    }

    onChooseFileButtonClick(event: File[]) {
        this.setState({file: event})
        this.props.onChooseFileButtonClick(event)
    }

    onDeleteFileButtonClick() {
        this.setState({file: []})
        this.props.onDeleteFileButtonClick()
    }

    render() {
        return (
            <div>
                <Row width="100%" widths={["grid-6", "grid-6"]} spacing="large" alignmentVertical={"top"}>
                    <Column alignmentHorizontal="left" spacingInset="large" spacing="large">
                        <FileInput
                            onFileAttached={acceptedFile => this.onChooseFileButtonClick(acceptedFile)}
                            uploadButtonType={"primary"}
                            uploadButtonLabel= {"Choose File"}
                            accept={".csv"}
                        />
                        {(() => {
                            if (this.state.file.length) {
                                return <FileDetails
                                    file={this.state.file[0]}
                                    key={this.state.file[0].name}
                                    removeFileButtonLabel={`Delete, ${this.state.file[0].name}`}
                                    onClickRemoveFile={this.onDeleteFileButtonClick}
                                    uploadComplete={true}
                                />
                            }
                        })()
                        }
                    </Column>

                    <Column alignmentHorizontal="left" spacingInset="large" spacing="large">
                        <Button type={"primary"} onClick={this.props.onUploadButtonClick} disabled={this.props.isDisabledUploadButton}>
                            Upload
                        </Button>
                    </Column>
                </Row>
            </div>
        )
    }
}