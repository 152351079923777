import React from "react";
import Button from "@amzn/meridian/button";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import SmallText from "src/components/common/text/SmallText";
import Row from "@amzn/meridian/row";
import {ServiceProvidersAuditButtonsProps} from "src/interfaces/props/ServiceProvidersAuditProps"

export class ServiceProvidersAuditButtons extends React.Component<ServiceProvidersAuditButtonsProps> {
    constructor(props: ServiceProvidersAuditButtonsProps) {
        super(props);
    }
    render() {
        const CONFIRMATION_TEXT = "Click to confirm";
        return(
            <div>
                <Button onClick={this.props.onModalClick}
                        type={this.props.type} size="small"
                        minWidth={this.props.minWidth}
                        disabled={this.props.isDisabled}
                        children={this.props.buttonName}
                />
                <Modal title={this.props.title} open={this.props.openModal} onClose={this.props.onCancelClick} scrollContainer="viewport">
                    <SmallText text={CONFIRMATION_TEXT} />
                    <ModalFooter>
                        <Row alignmentHorizontal="center" widths={["grid-6", "grid-6"]} spacing="large">
                            <Button type="secondary" size="small" onClick={this.props.onCancelClick}> Cancel </Button>
                            <Button type="primary" size="small" onClick={this.props.onConfirmClick}> Confirm </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ServiceProvidersAuditButtons;