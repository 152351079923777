import React from 'react';
import Card, {CardHeader} from "@amzn/meridian/card";
import {CardComponentProps} from "src/interfaces/props/CommonProps";
import SmallText from "src/components/common/text/SmallText";
import Level3Heading from "src/components/common/heading/Level3Heading";

export class CardComponent extends React.Component<CardComponentProps> {

    constructor(props: CardComponentProps) {
        super(props);
    }

    render() {
        return (
            <Card href={this.props.URL} onClick={this.props.history} width={500}>
                <CardHeader>
                    <Level3Heading text={this.props.screenName}/>
                </CardHeader>
                <SmallText text={this.props.intro} />
            </Card>
        );
    }
}
export default CardComponent;