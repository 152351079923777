import React from "react";
import {serviceProvidersAuditData} from "src/helpers/service-providers-audit/ServiceProvidersAuditAPIs";
import {ServiceProvidersAuditInput} from "src/components/screens/service-providers-audit/ServiceProvidersAuditInput"
import Column from "@amzn/meridian/column";
import LargeText from "src/components/common/text/LargeText";
import {ApplicationScreenConstants} from "src/constants/ApplicationScreenConstants";
import SmallText from "src/components/common/text/SmallText";
import moment from "moment";
import Divider from "@amzn/meridian/divider";
import {
    EMPTY_STRING,
    EU_AMAZON,
    MARKETPLACE_EU_AMAZON_BETA_VALUE_LIST,
    MARKETPLACE_EU_AMAZON_PROD_VALUE_LIST, MARKETPLACE_US_AMAZON_VALUE_LIST
} from "src/constants/ApplicationCommonConstants";
import {DataFetchState} from "src/interfaces/CommonTypes";
import {deserializeData} from "src/helpers/service-providers-audit/ServiceProvidersAuditHelper";
import ServiceProvidersAuditResponse from "src/components/screens/service-providers-audit/ServiceProvidersAuditResponse";
import {apiCallParameters, DATE_FORMAT} from "src/constants/ServiceProvidersApiConstants";
import {ServiceProvidersAuditPageProps} from "src/interfaces/props/ServiceProvidersAuditProps"
import {ServiceProvidersAuditPageStates} from "src/interfaces/states/ServiceProviderAuditStates"

function createPayloadObject(resourceType: string, marketplace: string, auditType: string, auditStatus: string, date: string) {
    let payloadObject = {
        resourceType: resourceType,
        marketPlaceId: marketplace,
        auditType: auditType,
        auditStatus: auditStatus,
        date: date
    }
    return payloadObject;
}

export class ServiceProvidersAuditPage extends  React.Component<ServiceProvidersAuditPageProps,ServiceProvidersAuditPageStates> {

    constructor(props: ServiceProvidersAuditPageProps) {
        super(props);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.loadAuditData = this.loadAuditData.bind(this);
        this.state = {
            dataFetchState: DataFetchState.RequestNotMade,
            responsePayload: [],
            inputValidationStatus: false,
        }
    }
    validateInputs(resourceType: string , marketplace: string, auditType: string, auditStatus: string, date: string) {
        if (!this.isValidMarketplaceValue(marketplace) || auditType == EMPTY_STRING || auditStatus == EMPTY_STRING || date == undefined) {
            this.setState({inputValidationStatus: false});
            return false;
        } else {
            this.setState({inputValidationStatus: true});
            return true;
        }
    }

    isValidMarketplaceValue(marketplace: string) {
        if(this.props.screenInput.realmValue == EU_AMAZON && (this.props.screenInput.stage == "BETA" || this.props.screenInput.stage == "DEV") ){
            return MARKETPLACE_EU_AMAZON_BETA_VALUE_LIST.includes(marketplace);
        }
        else if(this.props.screenInput.realmValue == EU_AMAZON && this.props.screenInput.stage == "PROD") {
            return MARKETPLACE_EU_AMAZON_PROD_VALUE_LIST.includes(marketplace);
        }
        else {
            return MARKETPLACE_US_AMAZON_VALUE_LIST.includes(marketplace);
        }
    }

    async loadAuditData(userName: string, payload: string) {
        this.setState({dataFetchState: DataFetchState.FetchingData});
        let response;
        try {
            response = await serviceProvidersAuditData(apiCallParameters.LOAD_AUDIT_DATA, userName, payload);
            if (response.ResponsePayload == null && response.ValidUser == true) {
                this.setState({dataFetchState: DataFetchState.NoDataFound});
            } else if (response.ResponsePayload == null && response.ValidUser == false) {
                this.setState({dataFetchState: DataFetchState.UnauthorizedUser});
            } else {
                this.setState({responsePayload: deserializeData(response.ResponsePayload)});
                this.setState({dataFetchState: DataFetchState.DataFetchedSuccessfully});
            }
        } catch (error) {
            console.error("execute Request API call error", error);
        }
    }

    onSubmitButtonClick(resourceType: string, marketplace: string, auditType: string, auditStatus: string, date: string) {
        date =  moment(date).format(DATE_FORMAT);
        if (this.validateInputs(resourceType, marketplace, auditType, auditStatus, date)) {
            this.loadAuditData(this.props.screenInput.username,
                JSON.stringify(createPayloadObject(resourceType, marketplace, auditType, auditStatus, date)))
                .then(Response => {console.log("Audits Fetched")});
        } else {
            console.log("Invalid inputs: ", marketplace, auditType, auditStatus, date, resourceType);
            this.setState({dataFetchState: DataFetchState.InvalidRequestMade});
        }
    }

    render() {
        return (
            <div>
                <div id="ParameterSelectionSection">
                <Column alignmentHorizontal="left" spacingInset="xlarge" spacing="large">
                    <LargeText text={ApplicationScreenConstants.SERVICE_PROVIDERS_AUDIT.SCREEN_NAME} />
                    <SmallText text={ApplicationScreenConstants.SERVICE_PROVIDERS_AUDIT.SCREEN_INTRO} />
                    <ServiceProvidersAuditInput
                        onButtonClick={this.onSubmitButtonClick}
                        realmValue={this.props.screenInput.realmValue}
                        stage={this.props.screenInput.stage}/>
                    <Divider size="small"/>
                </Column>
                </div>
                <div id="ResponseSection">
                <ServiceProvidersAuditResponse dataFetchState={this.state.dataFetchState} resultData={this.state.responsePayload} userLogin={this.props.screenInput.username}/>
                </div>
            </div>
        );
    }
}
export default ServiceProvidersAuditPage;