import { XmlEntitiesType } from "src/interfaces/CommonTypes";

export const sanitizeForXML = (message: string): string => {
    // Replace special characters with their XML entities
    const xmlEntities: XmlEntitiesType = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&apos;",
    };
    return message.replace(/[&<>"']/g, (char) => xmlEntities[char]);
};