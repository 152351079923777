import React from 'react';
import Column from "@amzn/meridian/column";
import OnboardedTools from "src/components/screens/home/OnboardedTools";
import {HomeScreenProps} from "src/interfaces/props/HomeScreenProps";
import LargeText from "src/components/common/text/LargeText";

export class HomeScreen extends React.Component<HomeScreenProps> {

    constructor(props: HomeScreenProps) {
        super(props);
    }

    render() {
        const SCREEN_NAME = "Fulfillment Execution Internal Portal";
        return (
            <div id={"HomeScreen"}>
                <Column alignmentHorizontal="left" spacingInset="xlarge" spacing="xlarge">
                    <LargeText text={SCREEN_NAME} />
                    <OnboardedTools/>
                </Column>
            </div>
        );
    }
}

export default HomeScreen;
