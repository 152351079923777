import { AlertType } from "@amzn/meridian/alert/alert";
import Alert from "@amzn/meridian/alert";
import React from "react";
import { STATUS_CODE_TO_MESSAGE_MAP } from "src/constants/JobExecutionImagesConstants";
import { JobExecutionImagesMessageProps } from "src/interfaces/props/JobExecutionImagesProps";
import Column from "@amzn/meridian/column";

export default class JobExecutionImagesMessage extends React.Component<JobExecutionImagesMessageProps> {
	constructor(props: JobExecutionImagesMessageProps) {
		super(props);
	}
	render() {
		let statusCode = this.props.statusCode as keyof typeof STATUS_CODE_TO_MESSAGE_MAP; // to let typescript use statusCode as index key
		let msg = STATUS_CODE_TO_MESSAGE_MAP[statusCode]["text"];
		let type = STATUS_CODE_TO_MESSAGE_MAP[statusCode]["type"] as AlertType;
		return (
			<Column alignmentHorizontal="center">
				<Alert type={type} size="large" autoFocus={true}>
					{msg}
				</Alert>
			</Column>
		);
	}
}