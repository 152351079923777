import React from "react";
import Divider from "@amzn/meridian/divider";
import Column from "@amzn/meridian/column";
import LargeText from "src/components/common/text/LargeText";
import { DataFetchState } from "src/interfaces/CommonTypes";
import { ApplicationScreenConstants } from "src/constants/ApplicationScreenConstants";
import { fetchJobExecutionImages } from "src/helpers/job-execution-images/JobExecutionImagesAPI";
import SmallText from "src/components/common/text/SmallText";
import StringUtils from "src/utils/StringUtils";
import JobExecutionImagesInput from "./JobExecutionImagesInput";
import { JobExecutionImagesScreenProps } from "src/interfaces/props/JobExecutionImagesProps";
import { JobExecutionImagesScreenState } from "src/interfaces/states/JobExecutionImagesStates";
import {
	GetJobExecutionImagesInput,
	GetJobExecutionImagesOutput
} from "src/interfaces/models/JobExecutionImagesModels";
import JobExecutionImagesResponse from "./JobExecutionImagesResponse";

export class JobExecutionImagesScreen extends React.Component<
	JobExecutionImagesScreenProps,
	JobExecutionImagesScreenState
> {
	constructor(props: JobExecutionImagesScreenProps) {
		super(props);
		this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
		this.validateInputs = this.validateInputs.bind(this);
		this.fetchJobExecutionImages = this.fetchJobExecutionImages.bind(this);

		this.state = {
			dataFetchState: DataFetchState.RequestNotMade,
			getJobExecutionImagesOutput: [],
			inputValidationStatus: false
		};
	}

	validateInputs(jobIdValue: string) {
		if (StringUtils.isEmpty(jobIdValue)) {
			this.setState({ inputValidationStatus: false });
			return false;
		} else {
			this.setState({ inputValidationStatus: true });
			return true;
		}
	}

	async fetchJobExecutionImages(userLogin: string, payload: string) {
		this.setState({ dataFetchState: DataFetchState.FetchingData });
		let response;
		try {
			response = await fetchJobExecutionImages(userLogin, payload);
			this.parseResponse(response.ResponsePayload, response.ValidUser);
		} catch (error) {
			this.setState({ dataFetchState: DataFetchState.DataFetchFailed });
		}
	}

	parseResponse(responsePayload: string, validUser: boolean) {
		if (!validUser) {
			this.setState({ dataFetchState: DataFetchState.UnauthorizedUser });
		} else if (!responsePayload) {
			this.setState({ dataFetchState: DataFetchState.DataFetchFailed });
		} else {
			let response: string[] = JSON.parse(responsePayload);
			let parsedResponse: GetJobExecutionImagesOutput = response.map((item) => JSON.parse(item));
			if (response.length == 0) {
				this.setState({ dataFetchState: DataFetchState.NoDataFound });
			} else {
				this.setState({
					getJobExecutionImagesOutput: parsedResponse,
					dataFetchState: DataFetchState.DataFetchedSuccessfully
				});
			}
		}
	}

	onSubmitButtonClick(jobIdValue: string) {
		if (this.validateInputs(jobIdValue)) {
			let getJobExecutionImagesInput: GetJobExecutionImagesInput = this.createPayloadObject(jobIdValue);
			this.fetchJobExecutionImages(this.props.screenInput.username, JSON.stringify(getJobExecutionImagesInput));
		} else {
			this.setState({ dataFetchState: DataFetchState.InvalidRequestMade });
		}
	}

	createPayloadObject(jobIdValue: string) {
		let payloadObject = {
			jobId: jobIdValue
		};
		return payloadObject;
	}

	render() {
		return (
			<div id="QueryJobPhotosScreen">
				<div id="ParameterSelectionSection">
					<Column alignmentHorizontal="left" spacingInset="xlarge" spacing="large">
						<LargeText text={ApplicationScreenConstants.JOB_EXECUTION_IMAGES.SCREEN_NAME} />
						<SmallText text={ApplicationScreenConstants.JOB_EXECUTION_IMAGES.SCREEN_INTRO} />
						<JobExecutionImagesInput onButtonClick={this.onSubmitButtonClick} />
						<Divider size="small" />
					</Column>
				</div>

				<Column spacingInset="none xlarge xlarge">
					<JobExecutionImagesResponse
						dataFetchState={this.state.dataFetchState}
						getJobExecutionImagesOutput={this.state.getJobExecutionImagesOutput}
					/>
				</Column>
			</div>
		);
	}
}

export default JobExecutionImagesScreen;
