import {API} from "aws-amplify";
import {commonApiCallParameters, FALSE} from "src/constants/ApplicationCommonConstants";
import {refreshMidway} from "src/components/authentication/MidwayTokenRetriever";
import {apiCallParameters} from "src/constants/SendBulkNotificationsConstants";
import {
    GetS3UploadUrlOutput,
    GetNotificationInfoAPIInput, GetNotificationInfoAPIOutput,
    SearchNotificationsAPIInput, SearchNotificationsAPIOutput,
    SendInstantCustomizedNotificationAPIInput,
    SendInstantNotificationAPIInput, SendNotificationAPIOutput
} from "src/interfaces/models/SendBulkNotificationsModels";
import {SendBulkNotificationsOperations} from "src/interfaces/enums/SendBulkNotificationsEnums";

const executeNotificationServiceAPIRequest = async (operation: SendBulkNotificationsOperations, payload: string) => {
    const credentials = await refreshMidway();
    try {
        return await API.post(apiCallParameters.VAS_NOTIFICATION_SERVICE_DATA, commonApiCallParameters.EXECUTE_REQUEST, {
            body: {
                'RequestType': operation,
                'EncryptedPayloadRequired': FALSE,
                'Tool': apiCallParameters.VAS_NOTIFICATION_SERVICE_LAMBDA,
                'Payload': payload,
                'Token': credentials.token,
                'ClientId': location.host
            },
            headers: {
                'Content-Type': commonApiCallParameters.CONTENT_TYPE,
            },
        });
    } catch (error) {
        console.log(error);
        throw "Error while calling the Notification Service API";
    }
}

function processAPIResponse<T>(response: any): T {
    if (response && response.ResponsePayload) {
        return JSON.parse(response.ResponsePayload) as T;
    }
    throw "Notification Service API request failed";
}

export const searchNotifications = async (input: SearchNotificationsAPIInput):
        Promise<SearchNotificationsAPIOutput> => {
    const responsePayload = await executeNotificationServiceAPIRequest(
        SendBulkNotificationsOperations.SEARCH_NOTIFICATIONS, JSON.stringify(input));
    return processAPIResponse<SearchNotificationsAPIOutput>(responsePayload);
}

export const sendInstantNotification = async (input: SendInstantNotificationAPIInput):
        Promise<SendNotificationAPIOutput> => {
    const responsePayload = await executeNotificationServiceAPIRequest(
        SendBulkNotificationsOperations.SEND_INSTANT_NOTIFICATION, JSON.stringify(input));
    return processAPIResponse<SendNotificationAPIOutput>(responsePayload);
}

export const sendInstantCustomizedNotification = async (input: SendInstantCustomizedNotificationAPIInput):
        Promise<SendNotificationAPIOutput> => {
    const responsePayload = await executeNotificationServiceAPIRequest(
        SendBulkNotificationsOperations.SEND_INSTANT_CUSTOMIZED_NOTIFICATION, JSON.stringify(input));
    return processAPIResponse<SendNotificationAPIOutput>(responsePayload);
}

export const getS3UploadUrl = async (): Promise<GetS3UploadUrlOutput> => {
    const responsePayload = await executeNotificationServiceAPIRequest(
        SendBulkNotificationsOperations.GET_S3_UPLOAD_URL, "{}");
    return processAPIResponse<GetS3UploadUrlOutput>(responsePayload);
}

export const getNotificationInfo = async (input: GetNotificationInfoAPIInput):
        Promise<GetNotificationInfoAPIOutput> => {
    const responsePayload = await executeNotificationServiceAPIRequest(
        SendBulkNotificationsOperations.GET_NOTIFICATION_INFO, JSON.stringify(input));
    return processAPIResponse<GetNotificationInfoAPIOutput>(responsePayload);
}

