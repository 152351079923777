import React from "react";
import {ShowAttributeWithValueProps} from "src/interfaces/props/TechnicianProfileEditProps";
import Row from "@amzn/meridian/row";
import Level6Heading from "src/components/common/heading/Level6Heading";
import MiniText from "src/components/common/text/MiniText";

export class ShowAttributeWithValue extends React.Component<ShowAttributeWithValueProps> {
    constructor(props: ShowAttributeWithValueProps) {
        super(props);
    }

    render() {
        return (
            <Row width={"100%"} widths={["grid-4", "grid-8"]} >
                <Level6Heading text={this.props.attributeName}/>
                <MiniText text={this.props.attributeValue} breakWord={true}/>
            </Row>
        );
    }
}