import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {KatSpinner} from "@amzn/katal-react";
import katalReady from "@katal/components-ready";
import "./index.scss";
import {whenDOMContentLoaded} from "./whenDOMContentLoaded";
import App from "src/components/App";

katalReady(() =>
    whenDOMContentLoaded(() => {
        // The Suspense component causes React to display the fallback KatSpinner until
        // the i18n translation strings have been loaded.
        ReactDOM.render(
                <Suspense fallback={<KatSpinner size="large" />}>
                    <App />
                </Suspense>,
            document.getElementById("root")
        );
    })
);