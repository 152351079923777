import React from 'react';
import Heading from "@amzn/meridian/heading";
import {TextProps} from "src/interfaces/props/CommonProps";

export class Level3Heading extends React.Component<TextProps> {

    constructor(props: TextProps) {
        super(props);
    }

    render() {
        return(
            <Heading level={3}> {this.props.text} </Heading>
        );
    }
}
export default Level3Heading;
