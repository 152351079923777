import { API } from "aws-amplify";
import { apiCallParameters } from "src/constants/JobExecutionImagesConstants";
import { commonApiCallParameters, FALSE } from "src/constants/ApplicationCommonConstants";
import { refreshMidway } from "src/components/authentication/MidwayTokenRetriever";
import { GetJobExecutionImagesInput } from "src/interfaces/models/JobExecutionImagesModels";

export const fetchJobExecutionImages = async (userLogin: string, payload: string) => {
	let response;
	const credentials = await refreshMidway();
	try {
		response = await API.post(
			apiCallParameters.FEU_JOB_EXECUTION_IMAGES_API_NAME,
			commonApiCallParameters.EXECUTE_REQUEST,
			{
				body: {
					'RequestType': apiCallParameters.GET_JOB_EXECUTION_IMAGES_REQUEST,
					'Tool': apiCallParameters.FEU_JOB_EXECUTION_IMAGES_LAMBDA_TOOL,
                    'EncryptedPayloadRequired': FALSE,
					'UserLogin': userLogin,
					'Payload': payload,
					'Token': credentials.token,
					'ClientId': location.host
				},
				headers: { 'Content-Type': commonApiCallParameters.CONTENT_TYPE }
			}
		);
		return response;
	} catch (error) {
		throw new Error(`Error while calling the fetchJobExecutionImages api for input: ${payload}`);
	}
};