import {
    GetNotificationInfoAPIInput,
    SearchNotificationsAPIInput,
    SendInstantCustomizedNotificationAPIInput,
    SendInstantNotificationAPIInput
} from "src/interfaces/models/SendBulkNotificationsModels";
import StringUtils from "src/utils/StringUtils";
import {
    BulkNotificationDetailsScreenState,
    SearchNotificationsPaneState
} from "src/interfaces/states/SendBulkNotificationsStates";
import {
    buildExactValueFilters,
    buildRangeFilters,
} from "src/helpers/send-bulk-notifications/SendBulkNotificationsSearchFilters";
import {NOTIFICATION_MESSAGE_MAX_LENGTH} from "src/constants/SendBulkNotificationsConstants";
import { sanitizeForXML } from "src/utils/XmlUtils";

export const buildGetNotificationInfoPayload = (referenceId: string): GetNotificationInfoAPIInput => {
    return {
        referenceId: referenceId,
    } as GetNotificationInfoAPIInput;
}

export const buildSendInstantCommonNotificationInput = (state: BulkNotificationDetailsScreenState):
    SendInstantNotificationAPIInput => {
    if (StringUtils.isEmpty(state.notificationTitle) || StringUtils.isEmpty(state.notificationMessage) ||
        state.recipients.length === 0 || StringUtils.isEmpty(state.encryptedMarketplaceId)) {
        throw "All the required inputs are not provided. Required Inputs: Notification Title, Notification Message," +
        " Recipients, Marketplace";
    } else if (state.notificationMessage.length > NOTIFICATION_MESSAGE_MAX_LENGTH) {
        throw "Notification message has " + state.notificationMessage.length + " characters, which has the" +
        " maximum limit " + NOTIFICATION_MESSAGE_MAX_LENGTH;
    } else {
        const sanitizedNotificationMessage = sanitizeForXML(state.notificationMessage);
        return {
            notificationTitle: state.notificationTitle,
            notificationMessage: sanitizedNotificationMessage,
            encryptedMarketplaceId: state.encryptedMarketplaceId,
            deliveryChannel: state.deliveryChannel,
            recipientIds: state.recipients,
            recipientLevel: state.recipientLevel,
        } as SendInstantNotificationAPIInput;
    }
}

export const buildSendInstantCustomizedNotificationInput = (state: BulkNotificationDetailsScreenState,
                                                                  referenceId: string):
    SendInstantCustomizedNotificationAPIInput => {
    if (StringUtils.isEmpty(state.notificationTitle) || StringUtils.isEmpty(state.encryptedMarketplaceId)) {
        throw "All the required inputs are not provided. Required Inputs: Notification Title, Marketplace";
    }
    return {
        notificationTitle: state.notificationTitle,
        encryptedMarketplaceId: state.encryptedMarketplaceId,
        s3ObjectKey: referenceId,
        deliveryChannel: state.deliveryChannel,
    } as SendInstantCustomizedNotificationAPIInput;
}

export const buildSearchNotificationsAPIPayload = (state: SearchNotificationsPaneState): SearchNotificationsAPIInput => {
    const exactValueFilters = buildExactValueFilters(state);
    const rangeFilters = buildRangeFilters(state);
    return {
        paginationToken: state.paginationToken,
        pageSize: state.pageSize,
        exactValueFilters: exactValueFilters,
        rangeFilters: rangeFilters,

    } as SearchNotificationsAPIInput;
}