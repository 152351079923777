import React from "react";
import Column from "@amzn/meridian/column";
import ErrorAlert from "src/components/common/alert/ErrorAlert";

export class RequestFailedMessage extends React.Component {
    render() {
        const FAILED_REQUEST_MESSAGE = "Something wrong happened. Please try again.";
        return (
            <div id="RequestFailedMessage">
                <Column alignmentHorizontal="center">
                    <ErrorAlert text={FAILED_REQUEST_MESSAGE} />
                </Column>
            </div>
        );
    }
}

export default RequestFailedMessage;