import {
    TechnicianProfileEditLoadRowObject,
    TechnicianProfileEditRowObject, UpdateTechnicianOutputRowObject
} from "src/interfaces/props/TechnicianProfileEditProps";

export const searchQuery = (object: TechnicianProfileEditRowObject, searchValue: string) => {
    let searchResult = ((object.technicianId == searchValue) || (object.technicianName == searchValue) ||
        (object.merchantId == searchValue) || (object.profileImageStatus == searchValue) || (object.govtIdNameStatus == searchValue) ||
        (object.govtIdPictureStatus == searchValue) || (object.backgroundCheckStatus == searchValue))
    return searchResult;
}

export const deserializeSearchData = (serializedData: string) => {
    let deserializedStringArray = JSON.parse(serializedData)
    let arrayIndex;
    let deserializedArray = [] as TechnicianProfileEditRowObject[];
    for (arrayIndex = 0; arrayIndex < deserializedStringArray.length; arrayIndex++) {
        let data = JSON.parse(deserializedStringArray[arrayIndex]);
        deserializedArray.push(data);
    }
    return deserializedArray;
}

export const deserializeLoadData = (serializedData: string) => {
    let deserializedStringArray = JSON.parse(serializedData)
    let deserializedArray = [] as TechnicianProfileEditLoadRowObject[];
    deserializedArray.push(deserializedStringArray);
    return deserializedArray;
}

export const deserializeUpdateData = (serializedData: string) => {
    let deserializedStringArray = JSON.parse(serializedData)
    let deserializedArray = [] as UpdateTechnicianOutputRowObject[];
    deserializedArray.push(deserializedStringArray);
    return deserializedArray;
}

export const createUpdateTechnicianPayloadObject = (marketplaceId: string, merchantId: string, technicianId: string, profileImage: string,
                             emailAddress: string, isDisabled: boolean, technicianUpdatedAttributeList: string[],
                             govtIdUpdatedFistName: string, govtIdUpdatedLastName: string, govtIdUpdatedImage: string, oldGovtIdImage: string) => {
    let payloadObject = {
        marketPlaceId: marketplaceId,
        merchantId: merchantId,
        technicianId: technicianId,
        profileImage: profileImage,
        emailAddress: emailAddress,
        deleted: isDisabled,
        govtIdDetails: {
            govtIdExtractedFirstName: govtIdUpdatedFistName,
            govtIdExtractedLastName: govtIdUpdatedLastName,
            govtIdExtractedImage: govtIdUpdatedImage,
            oldGovtIdImage: oldGovtIdImage
        },
        technicianUpdateAttribute: technicianUpdatedAttributeList
    }
    return payloadObject;
}