import React from "react";
import Alert from "@amzn/meridian/alert"
import {AlertProps} from "src/interfaces/props/CommonProps";

export class SuccessAlert extends React.Component<AlertProps> {

    constructor(props: AlertProps) {
        super(props);
    }

    render() {
        return (
            <Alert type="success" size="large" autoFocus={true} onClose={this.props.onClose}> {this.props.text} </Alert>
        );
    }
}

export default SuccessAlert;